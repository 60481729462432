import React from "react";
import { defaultPicUrl } from "../../util/hooks";

export default function QuestionLeaderboardTable({
  rankedQuestionStats,
  countryFlags,
  groupType,
  customer,
  loadingPlayerQStats,
  navigate,
}) {
  // const { customer } = useContext(CustomerAuthContext);
  return (
    <table className="w-full relative table-auto border-separate border-spacing-2 short:border-spacing-1 bg-darker-gray h-full rounded-2xl flex flex-col items-start justify-start">
      <thead className="bg-gray-800 w-full rounded-lg">
        <tr>
          <th
            // onClick={(e) => {
            //   e.preventDefault();
            //   setValues({ ...values, sortBy: "Earnings" });
            // }}
            className={`group text-center relative px-1 sm:px-2 rounded-lg transition-all`}
          >
            <svg
              className="w-4 fill-current text-gray-200"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M18 10c0 3.309-2.691 6-6 6s-6-2.691-6-6 2.691-6 6-6 6 2.691 6 6zm4 1.737l-1.895 1.168.687 2.095-2.187.46-.079 2.2-2.213-.304-.84 2.04-1.977-1.031-1.496 1.635-1.497-1.634-1.977 1.031-.84-2.04-2.213.304-.079-2.2-2.186-.461.687-2.095-1.895-1.168 1.374-1.737-1.374-1.737 1.895-1.168-.687-2.095 2.187-.46.079-2.2 2.213.304.84-2.04 1.977 1.031 1.496-1.635 1.497 1.634 1.977-1.031.84 2.04 2.213-.304.079 2.2 2.186.461-.687 2.095 1.895 1.168-1.374 1.737 1.374 1.737zm-3-1.737c0-3.866-3.134-7-7-7s-7 3.134-7 7 3.134 7 7 7 7-3.134 7-7zm-1.859 10.276l2.401 3.724 1.146-2h2.312l-2.655-4.103c-.917.969-1.999 1.775-3.204 2.379zm-13.486-2.379l-2.655 4.103h2.312l1.146 2 2.401-3.724c-1.205-.604-2.287-1.41-3.204-2.379z" />
            </svg>
            <div className="flex flex-col items-center justify-center absolute bottom-full left-1/2 transform -translate-x-1/2 -mt-1 whitespace-nowrap text-center text-xs text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <span className="bg-gray-800 p-2  rounded-md shadow">Rank</span>
              <svg
                className="h-1.5 fill-current text-gray-800"
                viewBox="0 0 20 10"
              >
                <path d="M0 0L20 0L10 10Z" />
              </svg>
            </div>
          </th>

          <th
            className={`w-full truncate text-left px-4 rounded-lg  bg-darker-gray text-white py-1 transition-all`}
          >
            {groupType === "Friends" ? "Friend Leaderboard" : "Leaderboard"}
          </th>

          <th
            className={`group relative px-3 sm:px-4 rounded-lg transition-all`}
          >
            <svg
              className="h-5 sm:h-6 fill-current text-black"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 36 36"
            >
              <circle fill="#DD2E44" cx={18} cy={18} r={18} />
              <circle fill="#FFF" cx={18} cy={18} r={13.5} />
              <circle fill="#DD2E44" cx={18} cy={18} r={10} />
              <circle fill="#FFF" cx={18} cy={18} r={6} />
              <circle fill="#DD2E44" cx={18} cy={18} r={3} />
              <path
                opacity={0.2}
                d="m18.24 18.282 13.144 11.754s-2.647 3.376-7.89 5.109L17.579 18.42l.661-.138z"
              />
              <path
                fill="#FFAC33"
                d="M18.294 19a.994.994 0 0 1-.704-1.699l.563-.563a.995.995 0 0 1 1.408 1.407l-.564.563a.987.987 0 0 1-.703.292z"
              />
              <path
                fill="#55ACEE"
                d="M24.016 6.981c-.403 2.079 0 4.691 0 4.691l7.054-7.388c.291-1.454-.528-3.932-1.718-4.238-1.19-.306-4.079.803-5.336 6.935zm5.003 5.003c-2.079.403-4.691 0-4.691 0l7.388-7.054c1.454-.291 3.932.528 4.238 1.718.306 1.19-.803 4.079-6.935 5.336z"
              />
              <path
                fill="#3A87C2"
                d="M32.798 4.485 21.176 17.587c-.362.362-1.673.882-2.51.046-.836-.836-.419-2.08-.057-2.443L31.815 3.501s.676-.635 1.159-.152-.176 1.136-.176 1.136z"
              />
            </svg>

            <div className="flex flex-col items-center justify-center absolute bottom-full left-1/2 transform -translate-x-1/2 -mt-1 whitespace-nowrap text-center text-xs text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <span className="bg-gray-800 p-2  rounded-md shadow">
                Accuracy
              </span>
              <svg
                className="h-1.5 fill-current text-gray-800"
                viewBox="0 0 20 10"
              >
                <path d="M0 0L20 0L10 10Z" />
              </svg>
            </div>
          </th>
        </tr>
      </thead>
      {rankedQuestionStats?.length > 0 || loadingPlayerQStats ? (
        <tbody className="w-full max-h-44 sm:max-h-64 h-full overflow-y-auto">
          {rankedQuestionStats && rankedQuestionStats.length > 0 ? (
            rankedQuestionStats.map((questionStat, index) => (
              <tr className={`w-full truncate`} key={index}>
                <td className=" text-gray-400 text-center">{index + 1}</td>
                <td className="w-full text-left flex items-center justify-start space-x-2 text-black rounded-lg">
                  <div
                    className={`bg-gray-700 flex-shrink-0 transition-all flex items-center justify-center overflow-hidden p-1 2xl:p-1 rounded-full aspect-square w-11 short:w-8 2xl:w-14`}
                    key={index}
                    // hover:bg-blue-700
                  >
                    <img
                      src={questionStat.profilePicUrl || defaultPicUrl}
                      alt={questionStat.username}
                      className="border-white bg-white short:border-[1.5px] border-2 box-border rounded-full object-cover aspect-square"
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start space-y-0 leading-none short:leading-none short:text-sm">
                    <p className="font-semibold truncate text-white">
                      {customer && questionStat.id === customer.id
                        ? "You"
                        : questionStat.name || "Guest"}
                      {countryFlags
                        ? " " + countryFlags[questionStat.country || "All"]
                        : ""}
                    </p>
                    <div className="flex leading-tight items-center justify-start text-gray-700">
                      <svg
                        className="h-3 fill-current text-blue-300"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM9 5H10.5V10.2L15 12.9L14.2 14.2L9 11V5Z"
                        />
                      </svg>

                      <p className="text-sm ml-1 short:text-xs text-gray-200">
                        {questionStat.avgTimeTakenForCorrectAns} ms
                      </p>
                    </div>
                  </div>
                </td>

                {/* <td className=" font-semibold text-center  rounded-lg">
                  {questionStat.availableExtraLivesCount}
                </td> */}
                <td className="text-center short:text-sm font-semibold text-white">
                  {questionStat.avgAccuracy}%
                </td>
              </tr>
            ))
          ) : (
            <div className="py-4">Check after the first question</div>
          )}
        </tbody>
      ) : groupType === "Friends" && !customer ? (
        <div className="m-auto flex flex-col items-center justify-start py-4 max-w-xs font-semibold">
          <button
            onClick={(e) => {
              navigate("/register");
            }}
            className="text-xl text-yellow-200 whitespace-nowrap w-full hover:opacity-70 transition-all"
          >
            Sign in to add friends
          </button>
          <p className="whitespace-normal font-normal text-white">
            Once your friends answer this question, you will see detailed
            rankings here
          </p>
        </div>
      ) : (
        <p className="whitespace-normal font-normal text-white w-full max-h-44 sm:max-h-64 py-6 px-8">
          Once your friends answer this question, you will see detailed rankings
          here
        </p>
      )}

      <div className="w-full h-4 short:h-2 bg-gradient-to-t from-darker-gray via-darker-gray/70 to-transparent absolute bottom-0 inset-x-0"></div>
    </table>
  );
}
