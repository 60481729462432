import React from "react";

const MysteryTopicBox = ({
  randomTopicLogoUrls,
  mysteryTopicLogoUrl,
  width,
  shouldScaleDown,
}) => {
  return (
    <div
      className={`${
        shouldScaleDown ? "scale-75" : ""
      } w-48 transition-all aspect-square origin-bottom  flex items-center justify-center  bg-darker-gray-40 rounded-full overflow-hidden bottom-0 absolute`}
    >
      <div className="absolute z-10 flex flex-col items-center justify-start transition-all top-0 motion-safe:animate-slotUp">
        {[...randomTopicLogoUrls, mysteryTopicLogoUrl].map(
          (topicLogoUrl, index) => (
            <img
              src={topicLogoUrl}
              className={"h-48 w-48 object-cover rounded-full"}
              alt={"Mystery Topic " + index}
              key={index}
            />
          )
        )}
      </div>
    </div>
  );
};

export default MysteryTopicBox;
