import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import backgroundMusic from "../../music/backgroundMusic.mp3";
import correctAnsSound from "../../sounds/answer-correct.mp3";
import cheerSound from "../../sounds/battle-won.mp3";
import confettiSound from "../../sounds/confetti.mp3";
import usePageTracking from "../../util/usePageTracking";

import LiveStreamGradBack from "../../components/creator/LiveStreamGradBack";

import { customerClient, guestClient } from "../../GraphqlApolloClients";
// import  LivestreamAnswerBox from "../../components/creator/LivestreamAnswerBox";
import Confetti from "react-confetti";
// import  { useWindowSize } from "../../util/hooks";
import CommentSection from "../../components/creator/CommentSection";
import PieTimer from "../../components/creator/PieTimer";
import { CustomerAuthContext } from "../../context/customerAuth";

// import  ProductAdBox from "../../components/creator/ProductAdBox";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import BuySubscriptionForm from "../../components/creator/BuySubscriptionForm";
import GenericPaymentSuccessfulForm from "../../components/creator/GenericPaymentSuccessfulForm";
import PowerUpPaymentSuccessfulForm from "../../components/creator/PowerUpPaymentSuccessfulForm";
import PurchasePowerUpForm from "../../components/creator/PurchasePowerUpForm";
import {
  defaultPicUrl,
  getMobileOperatingSystem,
  useForm,
  useWindowSize,
} from "../../util/hooks";

import BattleLeaderboard from "../../components/creator/BattleLeaderboard";
import BuyCoinsForm from "../../components/creator/BuyCoinsForm";
import CodeForm from "../../components/creator/CodeForm";

import HorAdOverlayBox from "../../components/creator/HorAdOverlayBox";
import MobileFooter from "../../components/creator/MobileFooter";

import TNReviewForm from "../../components/creator/TNReviewForm";

import useSound from "use-sound";
import CountdownTimer from "../../components/creator/CountdownTimer";
import MiniNavBar from "../../components/creator/MiniNavBar";
import TKLPopup from "../../components/creator/TKLPopup";
import TopBar from "../../components/creator/TopBar";
import TopicIcon from "../../components/creator/TopicIcon";
import {
  FETCH_AD_BOOKMARK_BY_CUSTOMER,
  FETCH_ALL_PLAYER_BATTLE_STATS_BY_BATTLE,
  FETCH_CAN_USE_EXTRA_LIFE,
  FETCH_CUR_QUESTION,
  FETCH_CUR_SELECTED_ANSWER,
  FETCH_CUR_TRIVIA_NIGHT,
  FETCH_CUSTOMER,
  FETCH_GUEST_CUSTOMER,
  FETCH_IS_OUT_OF_LIVES,
  FETCH_LIVES_COUNT_BY_CUSTOMER,
  FETCH_RELEVANT_AD,
  FETCH_RELEVANT_PRODUCT_ADS,
  FETCH_SELECTED_ANSWER_STATS,
  FETCH_SHOULD_ASK_FOR_REVIEW,
  FETCH_TRIVIA_WIN_BY_CUSTOMER,
  LOG_TRIVIA_SHARE_MUTATION,
  TRIVIA_NIGHT_UPDATED_SUBSCRIPTION,
} from "../../util/gqlFunctions";
import { GuestAuthContext } from "../../context/guestAuth";

function TriviaNight(props) {
  usePageTracking();

  let navigate = useNavigate();

  const { guest } = useContext(GuestAuthContext);

  const { customer, logoutCustomer: logout } = useContext(CustomerAuthContext);

  useEffect(() => {
    document.title = "TKnight LIVE";
  }, []);
  const [lastAnsweredQ, setLastAnsweredQ] = useState(null);

  const {
    subscribeToMore,
    data: { getCurTriviaNight: triviaNight } = {},
    loading: loadingTriviaNight,
  } = useQuery(FETCH_CUR_TRIVIA_NIGHT, {
    client: customerClient,
    pollInterval: 10000,
  });

  const audioRef = useRef(null);

  useEffect(() => {
    audioRef.current.volume = 0.4;
  }, []);

  const [initializedMuteVals, setInitializedMuteVals] = useState(false);

  const { values, setValues } = useForm(joinProPlanCallback, {
    inviteCode: "",
  });

  const {
    // refetch: refetchRegularHorAd,
    data: { getRelevantAd: regularHorAd } = {},
    // loading: loadingCurQuestion,
  } = useQuery(FETCH_RELEVANT_AD, {
    client: customer ? customerClient : guestClient,
    variables: { format: 6, siteId: 0 },
  });

  const {
    // refetch: refetchRegularHorAd,
    data: { getRelevantAd: squareAd } = {},
    // loading: loadingCurQuestion,
  } = useQuery(FETCH_RELEVANT_AD, {
    client: customer ? customerClient : guestClient,
    variables: { format: 8, siteId: 0 },
  });
  const {
    data: { getAdBookmarkByCustomer: regularHorAdBookmark } = {},
    // loading: loadingBailOutByTN,
  } = useQuery(FETCH_AD_BOOKMARK_BY_CUSTOMER, {
    client: customer ? customerClient : guestClient,
    // fetchPolicy: "cache-and-network",

    variables: { adId: regularHorAd ? regularHorAd.id : "" },
  });

  const {
    data: { getAdBookmarkByCustomer: squareAdBookmark } = {},
    // loading: loadingBailOutByTN,
  } = useQuery(FETCH_AD_BOOKMARK_BY_CUSTOMER, {
    client: customer ? customerClient : guestClient,
    // fetchPolicy: "cache-and-network",

    variables: {
      adId: squareAd ? squareAd.id : "",
    },
  });

  const {
    data: { getTriviaWinByCustomer: triviaWin } = {},
    loading: loadingTriviaWin,
    refetch: refetchTriviaWin,
  } = useQuery(FETCH_TRIVIA_WIN_BY_CUSTOMER, {
    client: customerClient,
    variables: { triviaNightId: triviaNight ? triviaNight.id : "" },
  });

  const {
    data: { getCustomer: targetCustomer } = {},
    loading: loadingCustomerInfo,
    refetch: refetchCustomer,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
  });

  useEffect(() => {
    if (
      targetCustomer &&
      (!targetCustomer.curRegistrationStep ||
        targetCustomer.curRegistrationStep < 3)
    ) {
      navigate("/completeRegistration/" + targetCustomer.curRegistrationStep);
    }
  }, [targetCustomer, navigate]);

  const {
    data: { getGuestCustomer: targetGuestCustomer } = {},
    loading: loadingGuestCustomer,
    refetch: refetchGuestCustomer,
  } = useQuery(FETCH_GUEST_CUSTOMER, { client: guestClient });
  const {
    data: { getNumOfExtraLivesNeeded: numOfExtraLivesNeeded } = {},
    loading: loadingNumOfExtraLivesNeeded,
    refetch: refetchNumOfExtraLivesNeeded,
  } = useQuery(FETCH_CAN_USE_EXTRA_LIFE, {
    pollInterval: 5000,
    variables: { triviaNightId: triviaNight ? triviaNight.id : "" },
    client: customer ? customerClient : guestClient,
  });

  const [shouldOpenQuiz, setShouldOpenQuiz] = useState(true);
  const [finishedSettingUpTN, setFinishedSettingUpTN] = useState(false);

  const [shouldShowBattleLeaderboard, setShouldShowBattleLeaderboard] =
    useState(false);

  useEffect(() => {
    if (
      !finishedSettingUpTN &&
      !shouldOpenQuiz &&
      triviaNight &&
      triviaNight.status > 0
    ) {
      setShouldOpenQuiz(true);
      setFinishedSettingUpTN(true);
    }
  }, [finishedSettingUpTN, shouldOpenQuiz, triviaNight]);
  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] =
    useState(false);

  useEffect(() => {
    if (
      (customer &&
        !loadingCustomerInfo &&
        !targetCustomer &&
        !checkedIsCustomerLoggedIn) ||
      (targetCustomer && targetCustomer.isBlocked)
    ) {
      logout();
      setCheckedIsCustomerLoggedIn(true);
    }
  }, [
    customer,
    loadingCustomerInfo,
    targetCustomer,
    logout,
    checkedIsCustomerLoggedIn,
  ]);

  const resetInterval = 1000;
  const [errors, setErrors] = useState({});

  const [isInviteCopied, setIsInviteCopied] = useState(null);
  const [isSharingInviteToApps, setIsSharingInviteToApps] = useState(false);
  const [shouldPlaySoundEffects, setShouldPlaySoundEffects] = useState(true);
  const [shouldOpenQRCodeBox, setShouldOpenQRCodeBox] = useState(false);

  useEffect(() => {
    let timeout;
    if (isInviteCopied && resetInterval) {
      timeout = setTimeout(() => setIsInviteCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isInviteCopied, resetInterval]);
  const stripe = useStripe();
  const elements = useElements();

  const inviteFriends = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = "Check out tknight.live\n\n";
    textToShare +=
      "If you survive 7 trivia questions, you get to split the prize with other winners. ";
    // if (targetCustomer.totalEarnings) {
    //   textToShare +=
    //     "I've won $" +
    //     (targetCustomer.totalEarnings / 100).toFixed(2) +
    //     " so far! ";
    // }
    textToShare += "It's really fun!!\n\n";
    if (targetCustomer) {
      textToShare +=
        'Sign up with my extra ❤️ code: "' + targetCustomer.username + '"\n\n';
    }
    textToShare += "#TKnight";

    // textToShare +=
    //   " ".repeat(registrationAddress.length + 11) +
    //   "``" +
    //   "`".repeat(Math.ceil(targetCustomer.username.length * 1.7)) +
    //   "``\n\n";

    // await handleInviteDownload();

    if (navigator.share) {
      navigator
        .share({
          title: "Battle on TKnight!",
          text: textToShare,
          // url: "http://worldphraze.com/",
          // url:
          //   playersPhraze && playersPhraze.isEligibleForPrize
          //     ? "W/" + playersPhraze.id
          //     : "phraze",
        })
        .then(() => {
          setIsInviteCopied(true);
          setIsSharingInviteToApps(true);
        })
        .catch((error) => {
          setIsSharingInviteToApps(false);
          navigator.clipboard.writeText(textToShare);
          setHasCaptionBeenCopiedBefore(true);
          setIsInviteCopied(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setIsInviteCopied(true);
      setHasCaptionBeenCopiedBefore(true);
    }
  };

  function joinProPlanCallback() {}

  const {
    data: { getLivesCountByCustomer: livesCount } = {},
    loading: loadingLivesCount,
    refetch: refetchLivesCount,
  } = useQuery(FETCH_LIVES_COUNT_BY_CUSTOMER, {
    client: customerClient,
  });

  const {
    data: { getShouldAskForReview: shouldAskForReview } = {},
    // loading: loadingCustomerReview,
  } = useQuery(FETCH_SHOULD_ASK_FOR_REVIEW, {
    client: customerClient,
    variables: { askingFromLoc: 1, siteId: 0 },
  });

  useEffect(() => {
    if (triviaNight && (triviaNight.status === 2 || triviaNight.status === 3)) {
      refetchCustomer();
      refetchGuestCustomer();
    }
  }, [triviaNight, refetchCustomer, refetchGuestCustomer]);

  const [isCaptionCopied, setIsCaptionCopied] = useState(null);
  const [isSharingToApps, setIsSharingToApps] = useState(false);
  const [shouldOpenAdPopup, setShouldOpenAdPopup] = useState(false);
  const [hasPlayedAudienceCheer, setHasPlayedAudienceCheer] = useState(false);

  const [isFirstTime, setIsFirstTime] = useState(true);
  const [shouldShowMoreBattleOptions, setShouldShowMoreBattleOptions] =
    useState(false);
  // const [shouldShowMiniNav, setShouldShowMiniNav] = useState(false);
  // const [shouldShowMiniNavBar, setShouldShowMiniNavBar] = useState(false);
  const { width, height } = useWindowSize();
  useEffect(() => {
    let timeout;
    if (isCaptionCopied && resetInterval) {
      timeout = setTimeout(() => setIsCaptionCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied, resetInterval]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        triviaNight &&
        triviaNight.status === 5 &&
        moment(triviaNight.releaseDate).add(30, "minutes").isBefore()
      ) {
        navigate("/");
        // setTimeTillReleaseFormatted(moment(triviaNight.releaseDate).fromNow());
      } else if (triviaNight) {
        clearInterval(interval);
      }
    }, 20 * 1000 * 60);

    return () => clearInterval(interval);
  }, [triviaNight, navigate]);
  const [playCorrectAnswerSound] = useSound(correctAnsSound, { volume: 0.2 });
  const [
    playConfettiSound,
    { stop: stopConfettiSound, isPlaying: isPlayingConfettiSound },
  ] = useSound(confettiSound, { volume: 0.1 });
  const [playCheerSound, { stop: stopCheer, isPlaying: isPlayingCheer }] =
    useSound(cheerSound, {
      volume: 0.2,
    });
  const [hasMutedSound, setHasMutedSound] = useState(false);

  useEffect(() => {
    if (
      (!shouldPlaySoundEffects ||
        ((isPlayingCheer || isPlayingConfettiSound) &&
          !shouldPlaySoundEffects)) &&
      !hasMutedSound
    ) {
      stopConfettiSound();
      stopCheer();
      setHasMutedSound(true);
    }
  }, [
    shouldPlaySoundEffects,
    stopConfettiSound,
    isPlayingCheer,
    stopCheer,
    setHasMutedSound,
    hasMutedSound,
    isPlayingConfettiSound,
  ]);

  const {
    data: { getIsOutOfLives: isOutOfLives } = {},
    loading: loadingIsOutOfLives,
    refetch: refetchIsOutOfLives,
  } = useQuery(FETCH_IS_OUT_OF_LIVES, {
    client: customerClient,
    pollInterval: 5000,
    variables: { triviaNightId: triviaNight ? triviaNight.id : "" },
  });

  const {
    refetch: refetchCurQ,
    data: { getCurQuestion: curQuestion } = {},
    // loading: loadingCurQuestion,
  } = useQuery(FETCH_CUR_QUESTION, {
    client: customer ? customerClient : guestClient,
    variables: { triviaNightId: triviaNight ? triviaNight.id : null },
  });

  const { data: { getCurSelectedAnswer: selectedAnswer } = {} } = useQuery(
    FETCH_CUR_SELECTED_ANSWER,
    {
      client: targetCustomer ? customerClient : guestClient,

      variables: { quizId: null, triviaNightId: triviaNight?.id || null },
    }
  );

  const {
    data: { getSelectedAnswerStatsByQuestion: selectedAnswerStats } = {},
    loading: loadingSelectedAnswerStats,
    refetch: refetchSelectedAnswerStats,
  } = useQuery(FETCH_SELECTED_ANSWER_STATS, {
    client: customerClient,
    fetchPolicy: "cache-and-network",
    skip: triviaNight ? false : true,
    variables: {
      questionId: curQuestion ? curQuestion.id : "",
      triviaNightId: triviaNight ? triviaNight.id : "",
    },
  });

  const {
    data: { getRelevantProductAds: productAds } = {},
    // loading: loadingProductAds,
    refetch: refetchProductAds,
  } = useQuery(FETCH_RELEVANT_PRODUCT_ADS, {
    client: customerClient,

    // variables: { triviaNightId: triviaNight.id },
  });

  const {
    data: { getAllPlayerBattleStatsByBattle: allPlayerBattleStats } = {},
    // loading: loadingPlayerBattleStatsByBattle,
    refetch: refetchAllPlayerBattleStatsByBattle,
  } = useQuery(FETCH_ALL_PLAYER_BATTLE_STATS_BY_BATTLE, {
    client: customer ? customerClient : guestClient,

    variables: { triviaNightId: triviaNight ? triviaNight.id : null },
  });

  useEffect(() => {
    let subscription = null;
    if (triviaNight) {
      subscription = subscribeToMore({
        document: TRIVIA_NIGHT_UPDATED_SUBSCRIPTION,
        variables: { triviaNightId: triviaNight.id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const updatedTriviaNight = subscriptionData.data.triviaNightUpdated;
          return {
            ...prev,
            getCurTriviaNight: { ...updatedTriviaNight },
            // getOrderById: [
            //   ...prev.getFulfillerChatsByOrder,
            //   newMessage,
            // ],
          };
        },
      });
    }

    return () => {
      if (subscription && typeof subscription.unsubscribe === "function") {
        subscription.unsubscribe();
      }
    };
  }, [subscribeToMore, triviaNight]);

  const size = useWindowSize();
  const [hasCaptionBeenCopiedBefore, setHasCaptionBeenCopiedBefore] =
    useState(false);

  const [powerUpToPurchase, setPowerUpToPurchase] = useState(null);
  const [shouldOpenPaymentSuccessfulForm, setShouldOpenPaymentSuccessfulForm] =
    useState(false);
  const [shouldOpenJoinedProPlanPopup, setShouldOpenJoinedProPlanPopup] =
    useState(false);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [
    shouldOpenSubPaymentSuccessfulForm,
    setShouldOpenSubPaymentSuccessfulForm,
  ] = useState(false);
  const [shouldOpenReviewForm, setShouldOpenReviewForm] = useState(false);
  const [shouldOpenBuyCoinsForm, setShouldOpenBuyCoinsForm] = useState(false);
  const [shouldOpenReviewSentForm, setShouldOpenReviewSentForm] =
    useState(false);
  const [
    shouldOpenCoinPurchaseSuccessfulForm,
    setShouldOpenCoinPurchaseSuccessfulForm,
  ] = useState(false);
  const [shouldOpenProInviteCodeForm, setShouldOpenProInviteCodeForm] =
    useState(false);

  const toggleBuyCoinsForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setPowerUpToPurchase(null);
    setShouldShowMoreBattleOptions(false);
    setShouldOpenQRCodeBox(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenAdPopup(false);
    console.log(errors);
    setShouldOpenBuyCoinsForm(!shouldOpenBuyCoinsForm);
  };

  const toggleCoinPurchaseSuccessfulForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setPowerUpToPurchase(null);
    setShouldShowMoreBattleOptions(false);
    setShouldOpenQRCodeBox(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);

    setShouldOpenCoinPurchaseSuccessfulForm(
      !shouldOpenCoinPurchaseSuccessfulForm
    );
  };
  const togglePurchasePowerUpForm = (powerUpToPurchase) => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMoreBattleOptions(false);
    setShouldOpenQRCodeBox(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenAdPopup(false);

    setPowerUpToPurchase(powerUpToPurchase);
  };

  const togglePaymentSuccessfulForm = () => {
    setPowerUpToPurchase(null);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMoreBattleOptions(false);
    setShouldOpenQRCodeBox(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenAdPopup(false);

    setShouldOpenPaymentSuccessfulForm(!shouldOpenPaymentSuccessfulForm);
  };
  const toggleShowQRCode = () => {
    setPowerUpToPurchase(null);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMoreBattleOptions(false);
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenAdPopup(false);

    if (shouldOpenQuiz) {
      setShouldOpenQRCodeBox(!shouldOpenQRCodeBox);
      if (size.width < 1024) {
        setShouldOpenQuiz(false);
      }
    } else {
      setShouldOpenQRCodeBox(!shouldOpenQRCodeBox);
    }
  };
  const [viewedReviewForm, setViewedReviewForm] = useState(false);

  const toggleBuySubscriptionForm = useCallback(
    (subscriptionType) => {
      setShouldOpenSubPaymentSuccessfulForm(false);
      setPowerUpToPurchase(null);
      setShouldOpenPaymentSuccessfulForm(false);
      setShouldOpenReviewSentForm(false);
      setShouldOpenBuyCoinsForm(false);
      setShouldShowMoreBattleOptions(false);
      setShouldOpenQRCodeBox(false);
      setShouldShowBattleLeaderboard(false);
      setShouldOpenProInviteCodeForm(false);
      setShouldOpenCoinPurchaseSuccessfulForm(false);
      setShouldOpenAdPopup(false);

      if (subscriptionType !== null) {
        setViewedSubscriptionForm(true);
      }
      setSubscriptionType(subscriptionType);

      if (
        subscriptionType === null &&
        !viewedReviewForm &&
        shouldAskForReview &&
        triviaNight &&
        (triviaNight.status === 0 || triviaNight.status === 5)
      ) {
        setShouldOpenReviewForm(true);
      } else {
        setShouldOpenReviewForm(false);
      }
    },
    [viewedReviewForm, triviaNight, shouldAskForReview]
  );

  const toggleSubPaymentSuccessfulForm = () => {
    setSubscriptionType(null);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMoreBattleOptions(false);
    setShouldOpenQRCodeBox(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenAdPopup(false);

    setShouldOpenSubPaymentSuccessfulForm(!shouldOpenSubPaymentSuccessfulForm);
  };

  const toggleReviewForm = useCallback(() => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewSentForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMoreBattleOptions(false);
    setShouldOpenQRCodeBox(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenAdPopup(false);

    setShouldOpenReviewForm(!shouldOpenReviewForm);
  }, [shouldOpenReviewForm]);

  const toggleReviewSentForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMoreBattleOptions(false);
    setShouldOpenQRCodeBox(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenAdPopup(false);

    setShouldOpenReviewSentForm(!shouldOpenReviewSentForm);
  };

  const toggleLeaderboardPopup = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMoreBattleOptions(false);
    setShouldOpenQRCodeBox(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenAdPopup(false);

    setShouldShowBattleLeaderboard(!shouldShowBattleLeaderboard);
  };

  const toggleJoinedProPlanPopup = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMoreBattleOptions(false);
    setShouldOpenQRCodeBox(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenAdPopup(false);

    setShouldOpenJoinedProPlanPopup(!shouldOpenJoinedProPlanPopup);
  };

  const toggleProInviteCodeForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMoreBattleOptions(false);
    setShouldOpenQRCodeBox(false);
    setShouldOpenReviewSentForm(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenAdPopup(false);

    setShouldOpenProInviteCodeForm(!shouldOpenProInviteCodeForm);
  };

  const toggleAdPopup = (shouldOpen) => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMoreBattleOptions(false);
    setShouldOpenQRCodeBox(false);
    setShouldOpenReviewSentForm(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenAdPopup(shouldOpen);
  };

  useEffect(() => {
    let timeout;
    if (shouldOpenAdPopup) {
      timeout = setTimeout(() => {
        if (shouldOpenAdPopup) {
          toggleAdPopup(false);
        }
      }, 15000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [shouldOpenAdPopup]);
  const [showedOverlayAdOnce, setShowedOverlayAdOnce] = useState(false);

  useEffect(() => {
    if (!loadingIsOutOfLives && !loadingTriviaNight && triviaNight) {
      if (
        triviaNight.status === 1 &&
        triviaNight.lastCompletedQIndex === 1 &&
        !showedOverlayAdOnce &&
        !isOutOfLives &&
        !shouldOpenAdPopup &&
        !numOfExtraLivesNeeded
      ) {
        setShowedOverlayAdOnce(true);
        toggleAdPopup(true);
      } else if (triviaNight && triviaNight.status !== 1 && shouldOpenAdPopup) {
        setShowedOverlayAdOnce(false);
        toggleAdPopup(false);
      }
    }
  }, [
    shouldOpenAdPopup,
    triviaNight,
    isOutOfLives,
    loadingIsOutOfLives,
    targetGuestCustomer,
    showedOverlayAdOnce,
    loadingTriviaNight,
    numOfExtraLivesNeeded,
  ]);

  useEffect(() => {
    const delay = 4000; // 5 seconds in milliseconds
    const timer = setTimeout(() => {
      if (
        !viewedReviewForm &&
        targetCustomer &&
        !shouldOpenBuyCoinsForm &&
        powerUpToPurchase === null &&
        shouldAskForReview &&
        !shouldShowBattleLeaderboard &&
        ((targetCustomer.subscriptionType === null &&
          targetCustomer.balance < 500 &&
          targetCustomer.numOfTKLsPlayed > 3) ||
          targetCustomer.isPaidSubscriber) &&
        triviaNight &&
        (triviaNight.status === 0 || triviaNight.status === 5)
      ) {
        toggleReviewForm();
        setViewedReviewForm(true);
      }
    }, delay);

    // Cleanup function to clear the timeout on unmount or when dependencies change
    return () => {
      clearTimeout(timer);
    };
  }, [
    targetCustomer,
    toggleReviewForm,
    viewedReviewForm,
    shouldAskForReview,
    shouldShowBattleLeaderboard,
    triviaNight,
    powerUpToPurchase,
    shouldOpenBuyCoinsForm,
  ]);

  const [viewedSubscriptionForm, setViewedSubscriptionForm] = useState(false);

  useEffect(() => {
    const delay = 4000; // 5 seconds in milliseconds
    const timer = setTimeout(() => {
      if (
        !viewedSubscriptionForm &&
        targetCustomer &&
        subscriptionType === null &&
        // targetCustomer.balance >= 500 &&
        !targetCustomer.isPaidSubscriber &&
        triviaNight &&
        (triviaNight.status === 0 || triviaNight.status === 5)
      ) {
        toggleBuySubscriptionForm(0);
        setViewedSubscriptionForm(true);
      }
    }, delay);

    // Cleanup function to clear the timeout on unmount or when dependencies change
    return () => {
      clearTimeout(timer);
    };
  }, [
    targetCustomer,
    toggleBuySubscriptionForm,
    subscriptionType,
    viewedSubscriptionForm,
    triviaNight,
  ]);

  const [logTriviaShare] = useMutation(LOG_TRIVIA_SHARE_MUTATION, {
    // refetchQueries: [
    //   {
    //     query: FETCH_TRIVIA_SHARES_BY_CUSTOMER,
    //     variables: { triviaNightId: triviaNight.id, type: 3 },
    //   },
    // ],
    update(_, { data: { logTriviaShare: triviaShare } }) {
      setErrors({});
    },
    onError(err) {
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    client: customerClient,
  });

  useEffect(() => {
    if (
      (!initializedMuteVals ||
        (targetCustomer || targetGuestCustomer).areSoundEffectsMuted !==
          shouldPlaySoundEffects) &&
      (targetCustomer || targetGuestCustomer)
    ) {
      setShouldPlaySoundEffects(
        (targetCustomer || targetGuestCustomer).areSoundEffectsMuted === true
          ? false
          : true
      );
      setInitializedMuteVals(true);
    }
  }, [
    targetGuestCustomer,
    shouldPlaySoundEffects,
    initializedMuteVals,
    targetCustomer,
  ]);
  useEffect(() => {
    const audio = audioRef.current;
    if (shouldPlaySoundEffects) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [shouldPlaySoundEffects]);
  useEffect(() => {
    if (
      shouldPlaySoundEffects &&
      triviaNight &&
      triviaNight.status === 3 &&
      triviaNight.lastCompletedQ !== lastAnsweredQ &&
      selectedAnswer &&
      selectedAnswerStats
    ) {
      if (selectedAnswer.isCorrect) {
        setLastAnsweredQ(selectedAnswer.questionId);
        playCorrectAnswerSound();
      } else {
        setLastAnsweredQ(selectedAnswer.questionId);
      }
    }
  }, [
    shouldPlaySoundEffects,
    selectedAnswer,
    triviaNight,
    lastAnsweredQ,
    playCorrectAnswerSound,
    selectedAnswerStats,
  ]);

  useEffect(() => {
    if (
      triviaNight &&
      ((triviaNight.status >= 5 && triviaWin) ||
        (selectedAnswer &&
          selectedAnswerStats &&
          !isOutOfLives &&
          selectedAnswer.isCorrect &&
          triviaNight.lastCompletedQIndex === triviaNight.numOfQs - 1)) &&
      !hasPlayedAudienceCheer &&
      shouldPlaySoundEffects
    ) {
      playConfettiSound();
      playCheerSound();
      setHasPlayedAudienceCheer(true);
    }
  }, [
    triviaWin,
    triviaNight,
    shouldPlaySoundEffects,
    selectedAnswer,
    hasPlayedAudienceCheer,
    playCheerSound,
    selectedAnswerStats,
    isOutOfLives,
    playConfettiSound,
  ]);

  const OS = getMobileOperatingSystem();

  const [triedOpeningInApp, setTriedOpeningInApp] = useState(false);

  useEffect(() => {
    if (
      !triedOpeningInApp &&
      triviaNight &&
      (OS === "Android" || OS === "iOS")
    ) {
      const appLink = "tknight.live://tkl";
      const a = document.createElement("a");
      a.href = appLink;
      document.body.appendChild(a);
      setTriedOpeningInApp(true);

      a.click();
    }
  }, [triedOpeningInApp, OS, triviaNight]);

  return (
    <div className="w-screen overflow-hidden relative h-full min-h-screen">
      <audio ref={audioRef} src={backgroundMusic} loop volume={0.1} />
      <LiveStreamGradBack
        gradIndex={7}
        // gradIndex={targetCustomer ? targetCustomer.accentColor : 1}
      />
      {shouldOpenPaymentSuccessfulForm && targetCustomer && (
        <PowerUpPaymentSuccessfulForm
          togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
          targetCustomer={targetCustomer}
        />
      )}
      {powerUpToPurchase !== null && targetCustomer && stripe && elements ? (
        <PurchasePowerUpForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
          powerUpToPurchase={powerUpToPurchase}
          triviaNightId={triviaNight ? triviaNight.id : null}
          questionId={curQuestion ? curQuestion.id : null}
          togglePurchasePowerUpForm={togglePurchasePowerUpForm}
        />
      ) : (
        <></>
      )}
      {shouldOpenReviewForm && targetCustomer ? (
        <TNReviewForm
          targetCustomer={targetCustomer}
          shouldOpenReviewForm={shouldOpenReviewForm}
          toggleReviewForm={toggleReviewForm}
          toggleReviewSentForm={toggleReviewSentForm}
        />
      ) : (
        <></>
      )}

      {shouldOpenBuyCoinsForm && stripe && elements ? (
        <BuyCoinsForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          toggleCoinPurchaseSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
          shouldOpenBuyCoinsForm={shouldOpenBuyCoinsForm}
          questionId={null}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
          isHigher={true}
        />
      ) : (
        <></>
      )}

      {subscriptionType !== null && stripe && elements ? (
        <BuySubscriptionForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
          subscriptionType={subscriptionType}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
        />
      ) : (
        <></>
      )}

      {shouldOpenProInviteCodeForm && targetCustomer && (
        <CodeForm
          title="TKnight VIP Code"
          name="inviteCode"
          isCodeBoxOpen={shouldOpenProInviteCodeForm}
          setIsCodeBoxOpen={setShouldOpenProInviteCodeForm}
          typeOfCode={0}
          values={values}
          setValues={setValues}
          toggleJoinedProPlanPopup={toggleJoinedProPlanPopup}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
        />
      )}
      {shouldOpenJoinedProPlanPopup && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleJoinedProPlanPopup}
            targetCustomer={targetCustomer}
            message={"Welcome to TKnight VIP, " + targetCustomer.name + "!"}
            buttonMsg={"Thanks!"}
            popUpTitle={
              "Joined " +
              (targetCustomer.subscriptionType === 0
                ? "Individual"
                : targetCustomer.subscriptionType === 1
                ? "Duo"
                : targetCustomer.subscriptionType === 2
                ? "Family"
                : "Club") +
              " Successfully!"
            }
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={width}
              height={height}
              recycle={false}
              numberOfPieces={width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}
      {shouldOpenSubPaymentSuccessfulForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
            targetCustomer={targetCustomer}
            message={"Welcome to TKnight VIP, " + targetCustomer.name + "."}
            buttonMsg={"Thanks!"}
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}
      {shouldOpenCoinPurchaseSuccessfulForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
            targetCustomer={targetCustomer}
            message={
              "Tokens added to stash. Good luck, " + targetCustomer.name + "!"
            }
            buttonMsg={"Awesome!"}
            popUpTitle={"Payment Successful!"}
          />
        </>
      )}
      {shouldOpenReviewSentForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleReviewSentForm}
            targetCustomer={targetCustomer}
            popUpTitle={"Thank you!"}
            message={"This will help improve the game :)"}
            buttonMsg={"Awesome!"}
          />
        </>
      )}

      {shouldShowMoreBattleOptions && triviaNight && (
        <MiniNavBar
          page="TKL"
          triviaNight={triviaNight}
          targetCustomer={targetCustomer}
          isSharingInviteToApps={isSharingInviteToApps}
          isSharingToApps={isSharingToApps || isSharingInviteToApps}
          isShareCopied={isCaptionCopied || isInviteCopied}
          setIsSharingToApps={setIsSharingToApps}
          navigate={navigate}
          isInviteCopied={isInviteCopied}
          inviteFriends={inviteFriends}
          toggleReviewForm={toggleReviewForm}
          shouldShowMiniNavBar={shouldShowMoreBattleOptions}
          isFirstTime={isFirstTime}
          setIsFirstTime={setIsFirstTime}
          setShouldShowMiniNavBar={setShouldShowMoreBattleOptions}
          logout={logout}
          setShouldPlaySoundEffects={setShouldPlaySoundEffects}
          shouldPlaySoundEffects={shouldPlaySoundEffects}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          shouldShowMoreBattleOptions={shouldShowMoreBattleOptions}
          setShouldShowMoreBattleOptions={setShouldShowMoreBattleOptions}
          shouldOpenQRCodeBox={shouldOpenQRCodeBox}
          setShouldOpenQRCodeBox={setShouldOpenQRCodeBox}
          toggleShowQRCode={toggleShowQRCode}
          toggleLeaderboardPopup={toggleLeaderboardPopup}
        />
      )}
      {shouldShowBattleLeaderboard && triviaNight && (
        <BattleLeaderboard
          guest={guest}
          customer={customer}
          triviaNight={triviaNight}
          allPlayerBattleStats={allPlayerBattleStats}
          logTriviaShare={logTriviaShare}
          setShouldShowBattleLeaderboard={setShouldShowBattleLeaderboard}
          triviaWin={triviaWin}
          targetGuestCustomer={targetGuestCustomer}
          targetCustomer={targetCustomer}
        />
      )}
      <div
        className={`grow-0 ${
          !(targetCustomer && targetCustomer.isPaidSubscriber) &&
          !loadingCustomerInfo
            ? "lg:max-w-2xl xl:max-w-xl"
            : ""
        } h-screen z-10 flex flex-col items-center justify-start max-w-5xl 2xl:max-w-7xl mx-auto xl:px-0 pt-4 xshort:pt-2 sm:py-6 2xl:py-12`}
      >
        <TopBar
          navigate={navigate}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
          targetGuestCustomer={targetGuestCustomer}
          targetCustomer={targetCustomer}
          setShouldShowMiniNavBar={setShouldShowMoreBattleOptions}
          setIsFirstTime={setIsFirstTime}
          customer={customer}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          page="TKL"
        />

        {triviaNight && targetCustomer && targetCustomer.isPaidSubscriber && (
          <div
            onClick={(e) => {
              e.preventDefault();
              setShouldOpenQuiz(!shouldOpenQuiz);
            }}
            className={`text-left hide-button-flash cursor-pointer z-10 transition-all flex items-center justify-start lg:hidden hover:bg-gray-700 shadow-lg rounded-full mt-4 short:mt-3 border-4 border-gray-800 w-full bg-gray-800`}
          >
            <PieTimer
              endTime={
                triviaNight.status === 0
                  ? triviaNight.releaseDate
                  : triviaNight.status === 2 || triviaNight.status === 3
                  ? triviaNight.curQEndsAt
                  : triviaNight.nextQStartsAt
              }
              shouldPlaySoundEffects={
                shouldOpenQuiz ? shouldPlaySoundEffects : false
              }
              imgUrl={defaultPicUrl}
              alt={"Hosted by " + triviaNight.hostName}
              width={size.height < 672 ? 30 : 40}
              lastXSeconds={triviaNight.nextQLastsXSeconds * 1000}
              quiz={triviaNight}
            />
            <p className="sm:text-xl text-white text-center w-full truncate pr-8 font-semibold">
              {triviaNight.status === 0
                ? "TKnight LIVE"
                : triviaNight.status === 1
                ? triviaNight.lastCompletedQIndex === triviaNight.numOfQs - 2
                  ? "Last Question"
                  : "Starting Question " +
                    (triviaNight.lastCompletedQIndex !== null
                      ? triviaNight.lastCompletedQIndex + 2
                      : 1)
                : triviaNight.status === 2
                ? "Question " +
                  (triviaNight.lastCompletedQIndex !== null
                    ? triviaNight.lastCompletedQIndex + 2
                    : 1)
                : triviaNight.status === 3 || triviaNight.status === 4
                ? "Question " +
                  (triviaNight.lastCompletedQIndex !== null
                    ? triviaNight.lastCompletedQIndex + 2
                    : 1) +
                  " Answer"
                : triviaWin
                ? `You won!`
                : `View TKnight Result`}
            </p>
          </div>
        )}
        {triviaNight &&
          targetCustomer &&
          targetCustomer.isPaidSubscriber &&
          !loadingGuestCustomer && (
            <div className="flex w-full overflow-hidden pt-2 lg:py-6 lg:space-x-4 items-start justify-center flex-1">
              <TKLPopup
                toggleQuizForm={() => {
                  setShouldOpenQuiz(!shouldOpenQuiz);
                }}
                refetchAllPlayerBattleStatsByBattle={
                  refetchAllPlayerBattleStatsByBattle
                }
                refetchIsOutOfLives={refetchIsOutOfLives}
                isOutOfLives={isOutOfLives}
                loadingIsOutOfLives={loadingIsOutOfLives}
                refetchNumOfExtraLivesNeeded={refetchNumOfExtraLivesNeeded}
                shouldPlaySoundEffects={shouldPlaySoundEffects}
                refetchCurQ={refetchCurQ}
                numOfExtraLivesNeeded={numOfExtraLivesNeeded}
                loadingNumOfExtraLivesNeeded={loadingNumOfExtraLivesNeeded}
                isRobotPlaying={false}
                shouldOpenQuiz={shouldOpenQuiz}
                refetchCustomer={refetchCustomer}
                refetchProductAds={refetchProductAds}
                triviaNight={triviaNight}
                targetCustomer={targetCustomer}
                targetGuestCustomer={targetGuestCustomer}
                targetPlayer={targetCustomer || targetGuestCustomer}
                triviaWin={triviaWin}
                livesCount={livesCount}
                loadingLivesCount={loadingLivesCount}
                loadingTriviaWin={loadingTriviaWin}
                curQuestion={curQuestion}
                loadingSelectedAnswerStats={loadingSelectedAnswerStats}
                selectedAnswerStats={selectedAnswerStats}
                refetchSelectedAnswerStats={refetchSelectedAnswerStats}
                refetchLivesCount={refetchLivesCount}
                refetchTriviaWin={refetchTriviaWin}
                toggleBuySubscriptionForm={toggleBuySubscriptionForm}
                togglePurchasePowerUpForm={togglePurchasePowerUpForm}
                productAds={productAds}
                toggleLeaderboardPopup={toggleLeaderboardPopup}
                size={size}
                shouldOpenAdPopup={shouldOpenAdPopup}
              />

              <CommentSection
                toggleQuizForm={() => {
                  setShouldOpenQuiz(!shouldOpenQuiz);
                }}
                shouldOpenQRCodeBox={shouldOpenQRCodeBox}
                toggleShowQRCode={toggleShowQRCode}
                triviaNightId={triviaNight.id}
                areCommentsInFocus={size.width >= 1024 ? true : !shouldOpenQuiz}
                navigate={navigate}
                targetCustomer={targetCustomer}
                productAds={productAds}
                triviaNight={triviaNight}
                size={size}
                livesCount={livesCount}
                toggleBuySubscriptionForm={toggleBuySubscriptionForm}
                togglePurchasePowerUpForm={togglePurchasePowerUpForm}
              />
            </div>
          )}

        {!(targetCustomer && targetCustomer.isPaidSubscriber) &&
        !loadingCustomerInfo ? (
          <div
            className={`h-full short:mb-[4rem] mt-4 short:mt-3 lg:mt-6 mb-20 sm:mb-14 lg:mb-3 xshort:pb-0 short:px-2 px-2 sm:px-4 pt-3 sm:pt-4 pb-3 short:pb-2 relative transition-all pointer-events-auto overflow-x-hidden max-h-full flex-shrink w-full lg:max-w-2xl xl:max-w-xl overflow-y-auto flex flex-col items-center justify-start z-40 rounded-xl sm:pb-3 short:pt-3 bg-darker-gray shadow-xl lg:shadow-none lg:rounded-3xl `}
          >
            <div
              className={`px-4 w-full flex z-40 space-x-4 sm:px-0 items-center justify-center`}
            >
              <p
                className={`text-blue-300 group-hover:text-blue-400 font-semibold w-full truncate transition-all  focus:outline-none tracking-wide whitespace-nowrap text-center sm:text-lg 2xl:text-2xl`}
              >
                Trivia Knight LIVE
              </p>
            </div>
            <p
              className={`text-center short:text-sm px-4 pb-1 sm:px-0 text-gray-400 leading-tight`}
            >
              {!triviaNight
                ? ""
                : triviaNight.status === 0
                ? "Starts in "
                : "LIVE right now!"}
              {triviaNight && triviaNight.status === 0 ? (
                <CountdownTimer
                  endDate={triviaNight.releaseDate}
                  hideSpace={true}
                />
              ) : (
                <></>
              )}
            </p>

            <div className="w-full px-4 z-40 flex flex-col items-center justify-center flex-1">
              <p className="text-center leading-tight text-lg 2xl:text-2xl text-gray-400">
                {/* {triviaNight ? "Tonight's " : "Total "}Prize */}
                Win a share of
              </p>
              <p className="text-8xl 2xl:text-9xl leading-none text-white font-black mt-2 mb-3.5">
                {/* $ */}
                {triviaNight?.prizeCoins || ""}
              </p>

              <div className="flex items-center justify-center space-x-3 short:space-x-2">
                {[0, 1, 2].map((val) => (
                  <svg
                    className="h-8 fill-current 2xl:h-12 short:h-6"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    key={val}
                  >
                    <path
                      d="M6.65257 13C10.043 13 12.7915 10.2515 12.7915 6.86106C12.7915 3.47064 10.043 0.722168 6.65257 0.722168C3.26215 0.722168 0.513672 3.47064 0.513672 6.86106C0.513672 10.2515 3.26215 13 6.65257 13Z"
                      fill="#F4900C"
                    />
                    <path
                      d="M6.65257 12.2778C10.043 12.2778 12.7915 9.52931 12.7915 6.1389C12.7915 2.74848 10.043 0 6.65257 0C3.26215 0 0.513672 2.74848 0.513672 6.1389C0.513672 9.52931 3.26215 12.2778 6.65257 12.2778Z"
                      fill="#FFCC4D"
                    />
                    <path
                      d="M6.65224 11.5555C9.44435 11.5555 11.7078 9.29201 11.7078 6.4999C11.7078 3.70779 9.44435 1.44434 6.65224 1.44434C3.86013 1.44434 1.59668 3.70779 1.59668 6.4999C1.59668 9.29201 3.86013 11.5555 6.65224 11.5555Z"
                      fill="#FFE8B6"
                    />
                    <path
                      d="M6.65224 11.1946C9.44435 11.1946 11.7078 8.93117 11.7078 6.13906C11.7078 3.34695 9.44435 1.0835 6.65224 1.0835C3.86013 1.0835 1.59668 3.34695 1.59668 6.13906C1.59668 8.93117 3.86013 11.1946 6.65224 11.1946Z"
                      fill="#FFAC33"
                    />
                    <path
                      d="M6.63662 2.40527C5.64537 2.40527 4.69642 2.79904 3.99849 3.49996C3.30057 4.20088 2.91084 5.15152 2.91507 6.14277C2.9193 7.134 3.31714 8.08468 4.021 8.78559C4.7249 9.4865 5.67725 9.88028 6.66846 9.88028C6.21152 9.88028 5.76422 9.74476 5.38318 9.49089C5.00218 9.23703 4.70449 8.87616 4.52784 8.45399C4.35114 8.03182 4.30342 7.56724 4.39066 7.11906C4.4779 6.67087 4.6962 6.2592 5.01793 5.93608C5.33969 5.61296 5.75044 5.39291 6.19822 5.30376C6.64604 5.21461 7.11079 5.26036 7.53371 5.43523C7.95662 5.61011 8.3187 5.90624 8.57421 6.2862C8.82972 6.66613 8.96712 7.11286 8.96904 7.56984C8.97165 8.18259 8.73075 8.77026 8.29928 9.20354C7.86786 9.63683 7.28125 9.88028 6.66846 9.88028C7.65972 9.88028 8.60867 9.4865 9.30659 8.78559C10.0045 8.08468 10.3942 7.134 10.39 6.14277C10.3858 5.15152 9.98794 4.20088 9.28409 3.49996C8.58018 2.79904 7.62788 2.40527 6.63662 2.40527Z"
                      fill="#FFD983"
                    />
                  </svg>
                  // <svg
                  //   className="h-8 fill-current 2xl:h-12 short:h-6"
                  //   viewBox="0 0 36 30"
                  //   fill="none"
                  //   xmlns="http://www.w3.org/2000/svg"
                  // >
                  //   <path
                  //     d="M2 5C0 5 0 7 0 7V28C0 28 0 30 2 30H34C36 30 36 28 36 28V7C36 7 36 5 34 5H2Z"
                  //     fill="#5C913B"
                  //   />
                  //   <path
                  //     d="M2 0C0 0 0 2 0 2V22C0 22 0 24 2 24H34C36 24 36 22 36 22V2C36 2 36 0 34 0H2Z"
                  //     fill="#A7D28B"
                  //   />
                  //   <path
                  //     d="M25 18.5C28.5899 18.5 31.5 15.5899 31.5 12C31.5 8.41015 28.5899 5.5 25 5.5C21.4101 5.5 18.5 8.41015 18.5 12C18.5 15.5899 21.4101 18.5 25 18.5Z"
                  //     fill="#77B255"
                  //   />
                  //   <path
                  //     d="M33 22.5H3C2.173 22.5 1.5 21.827 1.5 21V3C1.5 2.173 2.173 1.5 3 1.5H33C33.827 1.5 34.5 2.173 34.5 3V21C34.5 21.827 33.827 22.5 33 22.5ZM3 2.5C2.725 2.5 2.5 2.724 2.5 3V21C2.5 21.275 2.725 21.5 3 21.5H33C33.275 21.5 33.5 21.275 33.5 21V3C33.5 2.724 33.275 2.5 33 2.5H3Z"
                  //     fill="#5C913B"
                  //   />
                  //   <path d="M14 0H22V24.062H14V0Z" fill="#FFE8B6" />
                  //   <path d="M14 24H22V30H14V24Z" fill="#FFAC33" />
                  //   <path
                  //     d="M11.8099 14.0228C11.8099 11.0438 6.31694 11.2378 6.31694 9.43881C6.31694 8.56781 7.14994 8.14281 8.11594 8.14281C9.74094 8.14281 10.0299 9.18681 10.7659 9.18681C11.2869 9.18681 11.5379 8.85881 11.5379 8.49081C11.5379 7.63481 10.2419 6.98881 8.99894 6.76481V5.93981C8.99894 5.42481 8.58194 5.00781 8.06694 5.00781C7.55194 5.00781 7.13494 5.42581 7.13494 5.93981V6.79281C5.78094 7.10281 4.61394 8.04281 4.61394 9.57381C4.61394 12.4358 10.1069 12.3198 10.1069 14.3318C10.1069 15.0268 9.35294 15.7228 8.11494 15.7228C6.25794 15.7228 5.63894 14.4658 4.88594 14.4658C4.51794 14.4658 4.18994 14.7748 4.18994 15.2408C4.18994 15.9818 5.42994 16.8718 7.13694 17.1218L7.13594 17.1258V18.0598C7.13594 18.5738 7.55394 18.9918 8.06894 18.9918C8.58294 18.9908 8.99994 18.5728 8.99994 18.0598V17.1258C8.99994 17.1158 8.99494 17.1068 8.99394 17.0978C10.5289 16.8108 11.8099 15.8118 11.8099 14.0228Z"
                  //     fill="#5C913B"
                  //   />
                  // </svg>
                ))}
              </div>
            </div>

            <p
              className={`text-center text-sm lg:text-base py-2 uppercase sm:py-2 text-gray-400 leading-tight`}
            >
              Topics coming up next
            </p>
            <div
              className={`grid grid-cols-7 w-full gap-2 pb-2 sm:pb-3 px-4 sm:px-5`}
            >
              {triviaNight &&
                triviaNight.topicIds &&
                triviaNight.topicIds.length > 0 &&
                triviaNight.topicIds.map((topicId, index) => (
                  <TopicIcon
                    topicId={topicId}
                    width={"w-full"}
                    navigate={navigate}
                    key={index}
                    // disabled={}
                  />
                ))}
              {triviaNight &&
              triviaNight.topicIds &&
              triviaNight.topicIds.length !== 7 ? (
                <TopicIcon
                  width={"w-full"}
                  navigate={navigate}
                  isMystery={true}

                  // disabled={}
                />
              ) : (
                <></>
              )}
            </div>

            <div className="px-3 sm:px-4 w-full">
              <button
                onClick={(e) => {
                  if (targetCustomer && !targetCustomer.isPaidSubscriber) {
                    toggleBuySubscriptionForm(2);
                  } else if (targetCustomer) {
                    inviteFriends(e);
                  } else {
                    navigate("/register/redirect/tkl");
                  }
                }}
                type="button"
                className={`${
                  isCaptionCopied ? " motion-safe:animate-popUp" : " "
                } ${
                  triviaNight &&
                  triviaNight.status > 0 &&
                  triviaNight.status < 5
                    ? ""
                    : ""
                } bg-blue-600 hide-button-flash relative text-xl 2xl:text-2xl hover:bg-blue-700 sm:text-2xl lg:text-2xl overflow-hidden shadow-sm group w-full font-bold uppercase flex transition-all items-center justify-center text-white rounded-full mb-2`}
              >
                <p
                  className={`${
                    isCaptionCopied || isInviteCopied
                      ? "motion-safe:animate-fadeDownFast"
                      : hasCaptionBeenCopiedBefore
                      ? "motion-safe:animate-fadeOutUpFast"
                      : "hidden"
                  }  py-3 sm:py-4 text-black bg-blue-300 inset-0 w-full absolute`}
                >
                  {isSharingToApps
                    ? "Shared!"
                    : isInviteCopied
                    ? "Copied link"
                    : isCaptionCopied
                    ? "Copied stats!"
                    : ""}
                </p>

                <p
                  className={`${
                    isCaptionCopied || isInviteCopied
                      ? "motion-safe:animate-fadeOutDownFast"
                      : hasCaptionBeenCopiedBefore
                      ? "motion-safe:animate-fadeUpFast"
                      : ""
                  } relative py-3 sm:py-4`}
                >
                  {targetCustomer && targetCustomer.isPaidSubscriber
                    ? "Invite a friend"
                    : "Continue"}

                  {triviaNight &&
                  triviaNight.status > 0 &&
                  triviaNight.status < 5 ? (
                    <span className="absolute top-2 -right-5">
                      <span className="relative flex h-3 w-3 group-hover:bg-opacity-20">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full  bg-yellow-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-yellow-500"></span>
                      </span>
                    </span>
                  ) : (
                    <></>
                  )}
                </p>
              </button>

              <p
                className={`2xl:text-lg short:text-xs sm:leading-tight w-full text-sm sm:text-xl lg:text-sm text-gray-400 2xl:leading-tight text-center leading-tight`}
              >
                The prize increases every time a{" "}
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (targetCustomer && targetCustomer.isPaidSubscriber) {
                    } else {
                      toggleBuySubscriptionForm(0);
                    }
                  }}
                  className={
                    targetCustomer && targetCustomer.isPaidSubscriber
                      ? ""
                      : `underline cursor-pointer text-blue-500 hover:text-blue-600 transition-all`
                  }
                >
                  VIP
                </span>{" "}
                joins.
              </p>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      {((size.width >= 1024 && regularHorAd) ||
        (size.width < 1024 && squareAd)) &&
        triviaNight &&
        shouldOpenAdPopup && (
          <HorAdOverlayBox
            targetCustomer={targetCustomer}
            triviaNight={triviaNight}
            targetAdBookmark={
              size.width >= 1024 ? regularHorAdBookmark : squareAdBookmark
            }
            toggleAdPopup={toggleAdPopup}
            overlayAd={size.width >= 1024 ? regularHorAd : squareAd}
            shouldOpenAdPopup={shouldOpenAdPopup}
            values={values}
            isOutOfLives={isOutOfLives}
            endTime={triviaNight.nextQStartsAt}
            imgUrl={
              (size.width >= 1024 ? regularHorAd : squareAd).businessLogoUrl ||
              defaultPicUrl
            }
            alt={"Hosted by " + triviaNight.hostName}
            width={size.width > 1536 ? 250 : 200}
            lastXSeconds={triviaNight.nextQLastsXSeconds * 1000}
          />
        )}

      {targetCustomer && targetCustomer.isPaidSubscriber ? (
        // size.width >= 1024 ? (
        //   <Footer
        //     navigate={navigate}
        //     logout={logout}
        //     targetCustomer={targetCustomer}
        //     customer={customer}
        //     keepDefaultColor={true}
        //   />
        // ) : (
        //   <></>
        // )
        <></>
      ) : (
        <MobileFooter navigate={navigate} page="tkl" />
      )}
    </div>
  );
}

export default TriviaNight;
