import { useMutation } from "@apollo/client";
import moment from "moment";

import React, { useCallback, useEffect, useState } from "react";
import { adminClient, customerClient } from "../../GraphqlApolloClients";

import { useForm } from "../../util/hooks";

import ProfilePicInputField from "./ProfilePicInputField";
import SubmitButton from "./SubmitButton";

import {
  CREATE_ORG_MUTATION,
  FETCH_CUSTOMER,
  FETCH_RECOMMENDED_FRIENDS,
  FETCH_RECOMMENDED_ORG_SECTIONS,
  FETCH_RECOMMENDED_ORGS,
} from "../../util/gqlFunctions";
import TagsInput from "./TagsInput";
import TextInputField from "./TextInputField";

function MakeOrgForm({
  setShouldOpenMakeOrgForm,
  targetCustomer,
  refetchRecommendedOrgs,

  recommendedForums,
}) {
  const [errors, setErrors] = useState({});

  const { onChange, values, setValues, onChangeMedia } = useForm(
    createOrgCallback,
    {
      name: "",
      shortForm: "",
      city: targetCustomer ? targetCustomer.city : "",
      province: "",
      country: targetCustomer ? targetCustomer.country : "United States",
      type: targetCustomer
        ? moment().diff(targetCustomer.birthday, "years") > 22
          ? 2
          : moment().diff(targetCustomer.birthday, "years") > 18
          ? 1
          : 0
        : 0,
      logoFile: null,
      websiteLink: "",
      description: "",
      topicNames: [],
      displayCity: "",
      emailSuffixes: [],
    }
  );
  const [tempLocationVals, setTempLocationVals] = useState({
    country: targetCustomer ? targetCustomer.country : "United States",
    city: targetCustomer ? targetCustomer.city : "",
    province: "",
    displayCity: "",
  });

  const [createOrganization, { loading: loadingCreateOrg }] = useMutation(
    CREATE_ORG_MUTATION,
    {
      refetchQueries: [
        { query: FETCH_RECOMMENDED_ORGS },
        { query: FETCH_RECOMMENDED_ORG_SECTIONS },
        { query: FETCH_RECOMMENDED_FRIENDS, variables: { values } },

        { query: FETCH_CUSTOMER },
      ],
      update(_, { data: { createOrganization: createdOrg } }) {
        setValues({
          ...values,
          ...createdOrg,
        });
        if (targetCustomer) {
          refetchRecommendedOrgs();
          recommendedForums();
        }

        setShouldOpenMakeOrgForm(false);
        setErrors({});
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: targetCustomer ? customerClient : adminClient,
    }
  );

  function createOrgCallback(e) {
    e.preventDefault();
    createOrganization({ variables: { ...values, ...tempLocationVals } });
  }

  function getProvinceFromAddressComponents(components) {
    for (var i = 0; i < components.length; i++) {
      var component = components[i];
      if (component.types.indexOf("administrative_area_level_1") !== -1) {
        return component.long_name;
      }
    }
    return null;
  }
  function getCountryFromAddressComponents(components) {
    for (var i = 0; i < components.length; i++) {
      var component = components[i];
      if (component.types.indexOf("country") !== -1) {
        return component.long_name;
      }
    }
    return null;
  }
  const [initializedCityDropdown, setInitializedCityDropdown] = useState(false);

  const handlePlaceChanged = useCallback(
    (tempCityAutocomplete) => {
      if (tempCityAutocomplete) {
        var place = tempCityAutocomplete.getPlace();
        if (place && place.address_components) {
          var province = getProvinceFromAddressComponents(
            place.address_components
          );
          var country = getCountryFromAddressComponents(
            place.address_components
          );

          setTempLocationVals({
            ...tempLocationVals,
            displayCity:
              place.address_components[0].long_name +
              ", " +
              (province || tempLocationVals.province) +
              ", " +
              (country || tempLocationVals.country),
            province: province || tempLocationVals.province,
            city: place.address_components[0].long_name,
            country: country || tempLocationVals.country,
          });
        }
      }
    },
    [tempLocationVals]
  );

  useEffect(() => {
    if (!initializedCityDropdown) {
      // Initialize the Autocomplete objects
      var tempCityAutocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById("city")
      );

      // Set the types to retrieve only cities and provinces/states
      tempCityAutocomplete.setTypes(["(cities)"]);

      // Use handlePlaceChanged directly in addListener
      tempCityAutocomplete.addListener("place_changed", function () {
        handlePlaceChanged(tempCityAutocomplete);
      });

      setInitializedCityDropdown(true);
    }
  }, [initializedCityDropdown, handlePlaceChanged]);

  return (
    <div className="fixed pt-16 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          setShouldOpenMakeOrgForm(false);
        }}
        className="z-40 bg-gray-500 hide-button-flash overscroll-contain bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-darker-gray shadow-xl rounded-b-none sm:rounded-3xl short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            setShouldOpenMakeOrgForm(false);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-700 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-white text-2xl font-bold w-full short:text-xl short:leading-tight">
          Add School/Work
        </p>
        <p className="text-gray-500 w-full leading-tight">
          This will allow your friends to easily find and battle each other.
        </p>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
        <div className="w-full flex flex-col items-start justify-start space-y-6 mt-6">
          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label htmlFor="type" className="font-semibold text-gray-400 ">
                Type{" "}
                {errors.type && (
                  <span className="text-red-500">{errors.type}</span>
                )}
              </label>
            </div>
            <select
              id="type"
              name="type"
              // autoComplete=""
              value={values.type}
              onChange={(e) => {
                onChange(e, true);
              }}
              disabled={loadingCreateOrg}
              className="sm:text-lg bg-transparent text-white mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-700 border focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value={0}>Middle/High School</option>
              <option value={1}>College/University</option>
              <option value={2}>Business/Non-profit</option>
            </select>
          </div>

          <div className="flex w-full items-start justify-start space-x-4 sm:space-x-8">
            <ProfilePicInputField
              title="Logo"
              name="logoFile"
              onChangeMedia={onChangeMedia}
              value={values.logoUrl}
              shouldNotEdit={true}
              values={values}
              error={errors.logoFile}
              isSquare={true}
            />

            <div className="flex items-start flex-col justify-start space-y-2 sm:text-base text-sm">
              <label className="font-semibold text-gray-200">
                Accent colour
              </label>
              <div className="grid grid-cols-4 sm:grid-cols-6 w-full gap-2 h-full">
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((colour, index) => (
                  <button
                    className={`${
                      index === values.accentColor
                        ? "border-gray-700 border-4 box-border"
                        : "hover:border-gray-600 hover:border-4"
                    } sm:w-10 aspect-square w-8 rounded-full bg-accent-${index} bg-gradient-to-br from-[--gradient-color-1] via-purple-500 to-pink-500 transition-all`}
                    onClick={(e) => {
                      e.preventDefault();

                      setValues({
                        ...values,
                        fieldsToEdit: ["accentColor"],
                        accentColor: index,
                      });
                      // Gradient.apply
                    }}
                    key={index}
                  ></button>
                ))}
              </div>
            </div>
          </div>

          <TextInputField
            title={
              (values.type === 0
                ? "School"
                : values.type === 1
                ? "Institution"
                : "Company") + " name"
            }
            name="name"
            type="text"
            error={errors.name}
            disabled={loadingCreateOrg}
            onChange={onChange}
            maxLen={100}
            value={values.name}
          />
          <TextInputField
            title="Short form"
            name="shortForm"
            type="text"
            error={errors.shortForm}
            disabled={loadingCreateOrg}
            onChange={onChange}
            maxLen={10}
            value={values.shortForm}
          />

          <TextInputField
            title="Website"
            name="websiteLink"
            type={"url"}
            error={errors.websiteLink}
            disabled={loadingCreateOrg}
            onChange={onChange}
            value={values.websiteLink}
            prefix="https://"
          />

          <TagsInput
            title="Email endings"
            name="emailSuffixes"
            type="email"
            placeholder={"pdsb.net, peelschools.org"}
            value={values.emailSuffixes}
            values={values}
            setValues={setValues}
            error={errors.emailSuffixes}
            disabled={loadingCreateOrg}
            maxTags={20}
          />

          <TextInputField
            id="city"
            title="City"
            type="text"
            name="displayCity"
            error={errors.city || errors.country || errors.province}
            disabled={loadingCreateOrg}
            onChange={(e) => {
              setTempLocationVals({
                ...tempLocationVals,
                displayCity: e.target.value,
              });
            }}
            value={tempLocationVals.displayCity}
          />

          <SubmitButton
            onClickFunc={createOrgCallback}
            disabled={loadingCreateOrg}
            title={"SUBMIT"}
            errors={errors}
          />
        </div>
      </form>
    </div>
  );
}

export default MakeOrgForm;
