import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import Confetti from "react-confetti";
import {
  decryptQCipher,
  defaultPicUrl,
  shuffleArray,
  useForm,
  useWindowSize,
} from "../../util/hooks";

import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import { customerClient, guestClient } from "../../GraphqlApolloClients";

import confettiSound from "../../sounds/confetti.mp3";
import cheerSound from "../../sounds/streak-milestone.mp3";

import moment from "moment";
import {
  FETCH_CUSTOMER_PRODUCTS,
  FETCH_FOCUS_STREAK_MILESTONE_BY_CUSTOMER,
  FETCH_LIVES_COUNT_BY_CUSTOMER,
  FETCH_PLAYER_TOPIC_STATS,
  FETCH_QUESTION_BOOKMARK_BY_CUSTOMER,
  FETCH_QUESTION_VIEW_BY_CUSTOMER,
  FETCH_SELECTED_ANSWER_BY_QUESTION,
  FETCH_SELECTED_ANSWER_STATS,
  FETCH_TOPIC,
  LOG_QUESTION_SHARE_MUTATION,
  QUESTION_VIEW_UPDATED_SUBSCRIPTION,
  SAVE_STREAK_WITH_EXTRA_LIFE_MUTATION,
  SEND_QUESTION_VIEW_MUTATION,
  TOGGLE_QUESTION_BOOKMARK_MUTATION,
  UPDATE_QUESTION_VIEW_STATUS_MUTATION,
} from "../../util/gqlFunctions";
import AnimatedCounter from "./AnimatedCounter";
import AnswerBubbleCanvas from "./AnswerBubbleCanvas";
import CycloneIcon from "./CycloneIcon";
import PieTimer from "./PieTimer";
import RateQuestionBox from "./RateQuestionBox";
import SelectAnswerBox from "./SelectAnswerBox";
import StreakIcon from "./StreakIcon";
import TopicIcon from "./TopicIcon";

function FocusQuestionBox({
  question,
  lastIncorrectQIndex,
  setLastIncorrectQIndex,
  setSubPopupReason,
  qFetchErrShownAtQId,
  refetchShouldAskForReview,
  targetCustomer,
  shouldShowCommentSection,

  targetGuestCustomer,

  setShouldOpenAdPopup,
  loadingRecommendedQs,
  shouldAskForReview,
  viewedReviewForm,
  toggleReviewForm,
  setViewedReviewForm,
  isChubby,
  timeZone,
  isQFetchErrShown,
  toggleBuySubscriptionForm,
  togglePurchasePowerUpForm,
  toggleLeaderboardPopup,
  country,
  questionIdToScrollIntoView,
  setQuestionIdToScrollIntoView,
  index,
  shouldOpenAdPopup,
  orgNameForUrl,
  indexVisible,
  setIndexVisible,
  shouldPlaySoundEffects,
  toggleShouldShowCommentSection,
  questionIdInView,
  setFinishedQuestionViewInFocus,
  setSelectedAnswerInFocus,
  refetchCustomer,
  refetchGuestCustomer,
  playerTopicStatsInView,
  setPlayerTopicStatsInView,
  topicShortForm,
  loginGuest,
  guest,
  customer,
  questionFetchErr,
  skippedQuestionIds,
  setSkippedQuestionIds,
}) {
  let navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [isBookmarked, setIsBookmarked] = useState(null);

  const {
    ref: boxRef,
    inView: isBoxVisible,
    // entry,
  } = useInView({
    threshold: 0.7,
  });

  const {
    subscribeToMore: subscribeToMoreQView,

    data: { getQuestionViewByCustomer: questionView } = {},
    loading: loadingQuestionView,
    refetch: refetchQuestionView,
  } = useQuery(FETCH_QUESTION_VIEW_BY_CUSTOMER, {
    client: targetCustomer ? customerClient : guestClient,

    variables: { questionId: question.id },

    onError(err) {
      // console.log(err);
      // if (err.graphQLErrors[0]) {
      //   console.log({
      //     ...err.graphQLErrors[0].extensions.errors,
      //   });
      // }
    },
  });
  const { data: { getQuestionBookmarkByCustomer: questionBookmark } = {} } =
    useQuery(FETCH_QUESTION_BOOKMARK_BY_CUSTOMER, {
      client: targetCustomer ? customerClient : guestClient,

      variables: {
        questionId: question.id,
      },
    });

  const {
    data: { getPlayerTopicStatsByTopic: playerTopicStats } = {},
    refetch: refetchPlayerTopicStats,
  } = useQuery(FETCH_PLAYER_TOPIC_STATS, {
    client: targetCustomer ? customerClient : guestClient,
    variables: {
      topicId: question ? question.topicId : "",
    },
    skip:
      questionIdInView !== question.id ||
      (questionView && questionView.status !== 1) ||
      (questionIdToScrollIntoView && questionIdToScrollIntoView !== question.id)
        ? true
        : false,
  });
  useEffect(() => {
    if (isBookmarked === null && questionBookmark) {
      setIsBookmarked(
        questionBookmark && questionBookmark.status === 0 ? true : false
      );
    }
  }, [isBookmarked, questionBookmark]);

  const { values, setValues } = useForm(sendQuestionViewCallback, {
    questionId: question.id,
    typeOfFocus: topicShortForm ? 1 : 0,
  });

  const [usedExtraLifeOnQuestionID, setUsedExtraLifeOnQuestionID] =
    useState(null);

  const [saveStreakWithExtraLife, { loading: loadingSaveStreakWithExtraLife }] =
    useMutation(SAVE_STREAK_WITH_EXTRA_LIFE_MUTATION, {
      refetchQueries: [
        { query: FETCH_LIVES_COUNT_BY_CUSTOMER },

        {
          query: FETCH_SELECTED_ANSWER_BY_QUESTION,
          variables: { questionId: question.id },
        },
        { query: FETCH_CUSTOMER_PRODUCTS },
      ],
      update(_, { data: { saveStreakWithExtraLife } }) {
        setErrors({});
        // if (question) {
        setUsedExtraLifeOnQuestionID(question.id);
        // }

        setPowerupBoughtMessage("Streak saved!");

        setIsPowerupBoughtMessageShown(true);
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });

          setIsErrMsgShown(true);
          if (err.graphQLErrors[0].extensions.errors) {
            setAnswerErr(err.graphQLErrors[0].extensions.errors.powerupErr);
            setTypeOfErr(1);
          }
        }
      },

      client: targetCustomer ? customerClient : guestClient,
    });

  const {
    data: { getFocusStreakMilestone: mainFocusStreakMilestone } = {},
    refetch: refetchMainFocusStreakMilestone,
  } = useQuery(FETCH_FOCUS_STREAK_MILESTONE_BY_CUSTOMER, {
    client: targetCustomer ? customerClient : guestClient,

    variables: {
      questionViewId: questionView ? questionView.id : "",
      topicId: null,
    },
    skip: questionView && questionView.status === 1 ? false : true,
  });
  const {
    data: { getFocusStreakMilestone: topicFocusStreakMilestone } = {},
    refetch: refetchTopicFocusStreakMilestone,
  } = useQuery(FETCH_FOCUS_STREAK_MILESTONE_BY_CUSTOMER, {
    client: targetCustomer ? customerClient : guestClient,

    variables: {
      questionViewId: questionView ? questionView.id : "",
      topicId: questionView ? questionView.topicId : null,
    },
    skip: questionView && questionView.status === 1 ? false : true,
  });

  useEffect(() => {
    if (
      questionBookmark &&
      (!values.questionBookmarkId ||
        values.questionBookmarkId !== questionBookmark.id)
    ) {
      setValues({
        ...values,
        questionBookmarkId: questionBookmark.id,
      });
    }

    if (
      questionView &&
      (!values.questionViewId || values.questionViewId !== questionView.id)
    ) {
      setValues({ ...values, questionViewId: questionView.id });
    }

    if (question && (!values.questionId || values.questionId !== question.id)) {
      setValues({ ...values, questionId: question.id });
    }
  }, [questionView, questionBookmark, values, setValues, question]);

  const [questionBookmarkErr, setQuestionBookmarkErr] = useState("");

  useEffect(() => {
    let timeout;
    if (questionBookmarkErr) {
      timeout = setTimeout(() => setQuestionBookmarkErr(""), 4000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [questionBookmarkErr]);

  const [toggleBookmarkQuestion, { loading: loadingToggleBookmark }] =
    useMutation(TOGGLE_QUESTION_BOOKMARK_MUTATION, {
      refetchQueries: [
        {
          query: FETCH_QUESTION_BOOKMARK_BY_CUSTOMER,
          variables: { questionId: question.id },
        },
      ],
      update(_, { data: { toggleBookmarkQuestion: questionBookmark } }) {},
      onError(err) {
        if (err.graphQLErrors[0]) {
          console.log(err);

          setAnswerErr("");
          setQuestionBookmarkErr(
            err.graphQLErrors[0].extensions.errors.bookmarkErr
          );
          setIsBookmarked(!isBookmarked);
          setIsErrMsgShown(true);
        }
      },
      client: targetCustomer ? customerClient : guestClient,
      variables: values,
    });

  const [logQuestionShare, { loading: loadingLogQuestionShare }] = useMutation(
    LOG_QUESTION_SHARE_MUTATION,
    {
      update(_, { data: { logQuestionShare: questionShared } }) {},
      onError(err) {
        if (err.graphQLErrors[0]) {
        }
      },
      client: targetCustomer ? customerClient : guestClient,
      variables: values,
    }
  );

  const [submittedAnswerSuccessfully, setSubmittedAnswerSuccessfully] =
    useState(false);

  const [
    updateQuestionViewStatus,
    { loading: loadingUpdateQuestionViewStatus },
  ] = useMutation(UPDATE_QUESTION_VIEW_STATUS_MUTATION, {
    update(_, { data: { updateQuestionViewStatus: updateQuestionViewed } }) {
      setSubmittedAnswerSuccessfully(true);
    },
    onError(err) {
      if (err.graphQLErrors[0]) {
      }
    },
    client: targetCustomer ? customerClient : guestClient,
    variables: values,
  });

  const [sendQuestionView, { loading: loadingSendQuestionView }] = useMutation(
    SEND_QUESTION_VIEW_MUTATION,
    {
      refetchQueries: [
        {
          query: FETCH_QUESTION_VIEW_BY_CUSTOMER,
          variables: { questionId: question.id },
        },
      ],
      update(_, { data: { sendQuestionView: sendQuestionViewObj } }) {
        // console.log("got sent");

        if (
          !targetCustomer &&
          !targetGuestCustomer &&
          sendQuestionViewObj.token
        ) {
          loginGuest({ token: sendQuestionViewObj.token });
        }
        setErrors({});
      },
      onError(err) {
        console.log(err);
        let tempAttemptedSendingQView = false;

        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });

          setQuestionBookmarkErr("");

          setIsErrMsgShown(true);
          if (err.graphQLErrors[0].extensions.errors) {
            if (err.graphQLErrors[0].extensions.errors.sendViewErr) {
              if (targetCustomer) {
                if (targetCustomer.isPaidSubscriber) {
                  setAnswerErr(
                    err.graphQLErrors[0].extensions.errors.bailOutErr ||
                      err.graphQLErrors[0].extensions.errors.sendViewErr
                  );
                } else {
                  tempAttemptedSendingQView = true;
                  setSubPopupReason(
                    "You've reached 6 topic focus questions per hour on TKnight Free."
                  );
                  toggleBuySubscriptionForm(0);
                }
              } else {
                navigate(
                  "/login/redirect/focus" +
                    (orgNameForUrl
                      ? `/org>${orgNameForUrl}`
                      : topicShortForm
                      ? `>${topicShortForm}>${question.id || ""}`
                      : "")
                );
              }
            }
            setTypeOfErr(1);
          }
        }

        if (!tempAttemptedSendingQView) {
          setAttemptedSendingQuizView(tempAttemptedSendingQView);
        }
      },
      client: targetCustomer ? customerClient : guestClient,
      variables: values,
    }
  );

  function sendQuestionViewCallback() {
    sendQuestionView();
  }
  const {
    data: { getSelectedFQuestionAnswer: selectedAnswer } = {},
    // error,
    loading: loadingSelectedAnswer,
    refetch: refetchSelectedAnswer,
  } = useQuery(FETCH_SELECTED_ANSWER_BY_QUESTION, {
    client: targetCustomer ? customerClient : guestClient,

    variables: { questionId: question.id },
  });

  const {
    data: { getSelectedAnswerStatsByQuestion: selectedAnswerStats } = {},
    loading: loadingSelectedAnswerStats,
    refetch: refetchSelectedAnswerStats,
  } = useQuery(FETCH_SELECTED_ANSWER_STATS, {
    client: targetCustomer ? customerClient : guestClient,

    variables: {
      questionId: question.id,
    },
    onError: (err) => {
      // console.log(err);
    },

    skip:
      questionView &&
      questionView.status === 1 &&
      (questionIdInView === question.id ||
        skippedQuestionIds.includes(question.id))
        ? false
        : true,
  });

  const [startedSub, setStartedSub] = useState(false);

  useEffect(() => {
    if (
      questionView &&
      (targetCustomer || targetGuestCustomer) &&
      !startedSub
    ) {
      setStartedSub(true);

      refetchQuestionView({ questionId: questionView.questionId });

      subscribeToMoreQView({
        document: QUESTION_VIEW_UPDATED_SUBSCRIPTION,
        variables: {
          questionId: question.id || "",
          customerId: targetCustomer ? targetCustomer.id : null,
          guestCustomerId:
            targetGuestCustomer && !targetCustomer
              ? targetGuestCustomer.id
              : null,
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (
            !subscriptionData.data ||
            subscriptionData.data.status < prev.status
          ) {
            return prev;
          }

          const updatedQuestionView = subscriptionData.data.questionViewUpdated;
          return {
            ...prev,
            getQuestionViewByCustomer: { ...updatedQuestionView },
          };
        },
      });
    }
  }, [
    subscribeToMoreQView,
    question,
    questionIdInView,
    targetCustomer,
    targetGuestCustomer,
    refetchQuestionView,
    questionView,
    startedSub,
  ]);

  const { data: { getTopicById: targetTopic } = {} } = useQuery(FETCH_TOPIC, {
    variables: { topicId: question ? question.topicId : "" },
    client: targetCustomer ? customerClient : guestClient,
    skip: questionIdInView === question.id ? false : true,
  });

  const [indexClicked, setIndexClicked] = useState(null);

  const { width, height } = useWindowSize();

  function saveStreakWithExtraLifeCallback(e) {
    e.preventDefault();

    saveStreakWithExtraLife({
      variables: { questionViewId: questionView ? questionView.id : "" },
    });
  }

  const [isCaptionCopied, setIsCaptionCopied] = useState(false);
  const [isSharingToApps, setIsSharingToApps] = useState(false);
  const [hasCaptionBeenCopiedBefore, setHasCaptionBeenCopiedBefore] =
    useState(false);

  const resetInterval = 1000;
  useEffect(() => {
    let timeout;
    if (isCaptionCopied) {
      timeout = setTimeout(() => setIsCaptionCopied(false), 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied]);

  var streakWhereExtraLivesAreActivated = 4;
  const [loadingSubmitAnswer, setLoadingSubmitAnswer] = useState(false);

  const shareFocusStreakMilestoneResult = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = null;
    var concatTopicNames = (
      mainFocusStreakMilestone || topicFocusStreakMilestone
    ).topicNames.join(", ");

    textToShare =
      "#TKnight ⚡️ " +
      (mainFocusStreakMilestone || topicFocusStreakMilestone).streak +
      "\n\n";

    textToShare += concatTopicNames + "\n\n";

    textToShare += topicShortForm
      ? "tknight.live/focus/" + topicShortForm
      : "tknight.live/focus";

    if (navigator.share) {
      navigator
        .share({
          title: (mainFocusStreakMilestone || topicFocusStreakMilestone).topicId
            ? "My TKnight " + concatTopicNames[0] + " Streak!"
            : "My TKnight Focus Streak",
          text: textToShare,
        })
        .then(() => {
          setHasCaptionBeenCopiedBefore(true);
          setIsCaptionCopied(true);

          setIsSharingToApps(true);
        })
        .catch((error) => {
          setIsSharingToApps(false);
          navigator.clipboard.writeText(textToShare);

          setIsCaptionCopied(true);
          setHasCaptionBeenCopiedBefore(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setHasCaptionBeenCopiedBefore(true);

      setIsCaptionCopied(true);
    }
  };

  const [isInviteCopied, setIsInviteCopied] = useState(null);

  useEffect(() => {
    let timeout;
    if (isInviteCopied && resetInterval) {
      timeout = setTimeout(() => setIsInviteCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isInviteCopied, resetInterval]);

  const size = useWindowSize();

  // var gameHour = moment(localDate).format("HH");

  const [answerErr, setAnswerErr] = useState(null);
  const [typeOfErr, setTypeOfErr] = useState(0); // 0: answer later, 1: powerup, 2: rate, 3: invite code first, 4: toggle pause

  const [targetMediaUrl, setTargetMediaUrl] = useState(null);
  const [isErrMsgShown, setIsErrMsgShown] = useState(false);
  const [powerupBoughtMessage, setPowerupBoughtMessage] = useState(null);
  const [isPowerupBoughtMessageShown, setIsPowerupBoughtMessageShown] =
    useState(false);

  useEffect(() => {
    if (question.questionMediaUrl && questionView && !targetMediaUrl) {
      setTargetMediaUrl(question.questionMediaUrl);
    } else if (
      question.answerMediaUrl &&
      questionView &&
      questionView.status === 1 &&
      targetMediaUrl !== question.answerMediaUrl
    ) {
      setTargetMediaUrl(question.answerMediaUrl);
    }
  }, [question, questionView, setTargetMediaUrl, targetMediaUrl]);

  useEffect(() => {
    let timeout;
    if (isErrMsgShown && answerErr) {
      timeout = setTimeout(() => setIsErrMsgShown(false), 4000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isErrMsgShown, answerErr]);

  useEffect(() => {
    let timeout;
    if (isPowerupBoughtMessageShown && powerupBoughtMessage) {
      timeout = setTimeout(() => setIsPowerupBoughtMessageShown(false), 4000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isPowerupBoughtMessageShown, powerupBoughtMessage]);

  const [shuffledIndices, setShuffledIndices] = useState(null);

  useEffect(() => {
    if (!shuffledIndices) {
      setShuffledIndices(shuffleArray([0, 1, 2, 3]));
    }
  }, [shuffledIndices]);

  const [
    playConfettiSound,
    { stop: stopConfettiSound, isPlaying: isPlayingConfettiSound },
  ] = useSound(confettiSound, { volume: 0.2 });

  const [hasPlayedConfettiSound, setHasPlayedConfettiSound] = useState(false);
  const [playCheerSound, { stop: stopCheer, isPlaying: isPlayingCheer }] =
    useSound(cheerSound, {
      volume: 0.2,
    });
  const [hasMutedSound, setHasMutedSound] = useState(false);

  useEffect(() => {
    if (
      !hasPlayedConfettiSound &&
      !isPlayingConfettiSound &&
      shouldPlaySoundEffects &&
      questionView &&
      questionIdInView === question.id &&
      questionView.status === 1 &&
      !shouldOpenAdPopup &&
      (mainFocusStreakMilestone || topicFocusStreakMilestone) &&
      !questionIdToScrollIntoView
    ) {
      playConfettiSound();
      playCheerSound();
      setHasPlayedConfettiSound(true);
      setHasMutedSound(false);
    }
  }, [
    questionView,
    playCheerSound,
    mainFocusStreakMilestone,
    playConfettiSound,
    question.id,
    questionIdInView,
    topicFocusStreakMilestone,
    questionIdToScrollIntoView,
    shouldOpenAdPopup,
    shouldPlaySoundEffects,
    hasPlayedConfettiSound,
    isPlayingConfettiSound,
  ]);

  useEffect(() => {
    if (
      (!shouldPlaySoundEffects ||
        ((isPlayingCheer || isPlayingConfettiSound) &&
          !shouldPlaySoundEffects) ||
        questionIdInView !== question.id) &&
      !hasMutedSound
    ) {
      stopConfettiSound();
      stopCheer();
      setHasMutedSound(true);
    }
  }, [
    shouldPlaySoundEffects,
    isPlayingConfettiSound,
    stopConfettiSound,
    isPlayingCheer,
    setHasMutedSound,
    hasMutedSound,
    stopCheer,
    questionIdInView,
    question.id,
  ]);

  const questionRef = useRef();

  useEffect(() => {
    if (question.id === questionIdToScrollIntoView && !loadingRecommendedQs) {
      questionRef.current.scrollIntoView({
        behavior: "instant",
        block: "nearest",
        inline: "center",
      });
    }
  }, [questionIdToScrollIntoView, question.id, index, loadingRecommendedQs]);

  const [attemptedSendingQuizView, setAttemptedSendingQuizView] =
    useState(false);
  useEffect(() => {
    if (
      index === indexVisible &&
      !questionView &&
      !loadingQuestionView &&
      !loadingRecommendedQs &&
      // questionIdToScrollIntoView === null &&
      !attemptedSendingQuizView

      // &&
      // questionIdInView === question.id
    ) {
      // console.log("sent view");
      setAttemptedSendingQuizView(true);
      sendQuestionView();
    }
  }, [
    index,
    indexVisible,
    questionView,
    sendQuestionView,
    question.id,
    loadingRecommendedQs,
    loadingQuestionView,
    attemptedSendingQuizView,
  ]);

  useEffect(() => {
    if (
      index === indexVisible &&
      question.id === questionIdToScrollIntoView &&
      !loadingRecommendedQs
    ) {
      setQuestionIdToScrollIntoView(null);
    }
  }, [
    index,
    indexVisible,
    setQuestionIdToScrollIntoView,
    questionIdToScrollIntoView,
    loadingRecommendedQs,
    question.id,
  ]);

  useEffect(() => {
    if (isBoxVisible && index !== indexVisible && !loadingRecommendedQs) {
      setIndexVisible(index);
    }
  }, [
    index,
    indexVisible,
    setIndexVisible,

    isBoxVisible,

    loadingRecommendedQs,
  ]);

  const [shouldShowAnsResult, setShouldShowAnsResult] = useState(null);
  useEffect(() => {
    if (guest && !targetGuestCustomer) {
      refetchGuestCustomer();
    }
  }, [guest, targetGuestCustomer, refetchGuestCustomer]);

  useEffect(() => {
    if (
      questionView &&
      questionView.status === 0 &&
      questionIdInView !== question.id &&
      !skippedQuestionIds.includes(question.id)
    ) {
      setSkippedQuestionIds([...skippedQuestionIds, question.id]);
    }
  }, [
    questionView,
    question,
    skippedQuestionIds,
    questionIdInView,
    setSkippedQuestionIds,
    loadingQuestionView,
  ]);
  const [lastAnswerResult, setLastAnswerResult] = useState(-10);

  useEffect(() => {
    if (
      questionView &&
      questionView.status === 1 &&
      lastAnswerResult !== questionView.answerResult &&
      questionIdInView === question.id &&
      !loadingLogQuestionShare &&
      !loadingQuestionView &&
      !loadingSelectedAnswerStats &&
      !loadingSelectedAnswer &&
      !loadingToggleBookmark &&
      !loadingUpdateQuestionViewStatus &&
      !loadingRecommendedQs &&
      !loadingSaveStreakWithExtraLife &&
      !loadingSendQuestionView &&
      !loadingSubmitAnswer
    ) {
      refetchQuestionView({ questionId: questionView.questionId });

      refetchSelectedAnswer({ questionId: questionView.questionId });
      refetchShouldAskForReview({ askingFromLoc: 0 });
      refetchPlayerTopicStats({ topicId: questionView.topicId });
      refetchSelectedAnswerStats({
        questionId: questionView.questionId,
      });
      if (customer) {
        refetchCustomer();
      }
      if (guest) {
        refetchGuestCustomer();
      }
      refetchMainFocusStreakMilestone({
        questionViewId: questionView.id,
        topicId: null,
      });
      refetchTopicFocusStreakMilestone({
        questionViewId: questionView.id,
        topicId: questionView.topicId,
      });
      setLoadingSubmitAnswer(false);

      if (selectedAnswer && selectedAnswer.isCorrect) {
        setSelectedAnswerInFocus(selectedAnswer);
        const fifteenSecsAfterQEndAt = new Date(
          new Date(questionView.qEndsAt).getTime() + 15 * 1000
        );

        if (targetCustomer || targetGuestCustomer) {
          var fourHoursAfterAccountCreation = new Date(
            new Date(
              (targetCustomer || targetGuestCustomer).createdAt
            ).getTime() +
              4 * 60 * 60 * 1000
          );
        }

        if (
          index === indexVisible &&
          new Date() < fifteenSecsAfterQEndAt &&
          selectedAnswer &&
          selectedAnswer.isCorrect
        ) {
          if (indexClicked !== null) {
            if (
              index % 7 === 1 &&
              (targetCustomer || targetGuestCustomer) &&
              !(targetCustomer || targetGuestCustomer).isPaidSubscriber &&
              fourHoursAfterAccountCreation &&
              new Date() > fourHoursAfterAccountCreation
            ) {
              setShouldOpenAdPopup(true);
            } else {
              setShouldOpenAdPopup(false);
            }
          }
        }
      } else if (selectedAnswer && !selectedAnswer.isCorrect) {
        setSelectedAnswerInFocus(selectedAnswer);
        setShouldShowAnsResult(true);
      }
      setFinishedQuestionViewInFocus(questionView);

      if (skippedQuestionIds.includes(question.id)) {
        let skippedQuestionIdsCopy = [...skippedQuestionIds];
        const indexToRemove = skippedQuestionIdsCopy.indexOf(question.id);

        if (indexToRemove > -1) {
          skippedQuestionIdsCopy.splice(indexToRemove, 1);
          setSkippedQuestionIds(skippedQuestionIdsCopy);
        }
      }
      setLastAnswerResult(questionView.answerResult);
    }
  }, [
    questionView,
    refetchSelectedAnswerStats,
    selectedAnswerStats,
    lastAnswerResult,
    setLastAnswerResult,
    indexVisible,
    question.id,
    targetCustomer,
    targetGuestCustomer,
    refetchQuestionView,
    refetchSelectedAnswer,
    questionIdInView,
    loadingLogQuestionShare,
    index,
    indexClicked,
    guest,
    customer,
    skippedQuestionIds,
    setSkippedQuestionIds,
    // targetCustomer,
    setShouldOpenAdPopup,
    setFinishedQuestionViewInFocus,
    selectedAnswer,
    // targetGuestCustomer,
    setSelectedAnswerInFocus,
    loadingQuestionView,
    loadingRecommendedQs,
    loadingSaveStreakWithExtraLife,
    loadingSelectedAnswer,
    loadingSelectedAnswerStats,
    loadingSendQuestionView,
    loadingSubmitAnswer,
    loadingToggleBookmark,
    loadingUpdateQuestionViewStatus,
    refetchCustomer,

    refetchGuestCustomer,
    setShouldShowAnsResult,
    refetchPlayerTopicStats,
    refetchMainFocusStreakMilestone,
    refetchShouldAskForReview,
    refetchTopicFocusStreakMilestone,
  ]);

  useEffect(() => {
    if (
      questionView &&
      questionIdInView === question.id &&
      playerTopicStats &&
      playerTopicStatsInView !== playerTopicStats &&
      lastAnswerResult !== questionView.answerResult
    ) {
      setPlayerTopicStatsInView(playerTopicStats);
    }
  }, [
    playerTopicStats,
    playerTopicStatsInView,
    setPlayerTopicStatsInView,
    lastAnswerResult,
    questionIdInView,
    questionView,
    question.id,
  ]);

  useEffect(() => {
    // const delay = 4000; // 5 seconds in milliseconds
    // const timer = setTimeout(() => {
    if (
      !viewedReviewForm &&
      shouldAskForReview &&
      questionIdInView === question.id &&
      questionView &&
      questionView.status === 1 &&
      !shouldOpenAdPopup &&
      questionView.answerResult === 3 &&
      index >= 4 &&
      index % 7 !== 1 &&
      !loadingRecommendedQs
    ) {
      toggleReviewForm();
      setViewedReviewForm(true);
    }
    // }, delay);
    // toggle at the end of the 5th question if they got it right

    // Cleanup function to clear the timeout on unmount or when dependencies change
    // return () => {
    //   clearTimeout(timer);
    // };
  }, [
    targetCustomer,
    targetGuestCustomer,
    toggleReviewForm,
    viewedReviewForm,
    shouldAskForReview,
    question.id,
    questionView,
    setViewedReviewForm,
    questionIdInView,
    shouldOpenAdPopup,
    index,
    loadingRecommendedQs,
  ]);

  const [shouldReverseAnsStreakCounter, setShouldReverseAnsStreakCounter] =
    useState(null);
  useEffect(() => {
    let timeout;

    if (shouldShowAnsResult === true && !shouldReverseAnsStreakCounter) {
      timeout = setTimeout(() => {
        setShouldShowAnsResult(false);
        setShouldReverseAnsStreakCounter(false);
      }, 4000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [shouldShowAnsResult, shouldReverseAnsStreakCounter]);

  useEffect(() => {
    let timeout;

    if (shouldReverseAnsStreakCounter === false) {
      timeout = setTimeout(() => {
        setShouldReverseAnsStreakCounter(true);
      }, 12100);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [shouldReverseAnsStreakCounter]);

  const shareQuestionInfo = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare =
      "TKnight " +
      (selectedAnswer && selectedAnswer.isCorrect ? "🟢 " : "⚫️ ") +
      targetTopic.name +
      "\n";

    const link =
      "https://tknight.live/focus/" +
      targetTopic.shortForm +
      "/" +
      question.id +
      "\n";

    if (navigator.share) {
      navigator
        .share({
          title: "Question about " + targetTopic.name + " on TKnight!",
          text: textToShare,
          url: link,
          // url:
          //   playersPhraze && playersPhraze.isEligibleForPrize
          //     ? "W/" + playersPhraze.id
          //     : "phraze",
        })
        .then(() => {
          setHasCaptionBeenCopiedBefore(true);
          setIsCaptionCopied(true);

          setIsSharingToApps(true);
        })
        .catch((error) => {
          setIsSharingToApps(false);
          navigator.clipboard.writeText(textToShare + link);
          // navigator.clipboard.writeText(textToShare + " worldphraze.com");
          setIsCaptionCopied(true);
          setHasCaptionBeenCopiedBefore(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare + link);
      setHasCaptionBeenCopiedBefore(true);

      setIsCaptionCopied(true);
    }

    // if (triviaWin) {
    // && (!triviaWinShares || triviaWinShares.length === 0)
    // await logTriviaShareEvent(3);
    // }
    // if (
    //   !triviaWinShares ||
    //   (triviaWinShares && triviaWinShares.length < 10)
    // )
    // else {
    //   if (quiz.status >= 5) {
    await logQuestionShare();
    //   } else if (quiz.status === 0) {
    //     await logTriviaShareEvent(0);
    //   } else {
    //     await logTriviaShareEvent(1);
    //   }
    // }
  };

  const [shouldShowStreakMilestone, setShouldShowStreakMilestone] =
    useState(false);

  const [finishedSettingOffset, setFinishedSettingOffset] = useState(false);

  useEffect(() => {
    if (
      !finishedSettingOffset &&
      questionView &&
      questionView.status === 1 &&
      questionView.answerResult !== 3 &&
      selectedAnswerStats &&
      ((!selectedAnswer && indexClicked === null) ||
        (selectedAnswer && selectedAnswer.isCorrect !== null)) &&
      (lastIncorrectQIndex === null || lastIncorrectQIndex <= index)
    ) {
      if (selectedAnswer && selectedAnswer.isCorrect) {
        setFinishedSettingOffset(true);
      } else if (
        questionView.answerResult === 2 &&
        lastIncorrectQIndex === index
      ) {
        setLastIncorrectQIndex(null);
        setFinishedSettingOffset(true);
      } else if (
        questionView.answerResult !== 2 &&
        (lastIncorrectQIndex === null || lastIncorrectQIndex < index)
      ) {
        setLastIncorrectQIndex(index);
      }
    }
  }, [
    setLastIncorrectQIndex,
    questionView,
    index,
    lastIncorrectQIndex,
    selectedAnswerStats,
    selectedAnswer,
    indexClicked,
    finishedSettingOffset,
  ]);

  useEffect(() => {
    let timeout;

    if (
      !shouldShowStreakMilestone &&
      (mainFocusStreakMilestone || topicFocusStreakMilestone)
    ) {
      timeout = setTimeout(
        () => {
          setShouldShowStreakMilestone(true);
        },
        500
        // indexClicked !== null ? 2000 : 2000
      );
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [
    shouldShowStreakMilestone,
    topicFocusStreakMilestone,
    mainFocusStreakMilestone,
    indexClicked,
  ]);

  const [secondsUntilNextQ, setSecondsUntilNextQ] = useState(null);

  useEffect(() => {
    if (!questionView || questionView.status !== 0) {
      setSecondsUntilNextQ(null);
      return;
    }
    const endTime =
      questionView &&
      questionView.status === 0 &&
      !loadingUpdateQuestionViewStatus &&
      !submittedAnswerSuccessfully
        ? questionView.qEndsAt
        : new Date();
    const targetDate = moment(endTime).toDate(); // specify the target date here
    const updateSeconds = setInterval(() => {
      const currentTime = Date.now();
      const msUntilNextQTemp = targetDate - currentTime;

      if (Math.floor(msUntilNextQTemp / 1000) > -1) {
        setSecondsUntilNextQ(Math.floor(msUntilNextQTemp / 1000));
      } else {
        setSecondsUntilNextQ(null);
      }
    }, 1000);
    return () => clearInterval(updateSeconds);
  }, [
    secondsUntilNextQ,
    loadingUpdateQuestionViewStatus,
    submittedAnswerSuccessfully,
    questionView,
    shouldPlaySoundEffects,
  ]);

  // if (isBoxVisible) {
  //   console.log(
  //     selectedAnswer && selectedAnswer.questionId === question.id
  //       ? selectedAnswer.deltaXP
  //       : null
  //   );
  // }
  const qDesc = decryptQCipher(question.questionDesc);
  return (
    <div
      ref={questionRef}
      className="w-full h-full select-none snap-start snap-always shrink-0"
    >
      <div
        id="gameDiv"
        ref={boxRef}
        // onSubmit={buyBandCallback}
        className={`bg-darker-gray relative flex px-3 sm:px-8 sm:pt-7 pt-3 rounded-2xl sm:rounded-3xl flex-col items-center justify-center shrink-0 w-full h-full grow-0`}
      >
        {questionView && targetMediaUrl && !targetMediaUrl.includes(".mp4") && (
          <div className="absolute z-0 p-1 sm:p-2 select-none rounded-2xl sm:rounded-3xl overflow-hidden inset-0 h-full object-cover origin-center">
            <img
              src={targetMediaUrl}
              alt="Question"
              className="object-cover w-full h-full rounded-xl sm:rounded-2xl"
            />
          </div>
        )}
        {answerErr && (
          <div
            className={`${
              isErrMsgShown
                ? "motion-safe:animate-fadeDownFast"
                : "motion-safe:animate-fadeOutUpFast"
            } truncate sm:max-w-xl z-50 font-semibold py-2 sm:text-lg text-sm px-4 absolute text-center bg-red-600 text-white rounded-lg inset-x-4 short:inset-x-2 sm:inset-x-auto top-3 sm:top-5 short:top-2 lg:w-auto`}
          >
            {answerErr}
          </div>
        )}

        {questionFetchErr && qFetchErrShownAtQId === question.id && (
          <div
            className={`${
              isQFetchErrShown
                ? "motion-safe:animate-fadeDownFast"
                : "motion-safe:animate-fadeOutUpFast"
            } truncate sm:max-w-xl z-50 font-semibold py-2 sm:text-lg text-sm px-4 absolute text-center bg-red-600 text-white rounded-lg inset-x-4 short:inset-x-2 sm:inset-x-auto top-3 sm:top-5 short:top-2 lg:w-auto`}
          >
            {questionFetchErr}
          </div>
        )}

        <div
          className={`${
            isCaptionCopied &&
            !(mainFocusStreakMilestone || topicFocusStreakMilestone)
              ? "motion-safe:animate-fadeDownFast"
              : hasCaptionBeenCopiedBefore &&
                !(mainFocusStreakMilestone || topicFocusStreakMilestone)
              ? "motion-safe:animate-fadeOutUpFast"
              : "hidden"
          } truncate sm:max-w-xl z-50 font-semibold py-2 text-lg short:text-sm px-4 absolute text-center bg-purple-600 text-white rounded-lg inset-x-4 short:inset-x-2 sm:inset-x-auto top-4 sm:top-5 short:top-2 lg:w-auto`}
        >
          Copied question to clipboard!
        </div>

        {questionBookmarkErr && (
          <div
            className={`${
              isErrMsgShown
                ? "motion-safe:animate-fadeDownFast"
                : "motion-safe:animate-fadeOutUpFast"
            } truncate sm:max-w-xl z-50 font-semibold py-2 text-lg short:text-sm px-4 absolute text-center bg-red-600 text-white rounded-lg inset-x-4 short:inset-x-2 sm:inset-x-auto top-4 sm:top-5 short:top-2 lg:w-auto`}
          >
            {questionBookmarkErr}
          </div>
        )}
        {powerupBoughtMessage && (
          <div
            className={`${
              isPowerupBoughtMessageShown
                ? "motion-safe:animate-fadeDownFast"
                : "motion-safe:animate-fadeOutUpFast"
            } truncate sm:max-w-xl z-50 font-semibold py-2 text-lg short:text-sm px-4 absolute text-center bg-purple-600 text-white rounded-lg inset-x-4 short:inset-x-2 sm:inset-x-auto top-4 sm:top-5 short:top-2 lg:w-auto`}
          >
            {powerupBoughtMessage}
          </div>
        )}
        <div
          className={`w-full flex z-40 space-x-4 px-1 sm:px-0 items-center justify-center`}
        >
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleLeaderboardPopup();
            }}
            className="w-1/5 flex items-center justify-start space-x-2"
          >
            <div
              className={`${
                targetMediaUrl
                  ? "text-gray-200 backdrop-blur-sm rounded-lg drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.9)]"
                  : "text-gray-200 "
              } flex items-center justify-start space-x-1.5 sm:space-x-3`}
            >
              <svg
                className="h-4 fill-current 2xl:h-6 short:h-3"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.5 4.5C4.5 2.01375 6.51375 0 9 0C11.4862 0 13.5 2.01375 13.5 4.5C13.5 6.98625 11.4862 9 9 9C6.51375 9 4.5 6.98625 4.5 4.5ZM0 14.625C0 11.6325 5.99625 10.125 9 10.125C12.0037 10.125 18 11.6325 18 14.625V18H0V14.625Z"
                />
              </svg>
              <p className="text-sm short:text-xs 2xl:text-xl font-semibold">
                {shouldShowStreakMilestone &&
                (mainFocusStreakMilestone || topicFocusStreakMilestone)
                  ? (mainFocusStreakMilestone || topicFocusStreakMilestone)
                      .numOfAchieversToday || 1
                  : question.rNumOfAttempts || ""}
              </p>
            </div>
          </button>

          <button
            onClick={(e) => {
              if (questionView && questionView.status === 1) {
                navigate("/topic/" + question.topicShortForm);
              }
            }}
            className="w-full group truncate sm:text-xl lg:text-lg 2xl:text-2xl"
          >
            <p
              className={`${
                targetMediaUrl
                  ? "text-blue-200 group-hover:text-blue-500 bg-black/60 rounded-lg drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.9)]"
                  : "text-blue-300 group-hover:text-blue-400"
              } truncate font-semibold w-full transition-all uppercase focus:outline-none tracking-wide whitespace-nowrap text-center`}
            >
              {/* {questionView?.status + " "} */}
              {/* {index}. {question && question.id}{" "} */}
              {secondsUntilNextQ === 0
                ? "Time's up!"
                : secondsUntilNextQ && secondsUntilNextQ <= 5
                ? secondsUntilNextQ
                : shouldShowStreakMilestone &&
                  (mainFocusStreakMilestone || topicFocusStreakMilestone)
                ? "Wow!"
                : questionView
                ? question.topicName
                : "Ready?"}
            </p>
          </button>

          {shouldShowStreakMilestone &&
          (mainFocusStreakMilestone || topicFocusStreakMilestone) ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                togglePurchasePowerUpForm(1);
              }}
              className="w-1/5 flex items-center justify-end space-x-2"
            >
              <div
                className={`sm:hover:opacity-50 flex items-center justify-end space-x-1.5 transition-all sm:space-x-3 text-gray-200`}
              >
                <svg
                  className={` fill-current text-red-700 h-5 2xl:h-7 short:h-4 `}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z" />
                </svg>

                <p className="text-sm 2xl:text-lg short:text-xs font-semibold">
                  {
                    (mainFocusStreakMilestone || topicFocusStreakMilestone)
                      .numOfLivesUsed
                  }
                </p>
              </div>
            </button>
          ) : (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleBookmarkQuestion();
                setIsBookmarked(
                  questionBookmark && questionBookmark.status === 0
                    ? false
                    : true
                );
              }}
              className="w-1/5 flex items-center justify-end space-x-2"
            >
              <div
                className={`${
                  isErrMsgShown && questionBookmarkErr
                    ? "text-red-700 motion-safe:animate-shake"
                    : "sm:hover:opacity-50"
                } ${
                  targetMediaUrl
                    ? "text-gray-200 backdrop-blur-sm rounded-lg drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.9)]"
                    : "text-gray-200 "
                } flex items-center justify-end space-x-1.5 transition-all sm:space-x-3 `}
              >
                <svg
                  className={`${
                    isBookmarked ? "fill-current" : ""
                  } h-4 2xl:h-6 short:h-3 stroke-current`}
                  viewBox="0 0 14 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.39392 14.0809L7 13.912L6.60608 14.0809L1 16.4835V2C1 1.45228 1.45228 1 2 1H12C12.5477 1 13 1.45228 13 2V16.4835L7.39392 14.0809Z"
                    strokeWidth="2"
                  />
                </svg>

                {(isBookmarked || question.rNumOfBookmarks > 0) && (
                  <p className="text-sm 2xl:text-lg short:text-xs font-semibold">
                    {(isBookmarked || question.rNumOfBookmarks > 0) && (
                      <span>
                        {isBookmarked
                          ? (question.rNumOfBookmarks || 0) + 1
                          : question.rNumOfBookmarks}
                      </span>
                    )}
                  </p>
                )}
              </div>
            </button>
          )}
        </div>

        {(mainFocusStreakMilestone || topicFocusStreakMilestone) &&
        shouldShowStreakMilestone ? (
          <></>
        ) : shouldOpenAdPopup ? (
          <div className="flex-1"></div>
        ) : questionView &&
          !loadingQuestionView &&
          questionView.status === 1 &&
          !questionView.extraLifeAvailabilityEnded &&
          questionView.extraLifeAvailabilityEndsAt &&
          (questionView.answerResult === 0 ||
            questionView.answerResult === -1) &&
          ((playerTopicStats &&
            playerTopicStats.curFocusStreak >=
              streakWhereExtraLivesAreActivated) ||
            (questionView.typeOfFocus === 0 &&
              (targetCustomer || targetGuestCustomer) &&
              (targetCustomer || targetGuestCustomer).curFocusStreak >=
                streakWhereExtraLivesAreActivated)) ? (
          <div className="w-full z-40 flex flex-col items-center justify-center space-y-3 2xl:space-y-5 flex-1">
            <div className="bg-darker-gray/40 backdrop-blur flex items-center justify-center rounded-full p-2">
              <PieTimer
                endTime={questionView.extraLifeAvailabilityEndsAt}
                imgUrl={targetTopic ? targetTopic.logoUrl : defaultPicUrl}
                shouldPlaySoundEffects={
                  questionIdInView === question.id &&
                  !loadingUpdateQuestionViewStatus
                    ? shouldPlaySoundEffects
                    : false
                }
                extraLifeQView={questionView}
                alt={question.topicName}
                width={size.width > 1536 ? 200 : 150}
                lastXSeconds={15 * 1000}
                isPaused={false}
              />
            </div>

            {questionView.typeOfFocus === 0 &&
              (targetCustomer || targetGuestCustomer).curFocusStreak >=
                streakWhereExtraLivesAreActivated && (
                <div className="flex items-center max-w-xs w-full px-10 lg:px-12 justify-center text-lg 2xl:text-xl text-gray-400">
                  <p className="flex-1 whitespace-nowrap">Focus streak</p>
                  <svg
                    className="h-7 mr-1 fill-current text-yellow-500"
                    viewBox="0 0 15 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M4.5 18L6.75 11.25H0L10.5 0L8.25 6.75H15L4.5 18Z" />
                  </svg>

                  <AnimatedCounter
                    duration={shouldReverseAnsStreakCounter ? 2750 : 1000}
                    endValue={
                      (targetCustomer || targetGuestCustomer).curFocusStreak
                    }
                    shouldReverse={shouldReverseAnsStreakCounter}
                  />
                </div>
              )}

            {playerTopicStats &&
              playerTopicStats.curFocusStreak >=
                streakWhereExtraLivesAreActivated && (
                <div className="flex items-center max-w-xs w-full px-10 lg:px-12 justify-center text-lg 2xl:text-xl text-gray-400">
                  <p className="flex-1 whitespace-nowrap">Topic streak</p>
                  <svg
                    className="h-7 mr-1 fill-current text-blue-300"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13.664 5.789c3.015-.714 7.986.497 10.336 2.568-1.74-4.282-6.446-7.357-11.993-7.357-4.904 0-9.084 3.078-9.863 7.265-.794 4.258 2.152 8.84 8.208 9.923-3.019.714-8.005-.5-10.352-2.581 1.729 4.3 6.445 7.393 12.007 7.393l.308-.003c4.828-.099 8.882-3.169 9.633-7.294.778-4.275-2.194-8.827-8.284-9.914zm1 7.643c-.759.771-2.571.755-4.042-.036-1.475-.792-2.046-2.058-1.286-2.829.759-.771 2.568-.755 4.043.037 1.469.792 2.046 2.057 1.285 2.828z" />
                  </svg>

                  <AnimatedCounter
                    duration={shouldReverseAnsStreakCounter ? 2750 : 1000}
                    endValue={
                      playerTopicStats ? playerTopicStats.curFocusStreak : 0
                    }
                    shouldReverse={shouldReverseAnsStreakCounter}
                  />
                </div>
              )}

            {question && question.id !== usedExtraLifeOnQuestionID ? (
              <button
                onClick={saveStreakWithExtraLifeCallback} // todo: get extra lives needed to join back
                className={`${
                  loadingSaveStreakWithExtraLife
                    ? "motion-safe:animate-pulse bg-red-800 hover:bg-red-800"
                    : ""
                } ${
                  answerErr && isErrMsgShown && typeOfErr === 1
                    ? "motion-safe:animate-shake"
                    : ""
                } font-semibold hover:bg-red-800 transition-all group overflow-hidden relative text-lg short:text-base 2xl:text-xl group text-white bg-red-700 hover:shadow-lg disabled:hover:shadow-none px-4 pr-2 py-2 rounded-full shadow`}
              >
                {loadingSaveStreakWithExtraLife && (
                  <div className="absolute motion-safe:animate-fadeDown bg-darker-gray z-40 inset-0 w-full flex items-center justify-start">
                    <svg
                      className={`h-5 px-20 py-0.5 text-gray-400 stroke-current m-auto motion-safe:animate-spin`}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.636 3.636A9 9 0 0119 10"
                        strokeWidth={2}
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                )}
                <div className="flex space-x-2 text-lg short:text-base xshort:text-sm 2xl:text-2xl items-center justify-center">
                  <p>
                    Save your streak
                    {questionView.typeOfFocus === 0 &&
                    playerTopicStats &&
                    playerTopicStats.curFocusStreak &&
                    (targetCustomer || targetGuestCustomer) &&
                    (targetCustomer || targetGuestCustomer).curFocusStreak
                      ? "s"
                      : ""}{" "}
                    for{" "}
                  </p>

                  <div className="flex pl-3 pr-3 xshort:py-0.5 items-center justify-center space-x-1 bg-white rounded-full">
                    <svg
                      className="h-5 fill-current 2xl:h-7 text-red-700"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z" />
                    </svg>
                    <p className=" text-white">
                      {
                        1
                        // numOfExtraLivesNeeded
                      }
                    </p>
                  </div>
                </div>
              </button>
            ) : (
              <div className="flex pb-3 items-center justify-center mt-2">
                <TopicIcon
                  navigate={navigate}
                  topicId={targetTopic.id}
                  disabled={true}
                />
              </div>
            )}
          </div>
        ) : (
          <div
            className={`${
              targetMediaUrl
                ? "sm:justify-end sm:items-start justify-end"
                : "justify-center"
            } transition-all w-full z-40 flex relative flex-col items-center space-y-1 flex-1 py-4`}
          >
            {questionIdInView === question.id &&
              !shouldOpenAdPopup &&
              !questionIdToScrollIntoView &&
              !loadingRecommendedQs && (
                <AnswerBubbleCanvas
                  lastQStartedAt={questionView ? questionView.viewedAt : null}
                  curAnsEndsAt={questionView ? questionView.qEndsAt : null}
                  questionId={question.id}
                  indexClicked={indexClicked}
                  deltaXP={
                    selectedAnswer && selectedAnswer.questionId === question.id
                      ? selectedAnswer.deltaXP
                      : null
                  }
                  questionView={questionView}
                  question={question}
                  targetMediaUrl={targetMediaUrl}
                  targetCustomer={targetCustomer}
                  targetGuestCustomer={targetGuestCustomer}
                />
              )}
            <div
              className={`bg-darker-gray/40 backdrop-blur  flex items-center justify-center rounded-full ${
                targetMediaUrl ? "p-1 " : "p-2 short:p-1"
              }`}
            >
              {questionView && (
                <PieTimer
                  endTime={
                    questionView &&
                    questionView.status === 0 &&
                    !loadingUpdateQuestionViewStatus &&
                    !submittedAnswerSuccessfully
                      ? questionView.qEndsAt
                      : new Date()
                  }
                  shouldPlaySoundEffects={
                    questionIdInView === question.id &&
                    !loadingUpdateQuestionViewStatus
                      ? shouldPlaySoundEffects
                      : false
                  }
                  imgUrl={targetTopic ? targetTopic.logoUrl : defaultPicUrl}
                  alt={question.topicName}
                  width={
                    size.height < 672
                      ? 50
                      : size.width > 1536
                      ? targetMediaUrl
                        ? 60
                        : 90
                      : targetMediaUrl
                      ? 60
                      : 70
                  }
                  lastXSeconds={
                    questionView ? questionView.lastXSeconds * 1000 : 0
                  }
                  questionView={questionView}
                  loading={
                    loadingQuestionView ||
                    loadingUpdateQuestionViewStatus ||
                    loadingRecommendedQs
                  }
                  pauseErr={errors && errors.pause}
                  // isPaused={true}
                />
              )}
            </div>

            <p
              className={`${
                questionView ? "motion-safe:animate-fadeInFast" : "invisible"
              } ${
                targetMediaUrl
                  ? "text-gray-200 sm:text-left backdrop-blur-sm rounded-lg bg-darker-gray/80"
                  : "text-gray-200 "
              } line-clamp-4 max-w-xs overflow-hidden leading-tight short:leading-tight select-none sm:leading-tight ${
                qDesc.length > 100
                  ? "text-sm sm:text-base 2xl:text-xl"
                  : "text-base sm:text-lg 2xl:text-2xl"
              } z-20 font-semibold text-center px-2`}
            >
              {questionView ? qDesc : ""}
            </p>
            {questionView &&
            (question.questionType === 1 || question.questionType === 2) ? (
              <span className="font-normal z-20 2xl:text-xl">
                (most popular answer wins)
              </span>
            ) : (
              ""
            )}
          </div>
        )}

        {(mainFocusStreakMilestone || topicFocusStreakMilestone) &&
        shouldShowStreakMilestone ? (
          <div className="w-full px-4 z-40 flex flex-col items-center justify-center flex-1">
            <p className="text-center leading-tight text-lg 2xl:text-2xl text-gray-500">
              {mainFocusStreakMilestone ? "Focus " : "Topic Focus "}Streak
            </p>

            <p className="text-7xl 2xl:text-9xl leading-none font-black text-white mt-2 mb-3.5">
              {(mainFocusStreakMilestone || topicFocusStreakMilestone).streak}
            </p>

            <div className="flex items-center justify-center space-x-3 short:space-x-2">
              {[0, 1, 2].map((val) =>
                mainFocusStreakMilestone ? (
                  <StreakIcon width={"h-8 2xl:h-12 short:h-6"} key={val} />
                ) : (
                  <CycloneIcon width={"h-8 2xl:h-12 short:h-6"} key={val} />
                )
              )}
            </div>
          </div>
        ) : questionView &&
          questionView.status === 1 &&
          (questionView.answerResult === 0 ||
            questionView.answerResult === -1) &&
          !questionView.extraLifeAvailabilityEnded &&
          questionView.extraLifeAvailabilityEndsAt &&
          ((playerTopicStats &&
            playerTopicStats.curFocusStreak >=
              streakWhereExtraLivesAreActivated) ||
            (questionView &&
              questionView.typeOfFocus === 0 &&
              (targetCustomer || targetGuestCustomer) &&
              (targetCustomer || targetGuestCustomer).curFocusStreak >=
                streakWhereExtraLivesAreActivated)) ? (
          <></>
        ) : !shouldOpenAdPopup ? (
          <div
            className={`${
              targetMediaUrl ? "space-y-2" : "space-y-2 sm:space-y-3"
            } short:space-y-1.5 flex z-40 flex-col items-center justify-center w-full`}
          >
            {questionView && shuffledIndices && shuffledIndices.length > 0 ? (
              shuffledIndices.map((index) => (
                <SelectAnswerBox
                  key={index}
                  shouldPlaySoundEffects={
                    questionIdInView === question.id
                      ? shouldPlaySoundEffects
                      : false
                  }
                  isChubby={isChubby}
                  targetMediaUrl={targetMediaUrl}
                  selectedAnswer={
                    selectedAnswer &&
                    question &&
                    selectedAnswer.questionId === question.id
                      ? selectedAnswer
                      : null
                  }
                  isForFocusMode={true}
                  questionView={questionView}
                  // ansOptions={ansOptions}
                  disabled={
                    // (questionView && questionView.status === 1) ||
                    loadingQuestionView ||
                    loadingSelectedAnswerStats ||
                    loadingSelectedAnswer ||
                    loadingSubmitAnswer ||
                    loadingSendQuestionView
                  }
                  updateQuestionViewStatus={() => {
                    updateQuestionViewStatus({
                      variables: { questionViewId: questionView.id },
                    });
                  }}
                  answerErr={answerErr}
                  setAnswerErr={setAnswerErr}
                  // loadingAnsOptions={loadingAnsOptions}
                  selectedAnswerStats={selectedAnswerStats}
                  question={question}
                  answerIndex={index}
                  setLoadingSubmitAnswer={setLoadingSubmitAnswer}
                  indexClicked={indexClicked}
                  setIndexClicked={setIndexClicked}
                  setIsErrMsgShown={setIsErrMsgShown}
                  targetGuestCustomer={targetGuestCustomer}
                  targetCustomer={targetCustomer}
                />
              ))
            ) : (
              <>
                <div
                  className={`border-gray-600 opacity-50 py-4 2xl:py-6 short:py-2 xshort:py-1.5 px-5 sm:px-8 short:px-3 focus:outline-none hide-button-flash rounded-full transition-colors border-2 overflow-hidden shadow-sm flex items-center relative justify-between w-full`}
                >
                  <p
                    className={`text-xl invisible text-white short:text-base text-left truncate select-none 2xl:text-3xl leading-tight font-semibold z-20`}
                  >
                    Great
                  </p>
                </div>
                <div
                  className={`border-gray-600 opacity-50 py-4 2xl:py-6 short:py-2 xshort:py-1.5 px-5 sm:px-8 short:px-3 focus:outline-none hide-button-flash rounded-full transition-colors border-2 overflow-hidden shadow-sm flex items-center relative justify-between w-full`}
                >
                  <p
                    className={`text-xl invisible text-white short:text-base text-left truncate select-none 2xl:text-3xl leading-tight font-semibold z-20`}
                  >
                    Good
                  </p>
                </div>
                <div
                  className={`border-gray-600 opacity-50 py-4 2xl:py-6 short:py-2 xshort:py-1.5 px-5 sm:px-8 short:px-3 focus:outline-none hide-button-flash rounded-full transition-colors border-2 overflow-hidden shadow-sm flex items-center relative justify-between w-full`}
                >
                  <p
                    className={`text-xl invisible text-white short:text-base text-left truncate select-none 2xl:text-3xl leading-tight font-semibold z-20`}
                  >
                    Meh
                  </p>
                </div>
                <div
                  className={`border-gray-600 opacity-50 py-4 2xl:py-6 short:py-2 xshort:py-1.5 px-5 sm:px-8 short:px-3 focus:outline-none hide-button-flash rounded-full transition-colors border-2 overflow-hidden shadow-sm flex items-center relative justify-between w-full`}
                >
                  <p
                    className={`text-xl invisible text-white short:text-base text-left truncate select-none 2xl:text-3xl leading-tight font-semibold z-20`}
                  >
                    Hi mama!
                  </p>
                </div>
              </>
            )}
          </div>
        ) : (
          <></>
        )}
        {(mainFocusStreakMilestone || topicFocusStreakMilestone) &&
        shouldShowStreakMilestone &&
        (targetCustomer || targetGuestCustomer) &&
        !shouldOpenAdPopup ? (
          <div className="w-full z-40 flex-col flex items-center justify-center space-y-2 rounded-lg">
            <button
              onClick={(e) => {
                shareFocusStreakMilestoneResult(e);
              }}
              type="button"
              className={`${
                isCaptionCopied ? " motion-safe:animate-popUp" : " "
              } relative text-xl sm:text-2xl sm:py-3 lg:py-1 2xl:py-4 lg:text-xl 2xl:text-2xl hover:bg-gray-300 overflow-hidden shadow-sm  group w-full font-bold uppercase flex transition-all items-center justify-center bg-gray-200 text-blue-600 rounded-full pl-4 pr-3 py-1`}
            >
              <p
                className={`${
                  isCaptionCopied
                    ? "motion-safe:animate-fadeDownFast"
                    : hasCaptionBeenCopiedBefore
                    ? "motion-safe:animate-fadeOutUpFast"
                    : "hidden"
                } pb-2 short:pb-1 text-black bg-blue-300 inset-0 short:pt-2 pt-2.5 w-full absolute`}
              >
                {isSharingToApps ? "Shared!" : "Copied"}
              </p>

              <p
                className={`${
                  isCaptionCopied
                    ? "motion-safe:animate-fadeOutDownFast"
                    : hasCaptionBeenCopiedBefore
                    ? "motion-safe:animate-fadeUpFast"
                    : ""
                } py-2 short:py-1`}
              >
                Share
              </p>
            </button>
            <p className="text-sm sm:text-xl lg:text-sm truncate short:text-xs px-2 pb-4 short:pb-2 w-full 2xl:text-lg 2xl:leading-tight text-center whitespace-nowrap text-gray-200 leading-tight">
              Only{" "}
              {(targetGuestCustomer || targetCustomer).city ||
              (targetGuestCustomer || targetCustomer).country
                ? (mainFocusStreakMilestone || topicFocusStreakMilestone)
                    .numOfSameCityAchieversToday
                  ? (mainFocusStreakMilestone || topicFocusStreakMilestone)
                      .numOfSameCityAchieversToday + " people"
                  : (mainFocusStreakMilestone || topicFocusStreakMilestone)
                      .numOfSameCountryAchieversToday
                  ? (mainFocusStreakMilestone || topicFocusStreakMilestone)
                      .numOfSameCountryAchieversToday + " people"
                  : "you"
                : (mainFocusStreakMilestone || topicFocusStreakMilestone)
                    .numOfAchieversToday
                ? (mainFocusStreakMilestone || topicFocusStreakMilestone)
                    .numOfAchieversToday + " people"
                : "you"}{" "}
              achieved this in{" "}
              {targetCustomer && targetCustomer.city
                ? targetCustomer.city
                : (targetGuestCustomer || targetCustomer).country
                ? (targetGuestCustomer || targetCustomer).country
                : "the world"}{" "}
              today!
            </p>
          </div>
        ) : (
          <div className="w-full z-40 short:text-sm flex items-center justify-between space-x-2 rounded-lg">
            <button
              onClick={(e) => {
                e.preventDefault();
                if (questionView && questionView.status === 1) {
                  toggleShouldShowCommentSection();
                }
              }}
              className={`${
                questionView && questionView.status === 1 ? "" : "opacity-50"
              } ${
                shouldShowCommentSection
                  ? targetMediaUrl
                    ? "hover:text-blue-300  text-blue-200"
                    : "hover:text-blue-300  text-blue-200"
                  : targetMediaUrl
                  ? "hover:text-white text-gray-200"
                  : "hover:text-gray-400 text-gray-200"
              } ${
                targetMediaUrl
                  ? "drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.9)]"
                  : ""
              } hide-button-flash focus:outline-none w-1/5 px-2 pb-3 pt-2 sm:py-4 xshort:py-2 flex transition-colors items-center justify-start short:space-x-1 space-x-2`}
            >
              <svg
                className="h-5 2xl:h-7 short:h-4 fill-current shrink-0"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.6668 0.833374H1.75016C1.246 0.833374 0.833496 1.24587 0.833496 1.75004V14.5834L4.50016 10.9167H13.6668C14.171 10.9167 14.5835 10.5042 14.5835 10V1.75004C14.5835 1.24587 14.171 0.833374 13.6668 0.833374ZM12.7502 2.66671V9.08337H3.73933L2.66683 10.1559V2.66671H12.7502ZM16.4168 4.50004H18.2502C18.7543 4.50004 19.1668 4.91254 19.1668 5.41671V19.1667L15.5002 15.5H5.41683C4.91266 15.5 4.50016 15.0875 4.50016 14.5834V12.75H16.4168V4.50004Z"
                />
              </svg>

              <p className="leading-none">{question.rNumOfComments || ""}</p>
            </button>
            <RateQuestionBox
              questionId={question.id}
              setAnswerErr={setAnswerErr}
              setIsErrMsgShown={setIsErrMsgShown}
              targetCustomer={targetCustomer}
              setTypeOfErr={setTypeOfErr}
              targetMediaUrl={targetMediaUrl}
            />
            <button
              onClick={shareQuestionInfo}
              className={`${
                targetMediaUrl
                  ? "drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.9)] hover:text-white text-gray-200"
                  : "hover:text-gray-400 text-gray-200"
              } w-1/5 px-2 pb-3 pt-2 sm:py-4 xshort:py-2 flex items-center justify-end short:space-x-1 space-x-2`}
            >
              <svg
                className="h-4 short:h-3.5 2xl:h-6 fill-current shrink-0"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11 4.5V0.5L18 7.5L11 14.5V10.4C6 10.4 2.5 12 0 15.5C1 10.5 4 5.5 11 4.5Z" />
              </svg>

              <p className="leading-none">{question.rNumOfShares || ""}</p>
            </button>
          </div>
        )}
      </div>

      {questionView &&
        questionIdInView === question.id &&
        questionView.status === 1 &&
        !shouldOpenAdPopup &&
        (mainFocusStreakMilestone || topicFocusStreakMilestone) &&
        !questionIdToScrollIntoView && (
          <div className="absolute z-50 pointer-events-none inset-0">
            <Confetti
              width={width}
              height={height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
              colors={
                // targetTopic.accentColor !== 1
                //   ? ["#991b1b", "#f87171", "#dc2626", "#7c2d12"]
                topicFocusStreakMilestone
                  ? ["#c084fc", "#FFD700", "#7e22ce"]
                  : ["#FFF275", "#FFD700", "#B8860B"]
              }
            />
          </div>
        )}
    </div>
  );
}

export default FocusQuestionBox;
