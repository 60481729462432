import { useMutation, useQuery } from "@apollo/client";

import React, { useState } from "react";
import { adminClient } from "../../GraphqlApolloClients";

import {
  DELETE_QUESTION,
  EDIT_QUESTION_MUTATION,
  EDIT_TRIVIA_NIGHT,
  FETCH_QUESTIONS_BY_TN_FOR_ADMIN,
  FETCH_TRIVIA_NIGHTS,
} from "../../util/gqlFunctions";
import { countries, useForm } from "../../util/hooks";
import EditQuestion from "./EditQuestion";
import MakeQuestion from "./MakeQuestion";
import QuestionSummaryBox from "./QuestionSummaryBox";
import TagsInput from "./TagsInput";

function EditTriviaNight({ targetTN, setTargetTN, navigate }) {
  const [errors, setErrors] = useState({});

  const { onChange, values, setValues } = useForm(editTNCallback, {
    triviaNightId: targetTN.id,
    fieldsToEdit: [],
    dayNum: targetTN.dayNum,
    country: targetTN.country,
    topic: targetTN.topic,
    prizeCoins: targetTN.prizeCoins,
  });

  const [editTriviaNight, { loading: loadingEditTN }] = useMutation(
    EDIT_TRIVIA_NIGHT,
    {
      refetchQueries: [{ query: FETCH_TRIVIA_NIGHTS }],
      update(_, { data: { editTriviaNight: triviaNight } }) {
        setValues({
          ...values,
          dayNum: triviaNight.country,
          country: triviaNight.country,
          topic: triviaNight.topic,
          prizeCoins: triviaNight.prizeCoins,
          fieldsToEdit: [],
        });
        setTargetTN(null);
        setErrors({});
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: adminClient,
    }
  );

  function setFieldsToEdit(name) {
    if (values.fieldsToEdit && values.fieldsToEdit.includes(name) && targetTN) {
      if (name.includes("Amount")) {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: targetTN[name] ? Math.floor(targetTN[name] / 100) : 10,
        });
      } else {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: targetTN[name] || "",
        });
      }
    } else {
      setValues({
        ...values,
        fieldsToEdit: [name],
      });
    }
  }

  function editTNCallback(e) {
    e.preventDefault();
    editTriviaNight();
  }

  const {
    data: { getQuestionsByTriviaNightForAdmin: questionsByTN } = {},
    // loading: loadingQs,
    // subscribeToMore,
  } = useQuery(FETCH_QUESTIONS_BY_TN_FOR_ADMIN, {
    client: adminClient,
    variables: { triviaNightId: targetTN.id },
  });

  const [editQIndex, setEditQIndex] = useState(false);
  const [shouldOpenCreateQ, setShouldOpenCreateQ] = useState(false);

  const [deleteQuestion, { loading: loadingDeleteQ }] = useMutation(
    DELETE_QUESTION,
    {
      refetchQueries: [
        {
          query: FETCH_QUESTIONS_BY_TN_FOR_ADMIN,
          variables: { triviaNightId: targetTN.id },
        },
      ],
      update(_, { data: { deleteQuestion: deleteQ } }) {
        console.log(errors);
      },
      onError(err) {
        console.log(err);
        // setValues({ ...values, shouldDelete: false });
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      // variables: { questionId: triviaNight.id },
      client: adminClient,
    }
  );

  const [editQuestion, { loading: loadingEditQ }] = useMutation(
    EDIT_QUESTION_MUTATION,
    {
      refetchQueries: [
        {
          query: FETCH_QUESTIONS_BY_TN_FOR_ADMIN,
          variables: { triviaNightId: targetTN.id },
        },
      ],
      update(_, { data: { editQuestion: editQ } }) {
        console.log(errors);
      },
      onError(err) {
        console.log(err);
        // setValues({ ...values, shouldDelete: false });
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      // variables: { questionId: triviaNight.id },
      client: adminClient,
    }
  );

  return (
    <div className="fixed pt-16 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      {shouldOpenCreateQ && (
        <MakeQuestion
          setShouldOpenCreateQ={setShouldOpenCreateQ}
          triviaNightId={targetTN.id}
        />
      )}
      {editQIndex != null &&
        questionsByTN &&
        questionsByTN.length > 0 &&
        questionsByTN[editQIndex] && (
          <EditQuestion
            setEditQIndex={setEditQIndex}
            question={questionsByTN[editQIndex]}
            navigate={navigate}
          />
        )}

      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          setTargetTN(null);
        }}
        className="z-40 bg-gray-500 hide-button-flash overscroll-contain bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-darker-gray shadow-xl rounded-b-none sm:rounded-3xl short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            setTargetTN(null);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-700 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-white text-2xl font-bold w-full short:text-xl short:leading-tight">
          Trivia Night {targetTN.dayNum}
        </p>
        <p className="text-gray-400 w-full">
          Make questions and set prize amount
        </p>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
        <div className="w-full flex flex-col items-start justify-start space-y-6 mt-6">
          {/* <TextInputField
            title="Day number"
            name="dayNum"
            type="number"
            min={targetTN.dayNum}
            error={errors.dayNum}
            disabled={loadingEditTN}
            onChange={onChange}
            onChangeNumber={onChangeNumber}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editTriviaNight}
            value={values.dayNum}
          /> */}
          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label htmlFor="country" className="font-semibold text-gray-400 ">
                Country{" "}
                {errors.country &&
                  (!values.fieldsToEdit ||
                    (values.fieldsToEdit &&
                      values.fieldsToEdit.includes("country"))) && (
                    <span className="text-red-500">{errors.country}</span>
                  )}
              </label>
              {values.fieldsToEdit.length === 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      fieldsToEdit: ["country"],
                    });
                  }}
                  className="text-blue-300 transition-all font-semibold hover:text-blue-400"
                >
                  Edit
                </button>
              )}
              {values.fieldsToEdit.includes("country") && (
                <div className="flex items-center justify-end space-x-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldsToEdit("country");
                    }}
                    className="text-red-500 transition-all font-semibold hover:text-red-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      editTriviaNight();
                    }}
                    className="text-green-500 transition-all font-semibold hover:text-green-700"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <select
              id="country"
              name="country"
              autoComplete="country-name"
              value={values.country}
              onChange={onChange}
              disabled={
                loadingEditTN ||
                (values.fieldsToEdit &&
                  !values.fieldsToEdit.includes("country"))
              }
              className="sm:text-lg bg-transparent text-white mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-700 border focus:border-indigo-500 focus:ring-indigo-500"
            >
              {["All", ...countries].map((countryName, index) => (
                <option key={index} value={countryName}>
                  {countryName}
                </option>
              ))}
            </select>
          </div>

          <TagsInput
            title="Topics"
            name="topicNames"
            value={targetTN.topicNames}
            values={values}
            setValues={setValues}
            error={errors.topicNames}
            disabled={true}
            maxTags={20}
            hideInput={true}
          />

          {/* <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label htmlFor="topic" className="font-semibold text-gray-400 ">
                Topic{" "}
                {errors.topic &&
                  (!values.fieldsToEdit ||
                    (values.fieldsToEdit &&
                      values.fieldsToEdit.includes("topic"))) && (
                    <span className="text-red-500">{errors.topic}</span>
                  )}
              </label>
              {values.fieldsToEdit.length === 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      fieldsToEdit: ["topic"],
                    });
                  }}
                  className="text-blue-300 transition-all font-semibold hover:text-blue-400"
                >
                  Edit
                </button>
              )}
              {values.fieldsToEdit.includes("topic") && (
                <div className="flex items-center justify-end space-x-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldsToEdit("topic");
                    }}
                    className="text-red-500 transition-all font-semibold hover:text-red-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      editTriviaNight();
                    }}
                    className="text-green-500 transition-all font-semibold hover:text-green-700"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <select
              id="topic"
              name="topic"
              // autoComplete=""
              value={values.topic}
              onChange={onChange}
              disabled={
                loadingEditTN ||
                (values.fieldsToEdit && !values.fieldsToEdit.includes("topic"))
              }
              className="sm:text-lg bg-transparent text-white mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-700 border focus:border-indigo-500 focus:ring-indigo-500"
            >
              {topics.map((topicName, index) => (
                <option key={index} value={topicName}>
                  {topicName}
                </option>
              ))}
            </select>
          </div> */}

          {/* <TextInputField
            title="Prize amount"
            name="prizeCoins"
            type="money"
            min={10}
            max={50}
            value={values.prizeCoins}
            onChange={onChange}
            error={errors.prizeCoins}
            disabled={loadingEditTN}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editTriviaNight}
          /> */}

          {questionsByTN && targetTN && questionsByTN.length > 0 && (
            <div className="w-full">
              <div className="flex items-center justify-between mb-1 text-sm sm:text-base">
                <label
                  htmlFor="questions"
                  className="font-semibold text-gray-400 "
                >
                  Questions{" "}
                  {errors.questions &&
                    (!values.fieldsToEdit ||
                      (values.fieldsToEdit &&
                        values.fieldsToEdit.includes("questions"))) && (
                      <span className="text-red-500">{errors.questions}</span>
                    )}
                </label>
              </div>
              <div className="flex flex-col w-full items-center justify-start space-y-2">
                {questionsByTN.map((question, index) => (
                  <QuestionSummaryBox
                    key={index}
                    triviaNight={targetTN}
                    question={question}
                    deleteQuestion={() =>
                      deleteQuestion({
                        variables: { questionId: question.id },
                      })
                    }
                    index={index}
                    setEditQIndex={setEditQIndex}
                    loadingDeleteQ={loadingDeleteQ}
                    moveQuestionDown={() => {
                      editQuestion({
                        variables: {
                          questionId: question.id,
                          fieldsToEdit: ["index"],
                          index: index + 1,
                        },
                      });
                    }}
                    moveQuestionUp={() => {
                      editQuestion({
                        variables: {
                          questionId: question.id,
                          fieldsToEdit: ["index"],
                          index: index - 1,
                        },
                      });
                    }}
                    loadingMoveQuestion={loadingEditQ}
                  />
                ))}
              </div>
            </div>
          )}

          <button
            onClick={(e) => {
              e.preventDefault();
              setShouldOpenCreateQ(true);
            }}
            className="border rounded-md uppercase font-semibold border-gray-700 py-2 w-full text-white text-center hover:bg-gray-800 transition-all"
          >
            Add question
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditTriviaNight;
