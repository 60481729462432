import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { customerClient } from "../../GraphqlApolloClients";
import {
  decryptQCipher,
  defaultPicUrl,
  getRandomInt,
  useForm,
  useWindowSize,
} from "../../util/hooks";

import moment from "moment";
import Confetti from "react-confetti";
import { useInView } from "react-intersection-observer";
import AnswerBubbleCanvas from "./AnswerBubbleCanvas";
import BattleEndCanvas from "./BattleEndCanvas";
import PieTimer from "./PieTimer";

import { useNavigate } from "react-router-dom";

import {
  FETCH_ANS_OPTIONS,
  FETCH_CAN_USE_EXTRA_LIFE,
  FETCH_CUR_SELECTED_ANSWER,
  FETCH_CUSTOMER,
  FETCH_GUEST_CUSTOMER,
  FETCH_IMPRESSIVE_RANKING,
  FETCH_IS_OUT_OF_LIVES,
  FETCH_LIVES_COUNT_BY_CUSTOMER,
  FETCH_PERSONAL_QUIZ_BY_QUIZ_ID,
  FETCH_PLAYER_BATTLE_TOPIC_STATS,
  FETCH_PLAYER_TOPIC_STATS,
  FETCH_QUIZ_BY_ID,
  FETCH_TN_RESULTS_FORMATTED,
  FETCH_TOPIC,
  FETCH_TRIVIA_VIEW_BY_CUSTOMER,
  FETCH_TRIVIA_VIEW_COUNT,
  LOG_TRIVIA_SHARE_MUTATION,
  PAY_AND_JOIN_EVENT_MUTATION,
  REJOIN_GAME_WITH_EXTRA_LIVES_MUTATION,
  SEND_TRIVIA_CHEAT_MUTATION,
  SEND_TRIVIA_VIEW_MUTATION,
  TOGGLE_PAUSE_PERSONAL_QUIZ_MUTATION,
  TOGGLE_PAUSE_QUIZ_MUTATION,
} from "../../util/gqlFunctions";
import CountdownTimer from "./CountdownTimer";

import { GuestAuthContext } from "../../context/guestAuth";
import SecondsCountdown from "./SecondsCountdown";
import SelectAnswerBox from "./SelectAnswerBox";
import TopicIcon from "./TopicIcon";
import TopicLevelCircle from "./TopicLevelCircle";

const welcomeBackMessages = [
  "Welcome back!",
  "You're back in action!",
  "Ready to conquer again?",
  "Resume your noble journey!",
  "The adventure continues!",
  "You're not defeated yet!",
  "It's comeback season!",
  "Keep going!",
  "It's payback time!",
  "You're good to go!",
];
function TKLPopup({
  personalQuiz,
  toggleQuizForm,
  quiz,

  triviaNight,
  targetCustomer,

  shouldOpenAdPopup,
  livesCount,
  refetchProductAds,
  refetchCustomer,
  customer,
  targetPlayer,
  refetchLivesCount,
  refetchNumOfExtraLivesNeeded,
  triviaWin,
  loadingLivesCount,
  refetchAllPlayerBattleStatsByBattle,
  loadingTriviaWin,
  shouldOpenQuiz,
  refetchTriviaWin,
  curQuestion,
  shouldPlaySoundEffects,
  productAds,
  selectedAnswerStats,
  loadingSelectedAnswerStats,
  refetchSelectedAnswerStats,
  toggleBuySubscriptionForm,
  togglePurchasePowerUpForm,
  loadingNumOfExtraLivesNeeded,
  toggleLeaderboardPopup,
  targetGuestCustomer,
  numOfExtraLivesNeeded,
  refetchCurQ,

  refetchIsOutOfLives,
  loadingIsOutOfLives,
  isOutOfLives,
  client,
}) {
  let navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [isFirstCloseComplete, setIsFirstCloseComplete] = useState(false);

  const { loginGuest: loginGuestContext } = useContext(GuestAuthContext);

  const { values, setValues } = useForm(makeCommentCallback, {
    triviaNightId: triviaNight?.id || null,
    quizId: quiz?.id || null,
    message: "",
  });

  const [usedExtraLifeOnQuestionID, setUsedExtraLifeOnQuestionID] =
    useState(null);
  const [isPaused, setIsPaused] = useState(
    (personalQuiz || quiz || triviaNight).isPaused
  );
  useEffect(() => {
    if ((personalQuiz || quiz || triviaNight)?.isPaused !== isPaused) {
      setIsPaused((personalQuiz || quiz || triviaNight)?.isPaused);
    }
  }, [personalQuiz, quiz, triviaNight, isPaused]);

  const [
    rejoinGameWithExtraLives,
    { loading: loadingRejoinGameWithExtraLives },
  ] = useMutation(REJOIN_GAME_WITH_EXTRA_LIVES_MUTATION, {
    refetchQueries: [
      { query: FETCH_LIVES_COUNT_BY_CUSTOMER },
      { query: FETCH_CAN_USE_EXTRA_LIFE },
      { query: FETCH_IS_OUT_OF_LIVES },
      {
        query: FETCH_CUR_SELECTED_ANSWER,
        variables: {
          triviaNightId: triviaNight?.id || null,
          quizId: quiz?.id || null,
        },
      },
      { query: FETCH_CUSTOMER },
    ],
    update(_, { data: { rejoinGameWithExtraLives: extraLife } }) {
      setErrors({});
      if (curQuestion) {
        setUsedExtraLifeOnQuestionID(curQuestion.id);
      }
      setPowerupBoughtMessage(
        welcomeBackMessages[getRandomInt(welcomeBackMessages.length)]
      );
      setIsPowerupBoughtMessageShown(true);
      console.log(errors);
    },
    onError(err) {
      console.log(err);
      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0]);
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
        if (err.graphQLErrors[0].extensions.errors) {
          setAnswerErr(err.graphQLErrors[0].extensions.errors.powerupErr);
          setIsErrMsgShown(true);
        }
      }
    },

    client,
  });

  function makeCommentCallback(e) {
    e.preventDefault();
  }

  const {
    data: { getTriviaViewByCustomer: custTriviaView } = {},
    loading: loadingCustTriviaView,
    refetch: refetchCustTriviaView,
  } = useQuery(FETCH_TRIVIA_VIEW_BY_CUSTOMER, {
    client,
    variables: {
      triviaNightId: triviaNight?.id || null,
      quizId: quiz?.id || null,
    },
  });

  const [sendTriviaView, { loading: loadingSendTriviaView }] = useMutation(
    SEND_TRIVIA_VIEW_MUTATION,
    {
      refetchQueries: [
        {
          query: FETCH_TRIVIA_VIEW_BY_CUSTOMER,
          variables: {
            triviaNightId: triviaNight?.id || null,
            quizId: quiz?.id || null,
          },
        },
        {
          query: FETCH_TRIVIA_VIEW_COUNT,
          variables: {
            triviaNightId: triviaNight?.id || null,
            quizId: quiz?.id || null,
          },
        },
      ],
      update(_, { data: { sendTriviaView: triviaView } }) {
        setErrors({});
      },
      onError(err) {
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      client,
    }
  );

  const [sendTriviaCheat, { loading: loadingSendTriviaCheat }] = useMutation(
    SEND_TRIVIA_CHEAT_MUTATION,
    {
      update(_, { data: { sendTriviaCheat: triviaCheat } }) {
        setErrors({});
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: {
        triviaNightId: triviaNight?.id || null,
        quizId: quiz?.id || null,
        questionId: curQuestion ? curQuestion.id : "",
      },
      client,
    }
  );

  const [logTriviaShare] = useMutation(LOG_TRIVIA_SHARE_MUTATION, {
    // refetchQueries: [
    //   {
    //     query: FETCH_TRIVIA_SHARES_BY_CUSTOMER,
    //     variables: { triviaNightId: triviaNight.id, type: 3 },
    //   },
    // ],
    update(_, { data: { logTriviaShare: triviaShare } }) {
      setErrors({});
    },
    onError(err) {
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    client: customerClient,
  });

  const [togglePauseQuiz, { loading: loadingTogglePauseQuiz }] = useMutation(
    TOGGLE_PAUSE_QUIZ_MUTATION,
    {
      refetchQueries: [
        { query: FETCH_CUSTOMER },
        { query: FETCH_GUEST_CUSTOMER },
        {
          query: FETCH_QUIZ_BY_ID,
          variables: {
            quizId: quiz?.id,
          },
        },
        {
          query: FETCH_PERSONAL_QUIZ_BY_QUIZ_ID,
          variables: {
            quizId: quiz?.id,
          },
        },
      ],
      update(_, { data: { togglePauseQuiz: toggledPauseQuiz } }) {
        setErrors({});
        setTempElapsedTime(null);
      },
      onError(err) {
        setTempElapsedTime(null);

        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });

          console.log(err.graphQLErrors[0]);
          if (err.graphQLErrors[0].extensions.errors) {
            setAnswerErr(err.graphQLErrors[0].extensions.errors.pause);
            setIsErrMsgShown(true);
          }
        }
      },
      variables: { quizId: quiz?.id, secondsLeftAtPause: 10 },
      client,
    }
  );

  const [togglePausePersonalQuiz, { loading: loadingTogglePausePersonalQuiz }] =
    useMutation(TOGGLE_PAUSE_PERSONAL_QUIZ_MUTATION, {
      refetchQueries: [
        { query: FETCH_CUSTOMER },
        { query: FETCH_GUEST_CUSTOMER },
        {
          query: FETCH_QUIZ_BY_ID,
          variables: {
            quizId: quiz?.id,
          },
        },
        {
          query: FETCH_PERSONAL_QUIZ_BY_QUIZ_ID,
          variables: {
            quizId: quiz?.id,
          },
        },
      ],
      update(
        _,
        { data: { togglePausePersonalQuiz: toggledPausePersonalQuiz } }
      ) {
        setErrors({});
        setTempElapsedTime(null);
      },
      onError(err) {
        setTempElapsedTime(null);

        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });

          if (err.graphQLErrors[0].extensions.errors) {
            setAnswerErr(err.graphQLErrors[0].extensions.errors.pause);
            setIsErrMsgShown(true);
          }
        }
      },
      variables: {
        personalQuizId: personalQuiz ? personalQuiz.id : "",
        secondsLeftAtPause: 10,
      },
      client,
    });

  useEffect(() => {
    const sendViewData = async () => {
      sendTriviaView({
        variables: {
          triviaNightId: triviaNight?.id || null,
        },
      });
    };
    //passing getData method to the lifecycle method
    if (
      triviaNight &&
      triviaNight.status < 5 &&
      !custTriviaView &&
      !loadingCustTriviaView &&
      !loadingSendTriviaView &&
      targetPlayer
    ) {
      sendViewData();
    }
  }, [
    setValues,
    values,
    triviaNight,
    custTriviaView,
    loadingCustTriviaView,
    loadingSendTriviaView,
    sendTriviaView,
    targetPlayer,
  ]);

  const {
    data: { getCurSelectedAnswer: selectedAnswer } = {},
    // loading: loadingSelectedAnswer
    refetch: refetchCurSelectedAnswer,
  } = useQuery(FETCH_CUR_SELECTED_ANSWER, {
    client,
    variables: {
      triviaNightId: triviaNight?.id || null,
      quizId: quiz?.id || null,
    },
  });

  const {
    data: { getImpressiveRanking: impressiveRanking } = "",
    refetch: refetchImpressiveRanking,
  } = useQuery(FETCH_IMPRESSIVE_RANKING, {
    client,

    variables: {
      triviaNightId: triviaNight?.id || null,
      quizId: quiz?.id || null,
    },
  });

  const {
    data: { getTNResultsFormatted: tnResultsFormatted } = {},
    refetch: refetchTNResultsFormatted,
  } = useQuery(FETCH_TN_RESULTS_FORMATTED, {
    client: customerClient,
    variables: {
      triviaNightId: triviaNight?.id || null,
      quizId: quiz?.id || null,
    },
  });

  const {
    data: { getAnsOptions: ansOptions } = {},
    loading: loadingAnsOptions,
    refetch: refetchAnsOptions,
  } = useQuery(FETCH_ANS_OPTIONS, {
    client: customerClient,

    // variables: { triviaNightId: triviaNight.id },
  });

  const { data: { getTopicById: targetTopic } = {} } = useQuery(FETCH_TOPIC, {
    variables: {
      topicId: quiz
        ? quiz.topicIds[0]
        : triviaNight?.topicIds[triviaNight.curQIndex] || "",
    },
    client,
  });

  const {
    refetch: refetchPlayerBattleTopicStatsByBattleAndTopic,
    data: {
      getPlayerBattleTopicStatsByBattleAndTopic:
        playerBattleTopicStatsByBattleAndTopic,
    } = {},
    // loading: loadingPlayerBattleTopicStatsByBattleAndTopic,
  } = useQuery(FETCH_PLAYER_BATTLE_TOPIC_STATS, {
    client,
    variables: {
      topicId: quiz ? quiz.topicIds[0] : null,
      quizId: quiz?.id || null,
    },
  });

  function formatViewCount(viewCount) {
    if (viewCount < 1000) {
      return viewCount;
    } else if (viewCount < 1000000) {
      return (viewCount / 1000).toFixed(1) + "K";
    } else {
      return (viewCount / 1000000).toFixed(1) + "M";
    }
  }

  const [indexClicked, setIndexClicked] = useState(null);

  const { width, height } = useWindowSize();

  const logTriviaShareEvent = async (type) => {
    logTriviaShare({
      variables: {
        triviaNightId: triviaNight?.id || null,
        quiz: quiz?.id || null,
        type,
      },
    });
  };
  const [payAndJoinEvent, { loading: loadingPayAndJoinQuiz }] = useMutation(
    PAY_AND_JOIN_EVENT_MUTATION,
    {
      // refetchQueries: [
      //   { query: FETCH_CUSTOMER },
      //   { query: FETCH_GUEST_CUSTOMER },
      //   { query: FETCH_QUIZ_BY_ID, variables: { quizId: quiz?.id } },
      //   {
      //     query: FETCH_TRIVIA_VIEW_BY_CUSTOMER,
      //     variables: {
      //       quizId: quiz?.id,
      //     },
      //   },
      //   {
      //     query: FETCH_PERSONAL_QUIZ_BY_QUIZ_ID,
      //     variables: {
      //       quizId: quiz?.id,
      //     },
      //   },
      //   {
      //     query: FETCH_TRIVIA_VIEW_COUNT,
      //     variables: { quizId: quiz?.id, status: 1 },
      //   },
      // ],
      update(_, { data: { payAndJoinEvent: tvWGuestToken } }) {
        setErrors({});

        if (!targetCustomer && !targetGuestCustomer && tvWGuestToken.token) {
          loginGuestContext({ token: tvWGuestToken.token });
        }

        refetchCustTriviaView({ quizId: tvWGuestToken.quizId });
      },
      onError(err) {
        console.log(values);
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });

          if (
            err.graphQLErrors[0].extensions.errors &&
            err.graphQLErrors[0].extensions.errors.quizCreation
          ) {
            setAnswerErr(err.graphQLErrors[0].extensions.errors.quizCreation);
            setIsErrMsgShown(true);
          }
        }
      },
      variables: values,
      client,
    }
  );
  function rejoinGameWithExtraLivesCallback(e) {
    e.preventDefault();

    rejoinGameWithExtraLives({
      variables: {
        triviaNightId: triviaNight?.id || null,
        quizId: quiz?.id || null,
      },
    });
  }

  const [targetMediaUrl, setTargetMediaUrl] = useState(null);
  const [finishedShowingMysteryTopic, setFinishedShowingMysteryTopic] =
    useState(false);

  const [isCaptionCopied, setIsCaptionCopied] = useState(false);
  const [isSharingToApps, setIsSharingToApps] = useState(false);
  const [hasCaptionBeenCopiedBefore, setHasCaptionBeenCopiedBefore] =
    useState(false);
  const [tempElapsedTime, setTempElapsedTime] = useState(null);

  const resetInterval = 1000;
  useEffect(() => {
    let timeout;
    if (isCaptionCopied && resetInterval) {
      timeout = setTimeout(() => setIsCaptionCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied, resetInterval]);
  const [loadingSubmitAnswer, setLoadingSubmitAnswer] = useState(false);

  const {
    data: { getPlayerTopicStatsByTopic: playerTopicStats } = {},
    // loading: loadingTopicStats,
    // refetch: refetchPlayerTopicStats,
  } = useQuery(FETCH_PLAYER_TOPIC_STATS, {
    client,
    variables: {
      topicId: quiz ? quiz.topicIds[0] : "",
    },
    pollInterval:
      (personalQuiz || quiz) && (personalQuiz || quiz).status >= 5
        ? 1000
        : null,
  });

  const shareTNResult = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    var textToShare = null;
    if (quiz) {
      textToShare = "TKL ⚔️ " + quiz.topicNames[0] + "\n\n";
    } else if (triviaNight) {
      textToShare = "TKL " + triviaNight.dayNum + "\n\n";
    }

    if (tnResultsFormatted) {
      for (let i = 0; i < tnResultsFormatted.length; ++i) {
        textToShare += tnResultsFormatted[i].emoji;
      }
      textToShare += "\n\n";
    }

    if (triviaWin) {
      textToShare += "🪙" + (quiz || triviaNight).prizePerPlayer + "  | ";
    }
    if (
      quiz &&
      playerTopicStats &&
      custTriviaView &&
      custTriviaView.status === 1
    ) {
      textToShare +=
        "🏆 " +
        (quiz && playerTopicStats
          ? playerTopicStats.numOfBattlesWon
          : targetPlayer.numOfTKLsWon);

      textToShare += "\n\n";
    } else if (!triviaWin) {
      textToShare += "\n\n";
    } else {
      textToShare += "\n\n";
    }

    if (navigator.share) {
      navigator
        .share({
          title:
            "TKL " +
            (quiz ? quiz.topicNames[0] + " Battle" : triviaNight.dayNum) +
            " Summary!",
          text: textToShare,
          url: `https://tknight.live/${quiz ? "battle/" + quiz.id : "tnl"}`,
        })
        .then(async () => {
          setHasCaptionBeenCopiedBefore(true);
          setIsCaptionCopied(true);

          setIsSharingToApps(true);

          if (triviaWin) {
            // && (!triviaWinShares || triviaWinShares.length === 0)
            await logTriviaShareEvent(3);
          } else {
            if ((personalQuiz || quiz || triviaNight).status >= 5) {
              await logTriviaShareEvent(2);
            } else if ((personalQuiz || quiz || triviaNight).status === 0) {
              await logTriviaShareEvent(0);
            } else {
              await logTriviaShareEvent(1);
            }
          }
        })
        .catch((error) => {
          setIsSharingToApps(false);
          navigator.clipboard.writeText(textToShare);
          // navigator.clipboard.writeText(textToShare + " worldphraze.com");
          setIsCaptionCopied(true);
          setHasCaptionBeenCopiedBefore(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setHasCaptionBeenCopiedBefore(true);

      setIsCaptionCopied(true);
    }

    if (triviaWin) {
      // && (!triviaWinShares || triviaWinShares.length === 0)
      await logTriviaShareEvent(3);
    }
    // if (
    //   !triviaWinShares ||
    //   (triviaWinShares && triviaWinShares.length < 10)
    // )
    else {
      if (triviaNight.status >= 5) {
        await logTriviaShareEvent(2);
      } else if (triviaNight.status === 0) {
        await logTriviaShareEvent(0);
      } else {
        await logTriviaShareEvent(1);
      }
    }
  };

  useEffect(() => {
    if (
      triviaNight &&
      triviaNight.status !== 2 &&
      triviaNight.status !== 3 &&
      indexClicked !== null
    ) {
      // console.log("need to change index clicked");
      setIndexClicked(null);
    }
  }, [setValues, triviaNight, indexClicked]);

  const [isInviteCopied, setIsInviteCopied] = useState(null);
  const [isSharingInviteToApps, setIsSharingInviteToApps] = useState(false);

  useEffect(() => {
    let timeout;
    if (isInviteCopied && resetInterval) {
      timeout = setTimeout(() => setIsInviteCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isInviteCopied, resetInterval]);

  useEffect(() => {
    let timeout;
    if (
      !finishedShowingMysteryTopic &&
      triviaNight &&
      triviaNight.status === 1 &&
      triviaNight.lastCompletedQIndex === triviaNight.numOfQs - 2
    ) {
      timeout = setTimeout(() => setFinishedShowingMysteryTopic(true), 10000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [finishedShowingMysteryTopic, triviaNight]);

  const inviteFriends = async () => {
    var textToShare = "Play TKnight!\n\n";

    textToShare += "It's the most fun trivia game ever!!\n\n";
    if (targetCustomer) {
      textToShare += "Extra ❤️ Code: " + targetCustomer.username + "\n\n";
    }
    textToShare += "https://tknight.live";

    if (navigator.share) {
      navigator
        .share({
          title: "TKnight!",
          text: textToShare,
        })
        .then(() => {
          setIsInviteCopied(true);
          setHasCaptionBeenCopiedBefore(true);
          setIsSharingInviteToApps(true);
        })
        .catch((error) => {
          setIsSharingInviteToApps(false);
          navigator.clipboard.writeText(textToShare);
          setIsInviteCopied(true);
          setHasCaptionBeenCopiedBefore(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setIsInviteCopied(true);
      setHasCaptionBeenCopiedBefore(true);
    }
  };
  const [tnRef, isTNOpen] = useInView({
    threshold: shouldOpenQuiz ? 1 : 0,
  });
  console.log(isInviteCopied);

  useEffect(() => {
    const commonVariables = {
      triviaNightId: triviaNight?.id || null,
      quizId: quiz?.id || null,
    };

    if ((personalQuiz || quiz || triviaNight)?.status === 3 && curQuestion) {
      if (!isFirstCloseComplete) {
        setIsFirstCloseComplete(true);
      }
      setLoadingSubmitAnswer(false);
      refetchIsOutOfLives(commonVariables);
      refetchCurSelectedAnswer(commonVariables);
      refetchNumOfExtraLivesNeeded(commonVariables);
      refetchLivesCount(commonVariables);
      refetchSelectedAnswerStats({ questionId: curQuestion.id });
      refetchImpressiveRanking(commonVariables);
      refetchAllPlayerBattleStatsByBattle(commonVariables);
      setTargetMediaUrl(null);
    } else if ([1, 2].includes((personalQuiz || quiz || triviaNight)?.status)) {
      if (!isFirstCloseComplete) {
        setIsFirstCloseComplete(true);
      }
      // refetchProductAds();

      setLoadingSubmitAnswer(false);
      if ((personalQuiz || quiz || triviaNight)?.status === 1) {
        refetchImpressiveRanking(commonVariables);
        refetchNumOfExtraLivesNeeded(commonVariables);
        refetchLivesCount(commonVariables);
        refetchIsOutOfLives(commonVariables);
        refetchAllPlayerBattleStatsByBattle(commonVariables);

        refetchCurQ(commonVariables);

        setIndexClicked(null);
      } else if (curQuestion) {
        refetchSelectedAnswerStats({ questionId: curQuestion.id });
        refetchAnsOptions();
      }

      if (
        triviaNight &&
        triviaNight.lastCompletedQIndex === triviaNight.numOfQs - 2 &&
        moment(triviaNight.nextQStartsAt).subtract(5, "seconds").isBefore()
      ) {
        setFinishedShowingMysteryTopic(true);
      }
    } else if ((personalQuiz || quiz || triviaNight)?.status === 5) {
      if (!isFirstCloseComplete) {
        setIsFirstCloseComplete(true);
      }
      setLoadingSubmitAnswer(false);
      refetchTriviaWin(commonVariables);
      refetchTNResultsFormatted(commonVariables);
      refetchPlayerBattleTopicStatsByBattleAndTopic({
        topicId: triviaNight ? triviaNight.topicIds[triviaNight.curQIndex] : "",
        ...commonVariables,
      });
      refetchImpressiveRanking(commonVariables);
      refetchAllPlayerBattleStatsByBattle(commonVariables);
    } else if ((personalQuiz || quiz || triviaNight).status === 0) {
      if (!isFirstCloseComplete) {
        setIsFirstCloseComplete(true);
      }
      setTargetMediaUrl(null);
    }
  }, [
    triviaNight,
    refetchLivesCount,
    refetchNumOfExtraLivesNeeded,
    refetchImpressiveRanking,
    personalQuiz,
    quiz,
    refetchIsOutOfLives,
    refetchProductAds,
    curQuestion,
    refetchCustomer,
    refetchSelectedAnswerStats,
    refetchCurSelectedAnswer,
    refetchTriviaWin,
    refetchTNResultsFormatted,
    refetchAnsOptions,
    refetchPlayerBattleTopicStatsByBattleAndTopic,
    isFirstCloseComplete,
    refetchAllPlayerBattleStatsByBattle,
    refetchCurQ,
  ]);

  useEffect(() => {
    //passing getData method to the lifecycle method
    if (
      (personalQuiz || quiz || triviaNight) &&
      !indexClicked &&
      !selectedAnswer &&
      (personalQuiz || quiz || triviaNight).status === 2 &&
      !shouldOpenQuiz
    ) {
      setIsFirstCloseComplete(true);

      toggleQuizForm();
    }
  }, [
    shouldOpenQuiz,
    selectedAnswer,
    indexClicked,
    personalQuiz,
    quiz,
    triviaNight,
    toggleQuizForm,
  ]);

  const size = useWindowSize();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (
        document.hidden &&
        (personalQuiz || quiz || triviaNight).status === 2 &&
        !loadingSendTriviaCheat &&
        curQuestion &&
        moment().isBefore((personalQuiz || quiz || triviaNight).curQEndsAt)
      ) {
        sendTriviaCheat({
          variables: {
            questionId: curQuestion?.id || null,
            triviaNightId: triviaNight?.id || null,
            quizId: quiz?.id || null,
          },
        });
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [
    personalQuiz,
    quiz,
    curQuestion,
    triviaNight,
    sendTriviaCheat,
    loadingSendTriviaCheat,
  ]);

  // const [timeZone, setTimeZone] = useState("");

  // // Get the user's time zone on component mount
  // useEffect(() => {
  //   const date = new Date();
  //   let timezone = date.toString().match(/([A-Z]+[+-][0-9]+.*)/)[1];
  //   timezone = timezone.split(" (")[1].split(")")[0];
  //   setTimeZone(timezone);
  // }, []);

  const [answerErr, setAnswerErr] = useState(null);

  const [isErrMsgShown, setIsErrMsgShown] = useState(false);

  const shareBattleInvite = async (
    targetTopic,
    targetCustomer,
    targetBattle
  ) => {
    if (!targetTopic) {
      return;
    }
    const textToShare = `TKnight ⚔️ ${targetTopic.name}\nhttps://tknight.live/battle/${targetBattle.id}`;

    if (navigator.share) {
      navigator
        .share({
          title: targetTopic.name + " battle on TKnight!",
          text: textToShare,
        })
        .then(() => {
          setHasCaptionBeenCopiedBefore(true);
          setIsCaptionCopied(true);

          setIsSharingToApps(true);
        })
        .catch((error) => {
          setIsSharingToApps(false);
          navigator.clipboard.writeText(textToShare);

          setIsCaptionCopied(true);
          setHasCaptionBeenCopiedBefore(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setHasCaptionBeenCopiedBefore(true);

      setIsCaptionCopied(true);
    }
  };
  useEffect(() => {
    let timeout;
    if (isErrMsgShown && answerErr) {
      timeout = setTimeout(() => setIsErrMsgShown(false), 4000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isErrMsgShown, answerErr]);

  const [shuffledIndices, setShuffledIndices] = useState(null);

  const [powerupBoughtMessage, setPowerupBoughtMessage] = useState(null);
  const [isPowerupBoughtMessageShown, setIsPowerupBoughtMessageShown] =
    useState(false);

  useEffect(() => {
    let timeout;
    if (isPowerupBoughtMessageShown && powerupBoughtMessage) {
      timeout = setTimeout(() => setIsPowerupBoughtMessageShown(false), 4000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isPowerupBoughtMessageShown, powerupBoughtMessage]);

  useEffect(() => {
    function shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }

    if (!shuffledIndices) {
      setShuffledIndices(shuffleArray([0, 1, 2, 3]));
    }
  }, [shuffledIndices]);
  const topicLevelCircleHeight = 18;

  let numOfPlayers = triviaNight
    ? triviaNight.prizeCoins - 1000
    : quiz
    ? quiz.prizeCoins / quiz.betAmountPerPlayer
    : 0;

  return (
    <div
      className={`absolute lg:static pb-[130px] short:pt-12 pt-[60px] lg:pt-0 lg:pb-0 top-0 w-full h-full rounded-t-2xl flex flex-col items-center justify-start overflow-y-auto`}
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setErrors({});

          toggleQuizForm();
        }}
        className={`${
          triviaNight && (!targetCustomer || !targetCustomer.isPaidSubscriber)
            ? "hidden"
            : "flex"
        } ${
          isTNOpen
            ? "motion-safe:animate-fadeInFast pointer-events-auto lg:motion-safe:animate-none lg:hidden"
            : "motion-safe:animate-fadeOutFast pointer-events-none lg:hidden"
        } hide-button-flash z-40 hide-button-flash flex items-center justify-center inset-0 bottom-72 absolute `}
      />
      <div
        id="gameDiv"
        ref={tnRef}
        className={`${
          !quiz && (!targetCustomer || !targetCustomer.isPaidSubscriber)
            ? " xshort:rounded-b-none"
            : shouldOpenQuiz
            ? isTNOpen &&
              ((personalQuiz || quiz || triviaNight).status === 0 ||
                (personalQuiz || quiz || triviaNight).status === 5)
              ? "motion-safe:animate-moveDownFast lg:motion-safe:animate-none"
              : isTNOpen
              ? "motion-safe:animate-none"
              : "motion-safe:animate-moveDownFast lg:motion-safe:animate-none"
            : isTNOpen
            ? "motion-safe:animate-moveOutUpFast lg:motion-safe:animate-none"
            : "motion-safe:animate-none hidden lg:block"
        } ${
          (quiz || (targetCustomer && targetCustomer.isPaidSubscriber)) &&
          ((personalQuiz || quiz || triviaNight).status === 0 ||
            (personalQuiz || quiz || triviaNight).status === 1 ||
            (personalQuiz || quiz || triviaNight).status === 3 ||
            (personalQuiz || quiz || triviaNight).status === 5)
            ? "h-full lg:h-full"
            : "h-full"
        } ${
          quiz || (targetCustomer && targetCustomer.isPaidSubscriber)
            ? (personalQuiz || quiz || triviaNight).status === 0
              ? "px-2 sm:px-4 pt-3 sm:pt-7"
              : "short:px-4 px-6 sm:px-8 pt-5 sm:pt-7"
            : "short:px-2 px-2 sm:px-4 pt-3 sm:pt-4"
        } ${
          (quiz || (targetCustomer && targetCustomer.isPaidSubscriber)) &&
          ((personalQuiz || quiz || triviaNight).status === 2 ||
            (personalQuiz || quiz || triviaNight).status === 3)
            ? "pb-6 sm:pb-8 short:pb-4"
            : "pb-3 short:pb-2 sm:pb-2"
        } relative transition-all pointer-events-auto overflow-x-hidden max-h-full flex-shrink lg:m-auto w-full overflow-y-auto flex flex-col items-center justify-start z-40 rounded-xl short:pt-3 bg-darker-gray shadow-xl lg:shadow-none lg:rounded-3xl `}
      >
        {answerErr && (
          <div
            className={`${
              isErrMsgShown
                ? "motion-safe:animate-fadeDownFast"
                : "motion-safe:animate-fadeOutUpFast"
            } truncate sm:max-w-xl z-50 font-semibold py-2 text-lg short:text-sm px-4 absolute text-center bg-red-600 text-white rounded-lg inset-x-4 short:inset-x-2 sm:inset-x-auto top-4 sm:top-5 short:top-2 lg:w-auto`}
          >
            {answerErr}
          </div>
        )}

        {powerupBoughtMessage && (
          <div
            className={`${
              isPowerupBoughtMessageShown
                ? "motion-safe:animate-fadeDownFast"
                : "motion-safe:animate-fadeOutUpFast"
            } truncate sm:max-w-xl z-50 font-semibold py-2 text-lg short:text-sm px-4 absolute text-center bg-purple-600 text-white rounded-lg inset-x-4 short:inset-x-2 sm:inset-x-auto top-4 sm:top-5 short:top-2 lg:w-auto`}
          >
            {powerupBoughtMessage}
          </div>
        )}

        <div
          className={`${
            triviaNight && (!targetCustomer || !targetCustomer.isPaidSubscriber)
              ? "px-4"
              : (personalQuiz || quiz || triviaNight).status === 0
              ? "px-2 sm:px-4"
              : ""
          } w-full flex z-40 space-x-4 sm:px-0 items-center justify-center`}
        >
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              toggleLeaderboardPopup();
            }}
            className="w-1/5 flex items-center justify-start space-x-2"
          >
            <div
              className={`${
                targetMediaUrl
                  ? "text-gray-200 backdrop-blur-sm rounded-lg drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.9)]"
                  : "text-gray-200 "
              } flex items-center justify-start space-x-2 short:space-x-1.5 sm:space-x-3`}
            >
              <svg
                className="h-4 fill-current 2xl:h-6 short:h-3"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.5 4.5C4.5 2.01375 6.51375 0 9 0C11.4862 0 13.5 2.01375 13.5 4.5C13.5 6.98625 11.4862 9 9 9C6.51375 9 4.5 6.98625 4.5 4.5ZM0 14.625C0 11.6325 5.99625 10.125 9 10.125C12.0037 10.125 18 11.6325 18 14.625V18H0V14.625Z"
                />
              </svg>
              <p className="text-sm short:text-xs 2xl:text-xl font-semibold">
                {formatViewCount(numOfPlayers)}
              </p>
            </div>
          </button>
          <div
            className={`${
              targetMediaUrl
                ? "text-blue-200 group-hover:text-blue-500 bg-black/60 rounded-lg drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.9)]"
                : "text-blue-300 group-hover:text-blue-400"
            } font-semibold w-full truncate transition-all uppercase focus:outline-none tracking-wide whitespace-nowrap text-center sm:text-lg 2xl:text-2xl`}
          >
            <SecondsCountdown
              personalQuiz={personalQuiz}
              quiz={quiz}
              triviaNight={triviaNight}
              targetCustomer={targetPlayer}
              triviaWin={triviaWin}
              curQuestion={curQuestion}
              selectedAnswer={selectedAnswer}
              isPaused={isPaused}
            />
          </div>

          {quiz && (!custTriviaView || custTriviaView.status !== 1) ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleBuySubscriptionForm(2);
              }}
              className="w-1/5 flex items-center justify-end"
            >
              <div
                className={`flex items-center justify-end space-x-2.5 short:space-x-1.5 text-gray-200`}
              >
                <svg
                  className={`h-5 2xl:h-7 short:h-4 `}
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.65257 13C10.043 13 12.7915 10.2515 12.7915 6.86106C12.7915 3.47064 10.043 0.722168 6.65257 0.722168C3.26215 0.722168 0.513672 3.47064 0.513672 6.86106C0.513672 10.2515 3.26215 13 6.65257 13Z"
                    fill="#F4900C"
                  />
                  <path
                    d="M6.65257 12.2778C10.043 12.2778 12.7915 9.52931 12.7915 6.1389C12.7915 2.74848 10.043 0 6.65257 0C3.26215 0 0.513672 2.74848 0.513672 6.1389C0.513672 9.52931 3.26215 12.2778 6.65257 12.2778Z"
                    fill="#FFCC4D"
                  />
                  <path
                    d="M6.65224 11.5555C9.44435 11.5555 11.7078 9.29201 11.7078 6.4999C11.7078 3.70779 9.44435 1.44434 6.65224 1.44434C3.86013 1.44434 1.59668 3.70779 1.59668 6.4999C1.59668 9.29201 3.86013 11.5555 6.65224 11.5555Z"
                    fill="#FFE8B6"
                  />
                  <path
                    d="M6.65224 11.1946C9.44435 11.1946 11.7078 8.93117 11.7078 6.13906C11.7078 3.34695 9.44435 1.0835 6.65224 1.0835C3.86013 1.0835 1.59668 3.34695 1.59668 6.13906C1.59668 8.93117 3.86013 11.1946 6.65224 11.1946Z"
                    fill="#FFAC33"
                  />
                  <path
                    d="M6.63662 2.40527C5.64537 2.40527 4.69642 2.79904 3.99849 3.49996C3.30057 4.20088 2.91084 5.15152 2.91507 6.14277C2.9193 7.134 3.31714 8.08468 4.021 8.78559C4.7249 9.4865 5.67725 9.88028 6.66846 9.88028C6.21152 9.88028 5.76422 9.74476 5.38318 9.49089C5.00218 9.23703 4.70449 8.87616 4.52784 8.45399C4.35114 8.03182 4.30342 7.56724 4.39066 7.11906C4.4779 6.67087 4.6962 6.2592 5.01793 5.93608C5.33969 5.61296 5.75044 5.39291 6.19822 5.30376C6.64604 5.21461 7.11079 5.26036 7.53371 5.43523C7.95662 5.61011 8.3187 5.90624 8.57421 6.2862C8.82972 6.66613 8.96712 7.11286 8.96904 7.56984C8.97165 8.18259 8.73075 8.77026 8.29928 9.20354C7.86786 9.63683 7.28125 9.88028 6.66846 9.88028C7.65972 9.88028 8.60867 9.4865 9.30659 8.78559C10.0045 8.08468 10.3942 7.134 10.39 6.14277C10.3858 5.15152 9.98794 4.20088 9.28409 3.49996C8.58018 2.79904 7.62788 2.40527 6.63662 2.40527Z"
                    fill="#FFD983"
                  />
                </svg>
                <p className="text-sm 2xl:text-lg short:text-xs font-semibold">
                  {quiz.betAmountPerPlayer}
                </p>
              </div>
            </button>
          ) : (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                togglePurchasePowerUpForm(1);
              }}
              className="w-1/5 flex items-center justify-end space-x-2"
            >
              <div
                className={`flex items-center justify-end space-x-1.5 sm:space-x-2.5 short:space-x-1.5 text-gray-200`}
              >
                <svg
                  className={`fill-current text-red-600 h-5 2xl:h-7 short:h-4 `}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z" />
                </svg>

                <p className="text-sm 2xl:text-lg short:text-xs font-semibold">
                  {isOutOfLives ? livesCount || 0 : (livesCount || 0) + 1}
                </p>
              </div>
            </button>
          )}
        </div>

        {triviaNight && triviaNight.status === 0 ? (
          <p
            className={`text-center short:text-sm px-4 pb-1 sm:px-0 text-gray-400 leading-tight`}
          >
            Starts in{" "}
            <CountdownTimer
              endDate={triviaNight.releaseDate}
              hideSpace={true}
            />
          </p>
        ) : (personalQuiz || quiz) &&
          ((personalQuiz || quiz).status === 0 ||
            (personalQuiz || quiz).status === 5) ? (
          <div className="flex short:hidden items-center justify-center space-x-2 mt-2">
            <TopicIcon
              navigate={navigate}
              topicId={quiz.topicIds[0]}
              height="h-8"
            />
          </div>
        ) : (
          <></>
        )}

        {quiz ||
        (targetCustomer && targetCustomer.isPaidSubscriber && triviaNight) ? (
          <>
            {(personalQuiz || quiz || triviaNight).status === 0 && (
              <div className="w-full px-4 z-40 flex flex-col items-center justify-center flex-1">
                <p className="text-center leading-tight text-lg 2xl:text-2xl text-gray-400">
                  {triviaNight ? "Tonight's " : "Total "}Prize
                </p>
                <p className="text-8xl 2xl:text-9xl leading-none text-white font-black mt-2 mb-3.5">
                  {(quiz || triviaNight).prizeCoins
                    ? (quiz || triviaNight).prizeCoins
                    : ""}
                </p>

                <div className="flex items-center justify-center space-x-3 short:space-x-2">
                  {[0, 1, 2].map((val) => (
                    <svg
                      className="h-8 fill-current 2xl:h-12 short:h-6"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      key={val}
                    >
                      <path
                        d="M6.65257 13C10.043 13 12.7915 10.2515 12.7915 6.86106C12.7915 3.47064 10.043 0.722168 6.65257 0.722168C3.26215 0.722168 0.513672 3.47064 0.513672 6.86106C0.513672 10.2515 3.26215 13 6.65257 13Z"
                        fill="#F4900C"
                      />
                      <path
                        d="M6.65257 12.2778C10.043 12.2778 12.7915 9.52931 12.7915 6.1389C12.7915 2.74848 10.043 0 6.65257 0C3.26215 0 0.513672 2.74848 0.513672 6.1389C0.513672 9.52931 3.26215 12.2778 6.65257 12.2778Z"
                        fill="#FFCC4D"
                      />
                      <path
                        d="M6.65224 11.5555C9.44435 11.5555 11.7078 9.29201 11.7078 6.4999C11.7078 3.70779 9.44435 1.44434 6.65224 1.44434C3.86013 1.44434 1.59668 3.70779 1.59668 6.4999C1.59668 9.29201 3.86013 11.5555 6.65224 11.5555Z"
                        fill="#FFE8B6"
                      />
                      <path
                        d="M6.65224 11.1946C9.44435 11.1946 11.7078 8.93117 11.7078 6.13906C11.7078 3.34695 9.44435 1.0835 6.65224 1.0835C3.86013 1.0835 1.59668 3.34695 1.59668 6.13906C1.59668 8.93117 3.86013 11.1946 6.65224 11.1946Z"
                        fill="#FFAC33"
                      />
                      <path
                        d="M6.63662 2.40527C5.64537 2.40527 4.69642 2.79904 3.99849 3.49996C3.30057 4.20088 2.91084 5.15152 2.91507 6.14277C2.9193 7.134 3.31714 8.08468 4.021 8.78559C4.7249 9.4865 5.67725 9.88028 6.66846 9.88028C6.21152 9.88028 5.76422 9.74476 5.38318 9.49089C5.00218 9.23703 4.70449 8.87616 4.52784 8.45399C4.35114 8.03182 4.30342 7.56724 4.39066 7.11906C4.4779 6.67087 4.6962 6.2592 5.01793 5.93608C5.33969 5.61296 5.75044 5.39291 6.19822 5.30376C6.64604 5.21461 7.11079 5.26036 7.53371 5.43523C7.95662 5.61011 8.3187 5.90624 8.57421 6.2862C8.82972 6.66613 8.96712 7.11286 8.96904 7.56984C8.97165 8.18259 8.73075 8.77026 8.29928 9.20354C7.86786 9.63683 7.28125 9.88028 6.66846 9.88028C7.65972 9.88028 8.60867 9.4865 9.30659 8.78559C10.0045 8.08468 10.3942 7.134 10.39 6.14277C10.3858 5.15152 9.98794 4.20088 9.28409 3.49996C8.58018 2.79904 7.62788 2.40527 6.63662 2.40527Z"
                        fill="#FFD983"
                      />
                    </svg>
                  ))}
                </div>
              </div>
            )}

            {(personalQuiz || quiz || triviaNight).status === 1 && (
              <div className="w-full z-40 flex flex-col items-center justify-center space-y-4 2xl:space-y-6 flex-1">
                {(personalQuiz || quiz || triviaNight).lastCompletedQIndex ===
                (personalQuiz || quiz || triviaNight).numOfQs - 2 ? (
                  <p
                    className={`text-center leading-none short:leading-none xshort:leading-none text-lg short:text-base 2xl:text-2xl text-gray-400`}
                  >
                    Last Question
                  </p>
                ) : (
                  <p
                    className={`text-center truncate leading-none short:leading-none xshort:leading-none text-lg short:text-base ${
                      targetTopic ? "" : "invisible"
                    } 2xl:text-2xl text-gray-400`}
                  >
                    {targetTopic ? targetTopic.name : "Thank you"}
                  </p>
                )}

                <div
                  className={`bg-darker-gray/40 backdrop-blur flex items-center justify-center rounded-full ${
                    targetMediaUrl ? "p-1 " : "p-2 short:p-1"
                  }`}
                >
                  <PieTimer
                    loadingPause={
                      loadingTogglePausePersonalQuiz || loadingTogglePauseQuiz
                    }
                    isLastQ={
                      quiz
                        ? false
                        : triviaNight.lastCompletedQIndex ===
                          triviaNight.numOfQs - 2
                    }
                    endTime={
                      (personalQuiz || quiz || triviaNight).nextQStartsAt
                    }
                    imgUrl={
                      quiz
                        ? targetTopic?.logoUrl || defaultPicUrl
                        : triviaNight.hostPFPUrl || defaultPicUrl
                    }
                    quiz={personalQuiz || quiz || triviaNight}
                    alt={quiz?.hostName || triviaNight?.hostName || "Host"}
                    width={
                      size.height < 500 ? 130 : size.width > 1536 ? 250 : 200
                    }
                    lastXSeconds={
                      (personalQuiz || quiz || triviaNight).nextQLastsXSeconds *
                      1000
                    }
                    isPaused={isPaused}
                    tempElapsedTime={tempElapsedTime}
                    randomTopicLogoUrls={
                      quiz ? null : triviaNight.randomTopicLogoUrls
                    }
                    mysteryTopicLogoUrl={
                      quiz ? null : triviaNight.nextQTopicLogoUrl
                    }
                    togglePauseQuizCallback={() => {
                      if (quiz && (quiz.gameTimingType === 0 || personalQuiz)) {
                      } else {
                        return;
                      }

                      var curTime = new Date();
                      var secondsLeftAtPause = 0;
                      var timeLeftInSec = 0;
                      var timeLeftInMilli = 0;
                      if (!(personalQuiz || quiz).isPaused) {
                        if ((personalQuiz || quiz).status === 0) {
                          const releaseDate = new Date(
                            (personalQuiz || quiz).releaseDate
                          );
                          timeLeftInMilli =
                            releaseDate.getTime() - curTime.getTime();
                          timeLeftInSec = Math.floor(timeLeftInMilli / 1000);

                          if (timeLeftInSec <= 0) {
                          } else {
                            secondsLeftAtPause =
                              timeLeftInSec > 0 ? timeLeftInSec : 20;
                          }
                        } else if ((personalQuiz || quiz).status === 1) {
                          const nextQStartsAt = new Date(
                            (personalQuiz || quiz).nextQStartsAt
                          );
                          timeLeftInMilli = nextQStartsAt - curTime.getTime();
                          timeLeftInSec = Math.floor(timeLeftInMilli / 1000);

                          if (timeLeftInSec <= 0) {
                          } else {
                            secondsLeftAtPause =
                              timeLeftInSec > 0 ? timeLeftInSec : 20;
                          }
                        } else if ((personalQuiz || quiz).status === 3) {
                          const curAnsEndsAt = new Date(
                            (personalQuiz || quiz).curAnsEndsAt
                          );
                          timeLeftInMilli =
                            curAnsEndsAt.getTime() - curTime.getTime();
                          timeLeftInSec = Math.floor(timeLeftInMilli / 1000);

                          if (timeLeftInSec <= 0) {
                          } else {
                            secondsLeftAtPause =
                              timeLeftInSec > 0 ? timeLeftInSec : 20;
                          }
                        }
                        setTempElapsedTime(secondsLeftAtPause);
                      }
                      if (personalQuiz) {
                        togglePausePersonalQuiz({
                          variables: {
                            personalQuizId: personalQuiz ? personalQuiz.id : "",
                            secondsLeftAtPause,
                          },
                        });
                      } else {
                        togglePauseQuiz({
                          variables: {
                            quizId: quiz ? quiz.id : null,
                            secondsLeftAtPause,
                          },
                        });
                      }
                    }}
                  />
                </div>

                {numOfExtraLivesNeeded &&
                curQuestion &&
                curQuestion.id !== usedExtraLifeOnQuestionID ? (
                  <button
                    onClick={rejoinGameWithExtraLivesCallback}
                    className={`${
                      loadingRejoinGameWithExtraLives
                        ? "motion-safe:animate-pulse bg-red-800 hover:bg-red-800"
                        : ""
                    } ${
                      answerErr && isErrMsgShown
                        ? "motion-safe:animate-shake"
                        : ""
                    } font-semibold hover:bg-red-700 transition-all group overflow-hidden relative text-lg short:text-base 2xl:text-xl group text-white bg-red-600 hover:shadow-lg disabled:hover:shadow-none px-4 pr-2 py-2 rounded-full shadow`}
                    disabled={
                      loadingIsOutOfLives ||
                      loadingNumOfExtraLivesNeeded ||
                      loadingRejoinGameWithExtraLives
                    }
                  >
                    {loadingRejoinGameWithExtraLives && (
                      <div className="absolute motion-safe:animate-fadeDown bg-white inset-0 z-40 w-full flex items-center justify-start">
                        <svg
                          className={`h-5 px-20 py-0.5 text-gray-400 stroke-current m-auto motion-safe:animate-spin`}
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.636 3.636A9 9 0 0119 10"
                            strokeWidth={2}
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                    )}
                    <div className="flex space-x-2 text-lg short:text-base xshort:text-sm 2xl:text-2xl items-center justify-center">
                      <p>Join back for </p>

                      <div className="flex pl-3 pr-3 xshort:py-0.5 items-center justify-center space-x-1 bg-white rounded-full">
                        <svg
                          className="h-5 fill-current 2xl:h-7 text-red-600"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z" />
                        </svg>
                        <p className=" text-black">{numOfExtraLivesNeeded}</p>
                      </div>
                    </div>
                  </button>
                ) : shouldOpenAdPopup ? (
                  <></>
                ) : (
                  <div className="flex pb-3 space-x-2 items-center justify-center mt-2">
                    {quiz && targetTopic ? (
                      <TopicLevelCircle
                        topic={targetTopic}
                        targetCustomer={targetCustomer}
                        shouldShowCaption={true}
                        radius={topicLevelCircleHeight}
                      />
                    ) : triviaNight && triviaNight.topicIds?.length > 0 ? (
                      triviaNight.topicIds.map((topicId, index) => (
                        <TopicIcon
                          navigate={navigate}
                          topicId={topicId}
                          key={index}
                          height={"h-10"}
                          isCurTopic={targetTopic?.id === topicId}
                          disabled={true}
                        />
                      ))
                    ) : (
                      <></>
                    )}

                    {triviaNight &&
                    triviaNight.topicIds &&
                    triviaNight.topicIds.length !== 7 ? (
                      <TopicIcon
                        height={"h-10"}
                        navigate={navigate}
                        isMystery={true}
                        disabled={true}
                        hideSeek={false}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
            )}

            {((personalQuiz || quiz || triviaNight).status === 2 ||
              (personalQuiz || quiz || triviaNight).status === 3) &&
              curQuestion && (
                <div
                  className={`${
                    targetMediaUrl
                      ? "sm:justify-end sm:items-start justify-end"
                      : "justify-center"
                  } transition-all w-full z-40 flex relative flex-col items-center space-y-1 flex-1 py-4`}
                >
                  <AnswerBubbleCanvas
                    quizId={quiz?.id}
                    lastQStartedAt={
                      personalQuiz || quiz || triviaNight
                        ? (personalQuiz || quiz || triviaNight).lastQStartedAt
                        : null
                    }
                    curAnsEndsAt={
                      personalQuiz || quiz || triviaNight
                        ? (personalQuiz || quiz || triviaNight).curAnsEndsAt
                        : null
                    }
                    questionId={curQuestion?.id}
                    indexClicked={indexClicked}
                    deltaXP={
                      selectedAnswer &&
                      selectedAnswer.questionId === curQuestion?.id
                        ? selectedAnswer.deltaXP
                        : null
                    }
                    question={curQuestion}
                    personalQuiz={personalQuiz}
                    quiz={quiz}
                    triviaNight={triviaNight}
                    triviaNightId={triviaNight?.id}
                    targetCustomer={targetCustomer}
                    targetGuestCustomer={targetGuestCustomer}
                    targetMediaUrl={targetMediaUrl}
                  />

                  <div
                    className={`bg-darker-gray/40 backdrop-blur  flex items-center justify-center rounded-full ${
                      targetMediaUrl ? "p-1 " : "p-2 short:p-1"
                    }`}
                  >
                    {(personalQuiz || quiz || triviaNight).status === 3 ? (
                      <PieTimer
                        loadingPause={
                          loadingTogglePausePersonalQuiz ||
                          loadingTogglePauseQuiz
                        }
                        endTime={
                          (personalQuiz || quiz || triviaNight).curAnsEndsAt
                        }
                        imgUrl={
                          quiz
                            ? targetTopic?.logoUrl || defaultPicUrl
                            : triviaNight.hostPFPUrl || defaultPicUrl
                        }
                        targetMediaUrl={targetMediaUrl}
                        alt={(quiz || triviaNight).hostName}
                        width={
                          size.height < 672
                            ? 50
                            : size.width > 1536
                            ? targetMediaUrl
                              ? 60
                              : 90
                            : targetMediaUrl
                            ? 60
                            : 70
                        }
                        lastXSeconds={
                          (personalQuiz || quiz || triviaNight)
                            .nextQLastsXSeconds * 1000
                        }
                        quiz={personalQuiz || quiz || triviaNight}
                        togglePauseQuizCallback={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          if (
                            quiz &&
                            (quiz.gameTimingType === 0 || personalQuiz)
                          ) {
                          } else {
                            return;
                          }

                          var curTime = new Date();
                          var secondsLeftAtPause = 0;
                          var timeLeftInSec = 0;
                          var timeLeftInMilli = 0;
                          if (!(personalQuiz || quiz).isPaused) {
                            if ((personalQuiz || quiz).status === 0) {
                              const releaseDate = new Date(
                                (personalQuiz || quiz).releaseDate
                              );
                              timeLeftInMilli =
                                releaseDate.getTime() - curTime.getTime();
                              timeLeftInSec = Math.floor(
                                timeLeftInMilli / 1000
                              );

                              if (timeLeftInSec <= 0) {
                              } else {
                                secondsLeftAtPause =
                                  timeLeftInSec > 0 ? timeLeftInSec : 20;
                              }
                            } else if ((personalQuiz || quiz).status === 1) {
                              const nextQStartsAt = new Date(
                                (personalQuiz || quiz).nextQStartsAt
                              );
                              timeLeftInMilli =
                                nextQStartsAt - curTime.getTime();
                              timeLeftInSec = Math.floor(
                                timeLeftInMilli / 1000
                              );

                              if (timeLeftInSec <= 0) {
                              } else {
                                secondsLeftAtPause =
                                  timeLeftInSec > 0 ? timeLeftInSec : 20;
                              }
                            } else if ((personalQuiz || quiz).status === 3) {
                              const curAnsEndsAt = new Date(
                                (personalQuiz || quiz).curAnsEndsAt
                              );
                              timeLeftInMilli =
                                curAnsEndsAt.getTime() - curTime.getTime();
                              timeLeftInSec = Math.floor(
                                timeLeftInMilli / 1000
                              );

                              if (timeLeftInSec <= 0) {
                              } else {
                                secondsLeftAtPause =
                                  timeLeftInSec > 0 ? timeLeftInSec : 20;
                              }
                            }
                            setTempElapsedTime(secondsLeftAtPause);
                          }
                          if (personalQuiz) {
                            togglePausePersonalQuiz({
                              variables: {
                                personalQuizId: personalQuiz
                                  ? personalQuiz.id
                                  : "",
                                secondsLeftAtPause,
                              },
                            });
                          } else {
                            togglePauseQuiz({
                              variables: {
                                quizId: quiz ? quiz.id : null,
                                secondsLeftAtPause,
                              },
                            });
                          }
                        }}
                        loading={
                          loadingTogglePauseQuiz ||
                          loadingTogglePausePersonalQuiz
                        }
                        isPaused={isPaused}
                        tempElapsedTime={tempElapsedTime}
                        pauseErr={errors && errors.pause}
                      />
                    ) : (
                      <PieTimer
                        loadingPause={
                          loadingTogglePausePersonalQuiz ||
                          loadingTogglePauseQuiz
                        }
                        endTime={
                          (personalQuiz || quiz || triviaNight).curQEndsAt
                        }
                        imgUrl={
                          quiz
                            ? targetTopic?.logoUrl || defaultPicUrl
                            : triviaNight.hostPFPUrl || defaultPicUrl
                        }
                        alt={(quiz || triviaNight).hostName}
                        width={
                          size.height < 672
                            ? 50
                            : size.width > 1536
                            ? targetMediaUrl
                              ? 60
                              : 90
                            : targetMediaUrl
                            ? 60
                            : 70
                        }
                        quiz={personalQuiz || quiz || triviaNight}
                        lastXSeconds={
                          (personalQuiz || quiz || triviaNight)
                            .nextQLastsXSeconds * 1000
                        }
                        isPaused={isPaused}
                        tempElapsedTime={tempElapsedTime}
                        togglePauseQuizCallback={() => {
                          if (
                            quiz &&
                            (quiz.gameTimingType === 0 || personalQuiz)
                          ) {
                          } else {
                            return;
                          }

                          setIsPaused(!isPaused);

                          var curTime = new Date();
                          var secondsLeftAtPause = 0;
                          var timeLeftInSec = 0;
                          var timeLeftInMilli = 0;
                          if (!(personalQuiz || quiz).isPaused) {
                            if ((personalQuiz || quiz).status === 0) {
                              const releaseDate = new Date(
                                (personalQuiz || quiz).releaseDate
                              );
                              timeLeftInMilli =
                                releaseDate.getTime() - curTime.getTime();
                              timeLeftInSec = Math.floor(
                                timeLeftInMilli / 1000
                              );

                              if (timeLeftInSec <= 0) {
                              } else {
                                secondsLeftAtPause =
                                  timeLeftInSec > 0 ? timeLeftInSec : 20;
                              }
                            } else if ((personalQuiz || quiz).status === 1) {
                              const nextQStartsAt = new Date(
                                (personalQuiz || quiz).nextQStartsAt
                              );
                              timeLeftInMilli =
                                nextQStartsAt - curTime.getTime();
                              timeLeftInSec = Math.floor(
                                timeLeftInMilli / 1000
                              );

                              if (timeLeftInSec <= 0) {
                              } else {
                                secondsLeftAtPause =
                                  timeLeftInSec > 0 ? timeLeftInSec : 20;
                              }
                            } else if ((personalQuiz || quiz).status === 3) {
                              const curAnsEndsAt = new Date(
                                (personalQuiz || quiz).curAnsEndsAt
                              );
                              timeLeftInMilli =
                                curAnsEndsAt.getTime() - curTime.getTime();
                              timeLeftInSec = Math.floor(
                                timeLeftInMilli / 1000
                              );

                              if (timeLeftInSec <= 0) {
                              } else {
                                secondsLeftAtPause =
                                  timeLeftInSec > 0 ? timeLeftInSec : 20;
                              }
                            }
                            setTempElapsedTime(secondsLeftAtPause);
                          }
                          if (personalQuiz) {
                            togglePausePersonalQuiz({
                              variables: {
                                personalQuizId: personalQuiz
                                  ? personalQuiz.id
                                  : "",
                                secondsLeftAtPause,
                              },
                            });
                          } else {
                            togglePauseQuiz({
                              variables: {
                                quizId: quiz ? quiz.id : null,
                                secondsLeftAtPause,
                              },
                            });
                          }
                        }}
                        loading={
                          loadingTogglePauseQuiz ||
                          loadingTogglePausePersonalQuiz
                        }
                        pauseErr={errors && errors.pause}
                      />
                    )}
                  </div>

                  <p
                    className={`${
                      curQuestion
                        ? "motion-safe:animate-fadeInFast"
                        : "invisible"
                    } ${
                      targetMediaUrl
                        ? "text-gray-200 sm:text-left backdrop-blur-sm rounded-lg bg-white/80"
                        : "text-gray-200 "
                    } line-clamp-4 overflow-hidden transition-all leading-tight short:leading-tight max-h-24 sm:max-h-36 lg:max-h-28 select-none text-base sm:leading-tight sm:text-lg z-20 2xl:text-2xl font-semibold text-center max-w-xs px-2`}
                  >
                    {decryptQCipher(curQuestion.questionDesc)}
                  </p>
                </div>
              )}

            {(personalQuiz || quiz || triviaNight).status >= 5 ? (
              loadingTriviaWin ? (
                <div className="w-full z-40 flex flex-col items-center justify-center space-y-4 flex-1"></div>
              ) : (personalQuiz || quiz || triviaNight).status === 5 ? (
                <div className="w-full z-40 flex flex-col items-center justify-center space-y-4 flex-1 relative">
                  <BattleEndCanvas
                    endedAt={(personalQuiz || quiz || triviaNight).curAnsEndsAt}
                    deltaXP={
                      playerBattleTopicStatsByBattleAndTopic &&
                      playerBattleTopicStatsByBattleAndTopic.levelNumAfter !==
                        null &&
                      playerBattleTopicStatsByBattleAndTopic.levelNumBefore !==
                        null
                        ? playerBattleTopicStatsByBattleAndTopic.levelNumAfter -
                          playerBattleTopicStatsByBattleAndTopic.levelNumBefore
                        : null
                    }
                  />
                  <p className="text-center text-lg 2xl:text-2xl text-gray-400 short:text-base leading-none">
                    {(quiz || triviaNight).status === 5
                      ? (quiz || triviaNight).prizePerPlayer
                        ? "Prize per winner"
                        : "Nobody won :("
                      : triviaWin
                      ? "Prize releases in"
                      : "Results release in"}
                  </p>

                  {(personalQuiz || quiz || triviaNight).status === 5 ? (
                    <p
                      className={`${
                        (personalQuiz || quiz || triviaNight)?.status !== 5
                          ? "text-6xl"
                          : "text-7xl"
                      } xshort:text-4xl sm:text-7xl mb-3 2xl:text-8xl leading-none text-white font-black`}
                    >
                      {(quiz || triviaNight).status !== 5 ? (
                        <CountdownTimer
                          endDate={(quiz || triviaNight).endDate}
                          hideSpace={true}
                          isFloating={true}
                        />
                      ) : (quiz || triviaNight).prizePerPlayer ? (
                        (quiz || triviaNight).prizePerPlayer
                      ) : (
                        ""
                      )}
                      {(quiz || triviaNight).prizePerPlayer > 300 ? "!!" : ""}
                    </p>
                  ) : (
                    <></>
                  )}

                  <div className="flex items-center justify-center space-x-3 short:space-x-2">
                    {[0, 1, 2].map((val) => (
                      <svg
                        className="h-8 fill-current 2xl:h-12 short:h-6"
                        viewBox="0 0 13 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        key={val}
                      >
                        <path
                          d="M6.65257 13C10.043 13 12.7915 10.2515 12.7915 6.86106C12.7915 3.47064 10.043 0.722168 6.65257 0.722168C3.26215 0.722168 0.513672 3.47064 0.513672 6.86106C0.513672 10.2515 3.26215 13 6.65257 13Z"
                          fill="#F4900C"
                        />
                        <path
                          d="M6.65257 12.2778C10.043 12.2778 12.7915 9.52931 12.7915 6.1389C12.7915 2.74848 10.043 0 6.65257 0C3.26215 0 0.513672 2.74848 0.513672 6.1389C0.513672 9.52931 3.26215 12.2778 6.65257 12.2778Z"
                          fill="#FFCC4D"
                        />
                        <path
                          d="M6.65224 11.5555C9.44435 11.5555 11.7078 9.29201 11.7078 6.4999C11.7078 3.70779 9.44435 1.44434 6.65224 1.44434C3.86013 1.44434 1.59668 3.70779 1.59668 6.4999C1.59668 9.29201 3.86013 11.5555 6.65224 11.5555Z"
                          fill="#FFE8B6"
                        />
                        <path
                          d="M6.65224 11.1946C9.44435 11.1946 11.7078 8.93117 11.7078 6.13906C11.7078 3.34695 9.44435 1.0835 6.65224 1.0835C3.86013 1.0835 1.59668 3.34695 1.59668 6.13906C1.59668 8.93117 3.86013 11.1946 6.65224 11.1946Z"
                          fill="#FFAC33"
                        />
                        <path
                          d="M6.63662 2.40527C5.64537 2.40527 4.69642 2.79904 3.99849 3.49996C3.30057 4.20088 2.91084 5.15152 2.91507 6.14277C2.9193 7.134 3.31714 8.08468 4.021 8.78559C4.7249 9.4865 5.67725 9.88028 6.66846 9.88028C6.21152 9.88028 5.76422 9.74476 5.38318 9.49089C5.00218 9.23703 4.70449 8.87616 4.52784 8.45399C4.35114 8.03182 4.30342 7.56724 4.39066 7.11906C4.4779 6.67087 4.6962 6.2592 5.01793 5.93608C5.33969 5.61296 5.75044 5.39291 6.19822 5.30376C6.64604 5.21461 7.11079 5.26036 7.53371 5.43523C7.95662 5.61011 8.3187 5.90624 8.57421 6.2862C8.82972 6.66613 8.96712 7.11286 8.96904 7.56984C8.97165 8.18259 8.73075 8.77026 8.29928 9.20354C7.86786 9.63683 7.28125 9.88028 6.66846 9.88028C7.65972 9.88028 8.60867 9.4865 9.30659 8.78559C10.0045 8.08468 10.3942 7.134 10.39 6.14277C10.3858 5.15152 9.98794 4.20088 9.28409 3.49996C8.58018 2.79904 7.62788 2.40527 6.63662 2.40527Z"
                          fill="#FFD983"
                        />
                      </svg>
                    ))}
                  </div>
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        {(personalQuiz || quiz)?.status === 0 ||
        (personalQuiz || quiz || triviaNight)?.status >= 5 ? (
          <div className="h-10"></div>
        ) : (
          <></>
        )}

        {triviaNight?.status === 0 ? (
          <>
            <p
              className={`text-center text-sm lg:text-base uppercase py-2 text-gray-400 leading-tight`}
            >
              {triviaNight ? "Topics coming up next" : ""}
            </p>
            <div
              className={`grid grid-cols-7 w-full gap-2  pb-2 px-3 sm:px-4 `}
            >
              {triviaNight &&
                triviaNight.topicIds &&
                triviaNight.topicIds.length > 0 &&
                triviaNight.topicIds.map((topicId, index) => (
                  <TopicIcon
                    topicId={topicId}
                    width={"w-full"}
                    navigate={navigate}
                    key={index}
                  />
                ))}
              {triviaNight &&
              triviaNight.topicIds &&
              triviaNight.topicIds.length !== 7 ? (
                <TopicIcon
                  width={"w-full"}
                  navigate={navigate}
                  isMystery={true}

                  // disabled={}
                />
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}

        {(targetGuestCustomer ||
          (targetCustomer && (targetCustomer.isPaidSubscriber || quiz))) &&
        (personalQuiz || quiz || triviaNight) &&
        (personalQuiz || quiz || triviaNight).status > 0 ? (
          <>
            {(personalQuiz || quiz || triviaNight).status === 1 ? (
              <p
                className={`${
                  shouldOpenAdPopup ? "xshort:hidden" : ""
                } sm:text-base text-sm text-gray-400 leading-tight`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if ((personalQuiz || quiz || triviaNight)?.curQIndex > 0) {
                    toggleLeaderboardPopup();
                  }
                }}
              >
                {(personalQuiz || quiz || triviaNight)?.curQIndex > 0 &&
                impressiveRanking
                  ? impressiveRanking
                  : "Chat with everyone online"}
              </p>
            ) : (
              <></>
            )}

            {(personalQuiz || quiz || triviaNight).status >= 5 && (
              <div className="w-full z-40 flex-col flex items-center justify-center space-y-2 rounded-lg">
                <button
                  onClick={shareTNResult}
                  type="button"
                  className={`${
                    isCaptionCopied || isInviteCopied
                      ? " motion-safe:animate-popUp"
                      : " "
                  } relative text-xl sm:text-2xl sm:py-3 lg:py-1 2xl:py-4 lg:text-xl 2xl:text-2xl hover:bg-black overflow-hidden shadow-sm  group w-full font-bold uppercase flex transition-all items-center justify-center bg-blue-600 text-white rounded-full pl-4 pr-3 py-1`}
                >
                  <p
                    className={`${
                      isCaptionCopied || isInviteCopied
                        ? "motion-safe:animate-fadeDownFast"
                        : hasCaptionBeenCopiedBefore
                        ? "motion-safe:animate-fadeOutUpFast"
                        : "hidden"
                    } pb-2 short:pb-1 text-black bg-blue-300 inset-0 short:pt-2 pt-2.5 w-full absolute`}
                  >
                    {isSharingToApps || isSharingInviteToApps
                      ? "Shared!"
                      : isInviteCopied
                      ? "Copied link"
                      : isCaptionCopied
                      ? "Copied stats!"
                      : ""}
                  </p>

                  <p
                    className={`${
                      isCaptionCopied || isInviteCopied
                        ? "motion-safe:animate-fadeOutDownFast"
                        : hasCaptionBeenCopiedBefore
                        ? "motion-safe:animate-fadeUpFast"
                        : ""
                    } py-2 short:py-1`}
                  >
                    Share
                  </p>
                </button>

                {triviaNight && !triviaWin ? (
                  <p className="text-sm 2xl:text-lg sm:text-xl lg:text-sm max-w-xs text-center text-gray-400 leading-tight 2xl:leading-tight">
                    Next TKL starts in{" "}
                    <CountdownTimer
                      endDate={triviaNight.nextGameAt}
                      hideSpace={true}
                    />
                  </p>
                ) : triviaNight && triviaWin?.status >= 1 ? (
                  <p className="text-sm 2xl:text-lg 2xl:leading-tight sm:text-xl lg:text-sm text-center whitespace-nowrap text-gray-400 leading-tight">
                    Next game at{" "}
                    {moment(triviaNight.nextGameAt).local().format("LT")}
                  </p>
                ) : targetGuestCustomer && !targetCustomer ? (
                  <p className="text-sm sm:text-xl lg:text-sm sm:leading-tight short:text-xs 2xl:text-lg text-center whitespace-nowrap xshort:hidden text-gray-400">
                    <span
                      onClick={(e) => {
                        navigate("/register");
                      }}
                      className="font-semibold text-blue-500 hover:text-black transition-all cursor-pointer"
                    >
                      Create an account
                    </span>{" "}
                    to save your progress
                  </p>
                ) : (
                  <p className="text-sm sm:text-xl sm:leading-tight lg:text-sm short:text-xs text-center whitespace-nowrap text-gray-400">
                    <span
                      onClick={(e) => {
                        // if (quiz && custTriviaView) {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleLeaderboardPopup();
                      }}
                      className="font-semibold hide-button-flash text-blue-300 hover:text-blue-400 transition-all cursor-pointer"
                    >
                      {custTriviaView
                        ? impressiveRanking || "View leaderboard"
                        : "View leaderboard"}
                    </span>
                  </p>
                )}
              </div>
            )}

            {((personalQuiz || quiz || triviaNight).status === 2 ||
              (personalQuiz || quiz || triviaNight).status === 3) &&
              curQuestion && (
                <div
                  className={`${
                    targetMediaUrl ? "space-y-2" : "space-y-2 sm:space-y-3"
                  } short:space-y-1.5 flex z-40 flex-col items-center justify-center w-full`}
                >
                  {shuffledIndices &&
                    shuffledIndices.length > 0 &&
                    shuffledIndices.map((index) => (
                      <SelectAnswerBox
                        key={index}
                        customer={customer}
                        selectedAnswer={
                          selectedAnswer &&
                          curQuestion &&
                          selectedAnswer.questionId === curQuestion.id
                            ? selectedAnswer
                            : null
                        }
                        ansOptions={ansOptions}
                        answerErr={answerErr}
                        setAnswerErr={setAnswerErr}
                        shouldPlaySoundEffects={shouldPlaySoundEffects}
                        selectedAnswerStats={selectedAnswerStats}
                        question={curQuestion}
                        triviaNight={triviaNight}
                        answerIndex={index}
                        personalQuiz={personalQuiz}
                        quiz={quiz}
                        disabled={
                          loadingAnsOptions ||
                          loadingSubmitAnswer ||
                          // isOutOfLives ||
                          moment().isAfter(
                            (personalQuiz || quiz || triviaNight).curQEndsAt
                          )
                            ? true
                            : false
                        }
                        setLoadingSubmitAnswer={setLoadingSubmitAnswer}
                        indexClicked={indexClicked}
                        setIndexClicked={setIndexClicked}
                        setIsErrMsgShown={setIsErrMsgShown}
                        targetCustomer={targetCustomer}
                        targetGuestCustomer={targetGuestCustomer}
                      />
                    ))}
                </div>
              )}
          </>
        ) : (
          <div className="w-full z-40 text-center flex-col items-center justify-center rounded-lg">
            <div className="w-full text-left space-x-2 sm:space-x-3 flex items-center px-2 mb-1 justify-center">
              {quiz && custTriviaView && custTriviaView.status === 1 ? (
                <button
                  onClick={async (e) => {
                    e.preventDefault();
                    if (personalQuiz) {
                      togglePausePersonalQuiz({
                        variables: {
                          personalQuizId: personalQuiz ? personalQuiz.id : "",
                          secondsLeftAtPause: 0,
                        },
                      });
                    } else {
                      togglePauseQuiz({
                        variables: {
                          quizId: quiz ? quiz.id : null,
                          secondsLeftAtPause: 0,
                        },
                      });
                    }
                  }}
                  className="bg-gray-700 hover:bg-gray-800  transition-all h-14 flex aspect-square items-center justify-center rounded-full"
                >
                  <svg
                    className="w-5 aspect-square fill-current text-gray-200"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 14.5L14 10L8 5.5V14.5ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z"
                    />
                  </svg>
                </button>
              ) : (
                <></>
              )}

              <button
                onClick={async (e) => {
                  e.preventDefault();
                  if (
                    triviaNight &&
                    targetCustomer &&
                    !targetCustomer.isPaidSubscriber
                  ) {
                    toggleBuySubscriptionForm(2);
                  } else if (
                    quiz &&
                    (!custTriviaView || custTriviaView.status !== 1)
                  ) {
                    payAndJoinEvent({
                      variables: {
                        triviaNightId: triviaNight?.id,
                        quizId: quiz?.id,
                      },
                    });
                  } else if (quiz) {
                    await shareBattleInvite(
                      targetTopic,
                      targetCustomer || null,
                      quiz
                    );
                  } else {
                    await inviteFriends();
                  }
                }}
                className={`${
                  loadingPayAndJoinQuiz
                    ? "motion-safe:animate-pulse bg-blue-800 hover:bg-blue-800"
                    : "motion-safe:animate-fadeInFast"
                }  h-14 uppercase mb-0.5 flex items-center justify-center w-full disabled:bg-blue-200 disabled:hover:bg-blue-200 font-semibold hover:bg-blue-700 transition-all group overflow-hidden relative text-xl group text-white bg-blue-600 hover:shadow-lg disabled:hover:shadow-none px-5 rounded-full shadow`}
              >
                <p
                  className={`${
                    isCaptionCopied || isInviteCopied
                      ? "motion-safe:animate-fadeDownFast"
                      : hasCaptionBeenCopiedBefore
                      ? "motion-safe:animate-fadeOutUpFast"
                      : "hidden"
                  } pb-2 text-black bg-blue-300 inset-0 pt-3 w-full absolute`}
                >
                  {isSharingToApps ? "Shared!" : "Copied!"}
                </p>

                {loadingPayAndJoinQuiz && (
                  <div className="absolute motion-safe:animate-fadeDown bg-white inset-0 w-full flex items-center justify-start z-40">
                    <svg
                      className={`h-5 px-20 py-0.5 text-gray-400 stroke-current m-auto motion-safe:animate-spin`}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.636 3.636A9 9 0 0119 10"
                        strokeWidth={2}
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                )}

                <p
                  className={`${
                    isCaptionCopied || isInviteCopied
                      ? "motion-safe:animate-fadeOutDownFast"
                      : hasCaptionBeenCopiedBefore
                      ? "motion-safe:animate-fadeUpFast"
                      : ""
                  }`}
                >
                  {quiz && (!custTriviaView || custTriviaView.status !== 1)
                    ? "Join"
                    : quiz
                    ? "Share"
                    : "Invite a friend"}
                </p>
              </button>

              {quiz && custTriviaView && custTriviaView.status === 1 ? (
                <button
                  onClick={async (e) => {
                    navigate("/addFriends/battle/" + quiz.id);
                  }}
                  className="bg-gray-700 hover:bg-gray-800 transition-all h-14 flex aspect-square items-center justify-center rounded-full"
                >
                  <svg
                    className="w-5 aspect-square fill-current text-gray-200"
                    viewBox="0 0 22 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14 8C16.21 8 18 6.21 18 4C18 1.79 16.21 0 14 0C11.79 0 10 1.79 10 4C10 6.21 11.79 8 14 8ZM14 2C15.1 2 16 2.9 16 4C16 5.1 15.1 6 14 6C12.9 6 12 5.1 12 4C12 2.9 12.9 2 14 2ZM6 14C6 11.34 11.33 10 14 10C16.67 10 22 11.34 22 14V16H6V14ZM5 11V8H8V6H5V3H3V6H0V8H3V11H5ZM14 12C11.31 12 8.22 13.28 8 14H20C19.8 13.29 16.7 12 14 12Z"
                    />
                  </svg>
                </button>
              ) : (
                <></>
              )}
            </div>

            {quiz ? (
              <p className="short:text-sm text-base text-gray-400">
                The prize increases every time someone joins.
              </p>
            ) : (
              <p className="short:text-sm text-base text-gray-400">
                The prize increases every time a{" "}
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (targetCustomer && targetCustomer.isPaidSubscriber) {
                    } else {
                      toggleBuySubscriptionForm(0);
                    }
                  }}
                  className={
                    targetCustomer && targetCustomer.isPaidSubscriber
                      ? ""
                      : `underline cursor-pointer text-blue-500 hover:text-blue-600 transition-all`
                  }
                >
                  VIP
                </span>{" "}
                joins.
              </p>
            )}
          </div>
        )}
      </div>
      {(((personalQuiz || quiz || triviaNight).status >= 5 && triviaWin) ||
        (selectedAnswer &&
          selectedAnswerStats &&
          !isOutOfLives &&
          selectedAnswer.isCorrect &&
          (personalQuiz || quiz || triviaNight).lastCompletedQIndex ===
            (personalQuiz || quiz || triviaNight).numOfQs - 1)) && (
        <div className="absolute z-50 pointer-events-none inset-0">
          <Confetti
            width={width}
            height={height}
            recycle={false}
            numberOfPieces={size.width >= 640 ? 1500 : 1000}
            tweenDuration={30 * 1000}
            gravity={0.3}
          />
        </div>
      )}
    </div>
  );
}

export default TKLPopup;
