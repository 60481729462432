import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { customerClient } from "../../GraphqlApolloClients";
import {
  ACTIVE_NOTIFICATION_QUERY,
  FETCH_NUM_UNCLICKED_NOTIFICATIONS,
  SEND_NOTIF_CLICK_MUTATION,
} from "../../util/gqlFunctions";

export default function FriendNotifButton({ friendNotif, index, navigate }) {
  const [isNotifClicked, setIsNotifClicked] = useState(
    friendNotif.status === 1
  );
  const [sendNotificationClick] = useMutation(SEND_NOTIF_CLICK_MUTATION, {
    refetchQueries: [
      { query: FETCH_NUM_UNCLICKED_NOTIFICATIONS },
      { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 0 } },
      { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 1 } },
      { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 2 } },
      { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 3 } },
      { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 5 } },
    ],
    update(_, { data: { sendNotificationClick: notif } }) {
      navigate("/addFriends");
    },
    onError(err) {
      console.log(err);
    },

    client: customerClient,
  });

  return (
    <button
      key={index}
      className="w-full space-x-2 rounded sm:rounded-lg truncate transition-all group hover:bg-gray-800 flex items-center justify-start p-2 sm:p-3"
      onClick={(e) => {
        setIsNotifClicked(true);

        sendNotificationClick({
          variables: { notificationId: friendNotif.id },
        });
      }}
    >
      <svg
        className="w-3 sm:w-3.5 aspect-square text-gray-400 fill-current"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.24404 3.7559C3.24404 1.96349 4.69581 0.511719 6.48822 0.511719C8.28064 0.511719 9.73241 1.96349 9.73241 3.7559C9.73241 5.54832 8.28064 7.00009 6.48822 7.00009C4.69581 7.00009 3.24404 5.54832 3.24404 3.7559ZM0 11.0554C0 8.898 4.32288 7.8112 6.48837 7.8112C8.65387 7.8112 12.9767 8.898 12.9767 11.0554V13.4885H0V11.0554Z"
        />
      </svg>
      <p className="text-sm font-semibold text-white text-left">
        {friendNotif.friendName || ""} {friendNotif.message}
      </p>
      {!isNotifClicked ? (
        <div className="w-2 aspect-square bg-red-600 rounded-full"></div>
      ) : (
        <></>
      )}
      <div className="flex-1"></div>
      <svg
        className="w-2.5 h-2.5 text-gray-400 fill-current"
        viewBox="0 0 8 12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1.705 0L.295 1.41 4.875 6l-4.58 4.59L1.705 12l6-6-6-6z" />
      </svg>
    </button>
  );
}
