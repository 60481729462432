import { useMutation, useQuery } from "@apollo/client";
import { CardElement } from "@stripe/react-stripe-js";

import React, { useState } from "react";
import { adminClient, brandContactClient } from "../../GraphqlApolloClients";

import { useElements, useStripe } from "@stripe/react-stripe-js";

import { countries, useForm } from "../../util/hooks";
import CardInputField from "./CardInputField";

import SellerProductImagesGallery from "./SellerProductImagesGallery";
import SubmitButton from "./SubmitButton";

import {
  EDIT_AD_MUTATION,
  FETCH_ADS_BY_BIZ,
  FETCH_ALL_ADS,
  FETCH_PRODUCT_IMAGES_BY_USER,
  PROCESS_AD_LISTING_PAYMENT_RESULT,
  SEND_AD_FOR_APPROVAL_MUTATION,
} from "../../util/gqlFunctions";
import AdMediaField from "./AdMediaField";
import MultiPlaceSelectWSearchInputField from "./MultiPlaceSelectWSearchInputField";
import MultiSelectIntWSearchInputField from "./MultiSelectIntWSearchInputField";
import MultiSelectTextWSearchInputField from "./MultiSelectTextWSearchInputField";
import TextInputField from "./TextInputField";

const productCategories = {
  Electronics: "Electronics",
  "Apps & Games": "Apps & Games",
  Books: "Books",
  "Home & Kitchen": "Home & Kitchen",
  "Beauty & Personal Care": "Beauty & Personal Care",
  Toys: "Toys",
  "Sports & Outdoors": "Sports & Outdoors",
  "Clothing, Shoes & Jewelry": "Clothing, Shoes & Jewelry",
  "Pet Supplies": "Pet Supplies",
  Grocery: "Grocery",
  Restaurant: "Restaurant",
  Automotive: "Automotive",
  "Tools & Home Improvement": "Tools & Home Improvement",
  "Health & Household": "Health & Household",
};

const genders = {
  2: "Everyone",
  1: "Female",
  0: "Male",
};

const ageRanges = {
  0: "Kids",
  1: "18-24",
  2: "25-34",
  3: "35-44",
  4: "45-54",
  5: "55-64",
  6: "65+",
};
// const topics = [
//   "General",
//   "History",
//   "Geography",
//   "Pop Culture",
//   "Sports",
//   "Art",
//   "Music",
//   "Film",
//   "Television",
//   "Video Games",
//   "Literature",
//   "Technology",
//   "Science",
//   "Space",
//   "Math",
//   "Nature",
//   "Animals",
//   "Food",
//   "Cooking",
//   "Culture",
//   "Cars",
//   "Fashion",
//   "Celebrities",
//   "Holidays",
//   "Mythology",
//   "Language",
//   "Business",
//   "Economics",
//   "Politics",
//   "Government",
//   "Current Events",
//   "Law",
//   "Criminology",
//   "Psychology",
//   "Sociology",
//   "Medicine",
// ];

const formats = {
  0: "Narrow (58:7)",
  // 1: "Narrow Vertical (7:58)",
  // 2: "Wide (4:1)",
  // 3: "Wide Vertical (1:4)",
  // 4: "Short (2:1)",
  // 5: "Short Vertical (1:2)",
  6: "Regular (16:9)",
  7: "Vertical (9:14)",
  // 8: "Square (1:1)",
  // 9: "Front page (32:9)",
  // 10: "Audio",
};

function EditSellerAd({ targetAd, setTargetAd, isAdmin }) {
  const [errors, setErrors] = useState({});
  const stripe = useStripe();
  const elements = useElements();
  const [inputValue, setInputValue] = useState("");
  const [categoryInputValue, setCategoryInputValue] = useState("");
  const [placeInputValue, setPlaceInputValue] = useState("");
  const [ageRangeInputValue, setAgeRangeInputValue] = useState("");
  const [previewPhoto0Url, setPreviewPhoto0Url] = useState("");
  const [previewPhoto6Url, setPreviewPhoto6Url] = useState("");
  const [previewPhoto8Url, setPreviewPhoto8Url] = useState("");
  const [previewPhoto9Url, setPreviewPhoto9Url] = useState("");
  const [previewPhoto7Url, setPreviewPhoto7Url] = useState("");

  const { onChange, values, setValues, onChangeMedia } = useForm(
    editAdCallback,
    {
      adId: targetAd.id,
      fieldsToEdit: [],
      ...targetAd,

      discountedPrice: targetAd.discountedPrice || 0,
      usualPrice: targetAd.usualPrice || 0,
      format: targetAd.format || 0,
      adType: targetAd.adType,
      adVideoUrl: targetAd.adVideoUrl,
      useNewCard: true,
      formatInView: 0,
    }
  );

  const [previewVideo0URL, setPreviewVideo0URL] = useState("");
  const [previewVideo6URL, setPreviewVideo6URL] = useState("");
  const [previewVideo8URL, setPreviewVideo8URL] = useState("");
  const [previewVideo7URL, setPreviewVideo7URL] = useState("");
  const [previewVideo9URL, setPreviewVideo9URL] = useState("");

  const [editAd, { loading: loadingEditAd }] = useMutation(EDIT_AD_MUTATION, {
    refetchQueries: [
      {
        query: FETCH_ADS_BY_BIZ,
        variables: { businessId: targetAd.businessId },
      },
    ],
    update(_, { data: { editAd: editedAd } }) {
      setValues({
        ...values,
        ...editedAd,
        fieldsToEdit: [],
      });
      setPreviewVideo0URL(null);
      setPreviewPhoto0Url(null);
      setPreviewVideo6URL(null);
      setPreviewPhoto6Url(null);
      setPreviewVideo8URL(null);
      setPreviewPhoto8Url(null);
      // setTargetAd(null);
      setErrors({});
    },
    onError(err) {
      console.log(previewVideo6URL);
      console.log(previewVideo8URL);

      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0].extensions.errors);

        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: isAdmin ? adminClient : brandContactClient,
  });

  function setFieldsToEdit(name) {
    if (values.fieldsToEdit && values.fieldsToEdit.includes(name) && targetAd) {
      if (
        name.includes("Amount") ||
        name.includes("Price") ||
        name === "discount"
      ) {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: targetAd[name] ? Math.floor(targetAd[name] / 100) : 10,
        });
      } else {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: targetAd[name],
        });
      }
    } else {
      setValues({
        ...values,
        fieldsToEdit: [name],
      });
    }
  }

  function editAdCallback(e) {
    e.preventDefault();
    editAd();
  }

  const {
    data: { getProductImagesByUser: productImages } = {},
    loading: loadingProductImages,
    // subscribeToMore,
  } = useQuery(FETCH_PRODUCT_IMAGES_BY_USER, {
    client: isAdmin ? adminClient : brandContactClient,
  });

  const [isCardComplete, setIsCardComplete] = useState(false);
  const [cardElemIsFocused, setCardElemIsFocused] = useState(false);

  const [shouldOpenAddImages, setShouldOpenAddImages] = useState(false);

  const [loadingSendAdForApproval, setLoadingSendAdForApproval] =
    useState(false);

  const [processAdListingPaymentResult] = useMutation(
    PROCESS_AD_LISTING_PAYMENT_RESULT,
    {
      refetchQueries: [
        {
          query: FETCH_ADS_BY_BIZ,
          variables: { businessId: targetAd.businessId },
        },
      ],
      update(proxy, { data: { processAdListingPaymentResult: sentProduct } }) {
        setLoadingSendAdForApproval(false);

        if (sentProduct.status === 1) {
          setTargetAd(null);

          setErrors({});
        }
      },
      onError(err) {
        console.log(err);
        setLoadingSendAdForApproval(false);

        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      client: isAdmin ? adminClient : brandContactClient,
    }
  );
  // console.log(targetAd.status);
  const [sendAdForApproval] = useMutation(SEND_AD_FOR_APPROVAL_MUTATION, {
    refetchQueries: [
      {
        query: FETCH_ADS_BY_BIZ,
        variables: { businessId: targetAd.businessId },
      },
      {
        query: FETCH_ALL_ADS,
      },
    ],
    async update(_, { data: { sendAdForApproval: approvedAd } }) {
      if (values.useNewCard && approvedAd.status !== 2) {
        const result = await stripe.confirmCardPayment(
          approvedAd.paymentIntentClientSecret,
          {
            payment_method: {
              card: elements.getElement(CardElement),
            },
          }
        );

        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          setErrors({
            ...errors,
            paymentError: result.error.message,
          });
          setLoadingSendAdForApproval(false);
        } else {
          if (result.paymentIntent.status === "succeeded") {
            processAdListingPaymentResult({
              variables: {
                adId: approvedAd.id,
              },
            });
          } else {
            setErrors({
              ...errors,
              paymentError: "- Invalid",
            });
            setLoadingSendAdForApproval(false);
          }
        }
      } else if (approvedAd.status === 2) {
        setErrors({});

        setLoadingSendAdForApproval(false);

        setTargetAd(null);
      }
    },
    onError(err) {
      console.log(err);
      setLoadingSendAdForApproval(false);
      // setValues({ ...values, shouldDelete: false });
      // if (err.graphQLErrors[0]) {
      //   setErrors({
      //     ...err.graphQLErrors[0].extensions.errors,
      //   });

      //   if (err.graphQLErrors[0].extensions.errors) {
      //     setValues({
      //       ...values,
      //       fieldsToEdit: Object.keys(err.graphQLErrors[0].extensions.errors),
      //     });
      //   }
      // }
      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0].extensions.errors);

        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: isAdmin ? adminClient : brandContactClient,
  });

  // const handleChangeDateTime = (event) => {
  //   event.preventDefault();

  //   // Get the selected date and time in the user's timezone
  //   const localDate = moment(event.target.value).toDate();

  //   // Convert the local date and time to UTC
  //   const utcDate = moment.utc(localDate);

  //   // Save the UTC date and time to the state
  //   setValues({ ...values, startSaleAt: utcDate });
  // };

  // const displayStartCampaignAt = () => {
  //   // Format and display the campaignStartsAt value in the user's timezone
  //   return moment(values.campaignStartsAt)
  //     .local()
  //     .format("YYYY-MM-DDTHH:mm:ss");
  // };

  function sendAdForApprovalCallback(e) {
    e.preventDefault();

    if (!isAdmin) {
      if (
        targetAd.status === 0 &&
        values.useNewCard &&
        (!stripe || !elements)
      ) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        setLoadingSendAdForApproval(false);

        return;
      }

      if (targetAd.status === 0 && values.useNewCard && !isCardComplete) {
        setErrors({
          paymentError: isCardComplete ? null : "- Required",
          ...errors,
        });
        setLoadingSendAdForApproval(false);

        return;
      }
    }

    setLoadingSendAdForApproval(true);

    sendAdForApproval();
  }

  return (
    <div className="fixed pt-16 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      {!loadingProductImages && shouldOpenAddImages && (
        <SellerProductImagesGallery
          adId={targetAd.id}
          productImages={productImages}
          setShouldOpenAddImages={setShouldOpenAddImages}
          targetAd={targetAd}
        />
      )}

      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          setTargetAd(null);
        }}
        className="z-40 bg-gray-500 hide-button-flash overscroll-contain bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-darker-gray shadow-xl rounded-b-none sm:rounded-3xl short:h-full short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            setTargetAd(null);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-700 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-white text-2xl font-bold w-full short:text-xl short:leading-tight">
          Ad - {targetAd.campaignName}
        </p>
        <p className="text-gray-400 w-full">
          Set up your ad campaign and go live
        </p>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
        <div className="w-full flex flex-col items-start justify-start space-y-6 mt-6">
          {(targetAd.status === 0 || targetAd.status === 5) && (
            <TextInputField
              title="Title (for internal use)"
              name="campaignName"
              type="text"
              error={errors.campaignName}
              disabled={
                loadingEditAd ||
                (targetAd.status !== 0 && targetAd.status !== 5)
              }
              onChange={onChange}
              setFieldsToEdit={setFieldsToEdit}
              fieldsToEdit={
                targetAd.status === 0 || targetAd.status === 5
                  ? values.fieldsToEdit
                  : null
              }
              editFunc={editAd}
              value={values.campaignName}
            />
          )}
          {targetAd.status === 0 || targetAd.status === 5 ? (
            <div className="w-full">
              <div className="flex items-center justify-between text-sm sm:text-base">
                <label
                  htmlFor={"format"}
                  className="font-semibold text-gray-400 "
                >
                  Format{" "}
                  {errors.format &&
                    (!values.fieldsToEdit ||
                      (values.fieldsToEdit &&
                        values.fieldsToEdit.includes("format"))) && (
                      <span className="text-red-500">{errors.format}</span>
                    )}
                </label>
                {(targetAd.status === 0 ||
                  (isAdmin && targetAd.status === 3) ||
                  targetAd.status === 5) &&
                  values.fieldsToEdit &&
                  values.fieldsToEdit.length === 0 && (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setValues({
                          ...values,
                          fieldsToEdit: ["format"],
                        });
                      }}
                      className="text-blue-300 transition-all font-semibold hover:text-blue-400"
                    >
                      Edit
                    </button>
                  )}
                {values.fieldsToEdit &&
                  values.fieldsToEdit.includes("format") && (
                    <div className="flex items-center justify-end space-x-2">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setFieldsToEdit("format");
                        }}
                        className="text-red-500 transition-all font-semibold hover:text-red-700"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          editAd();
                        }}
                        className="text-green-500 transition-all font-semibold hover:text-green-700"
                      >
                        Save
                      </button>
                    </div>
                  )}
              </div>

              <div
                className={`gap-2 flex-wrap flex items-center justify-start mt-2`}
              >
                {Object.keys(formats).map((option, index) => (
                  <button
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();

                      if (
                        values.fieldsToEdit &&
                        values.fieldsToEdit.includes("format")
                      ) {
                        setValues({
                          ...values,
                          formatInView: parseInt(option),
                          format: parseInt(option),
                        });
                      }
                    }}
                    className={`px-4 ${
                      values.format !== parseInt(option)
                        ? targetAd.status === 0 || targetAd.status === 5
                          ? "flex opacity-30"
                          : "hidden"
                        : ""
                    } ${
                      values.fieldsToEdit &&
                      values.fieldsToEdit.includes("format")
                        ? " hover:bg-blue-100"
                        : " cursor-default"
                    }  rounded-full text-white items-center space-x-2 transition-all justify-start border border-gray-800 overflow-hidden py-1`}
                    disabled={
                      !(
                        values.fieldsToEdit &&
                        values.fieldsToEdit.includes("format")
                      )
                    }
                  >
                    <p>{formats[option]}</p>
                  </button>
                ))}
              </div>

              {(targetAd.status === 0 || targetAd.status === 5) && (
                <div className="flex sm:text-base text-sm flex-col items-start w-full justify-start px-2 mt-2 text-gray-600">
                  {/* <li>You will only be charged for month 1 today</li> */}
                  {values.format === 0 ? (
                    <p>
                      &#8227; Shown as a banner in focus mode, battles, and
                      TKnight LIVE
                    </p>
                  ) : values.format === 6 ? (
                    <>
                      <p>
                        &#8227; Shown as a banner in focus mode, battles, and
                        TKnight LIVE
                      </p>
                      <p>
                        &#8227; Also shown as an unskippable pop up ad in focus
                        mode, battles, and TKnight LIVE for wide screens (ex:
                        Macbook)
                      </p>
                    </>
                  ) : values.format === 7 ? (
                    <>
                      <p>
                        &#8227; Shown as an unskippable pop up ad in focus mode,
                        battles, and TKnight LIVE for mobile screens (ex: iPhone
                        11)
                      </p>
                    </>
                  ) : values.format === 8 ? (
                    <>
                      <p>
                        &#8227; Shown as an unskippable pop up ad in focus mode,
                        battles, and TKnight LIVE for short and narrow screens
                        (ex: iPhone 4)
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
          <div className="w-full">
            <label
              htmlFor={"objectiveType"}
              className="font-semibold text-gray-200 text-sm sm:text-base"
            >
              Reach{" "}
              {errors.objectiveType && (
                <span className="text-red-500">{errors.objectiveType}</span>
              )}
            </label>
            <div className="flex items-start mt-1 justify-start space-x-2 w-full">
              {(targetAd.status === 0 ||
                targetAd.status === 5 ||
                targetAd.objectiveType === 0) && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({ ...values, objectiveType: 0 });
                    editAd({
                      variables: {
                        ...values,
                        fieldsToEdit: ["objectiveType"],
                        objectiveType: 0,
                      },
                    });
                  }}
                  className={`${
                    values.objectiveType === 0
                      ? "border-blue-500 "
                      : "border-gray-700 hover:bg-gray-700"
                  } transition-all group relative text-left w-full shadow-sm flex-1 px-3 py-2 rounded-md border focus:border-indigo-500 focus:ring-indigo-500`}
                  disabled={
                    loadingEditAd ||
                    !(targetAd.status === 0 || targetAd.status === 5)
                  }
                >
                  <div className="w-full flex items-center justify-between">
                    <p className="text-gray-400 text-sm sm:text-base">Small</p>
                    <svg
                      className={`${
                        values.objectiveType === 0
                          ? "text-blue-500 opacity-100"
                          : "text-gray-200 opacity-0 "
                      } opacity-0 ${
                        targetAd.status === 0 || targetAd.status === 5
                          ? "group-hover:opacity-100"
                          : ""
                      } transition-all h-4 fill-current `}
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="6.5" cy="6.5" r="6.5" />
                      <path d="M3 6.72727L5.5 9L10 4" stroke="white" />
                    </svg>
                  </div>
                  <p className="text-xl text-white font-bold">
                    US$4.16/day
                    {/* <span className="text-gray-400 font-normal text-lg">
                {" "}
                + 16% per job
              </span> */}
                  </p>
                </button>
              )}
              {(targetAd.status === 0 ||
                targetAd.status === 5 ||
                targetAd.objectiveType === 1) && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({ ...values, objectiveType: 1 });
                    editAd({
                      variables: {
                        ...values,
                        fieldsToEdit: ["objectiveType"],
                        objectiveType: 1,
                      },
                    });
                  }}
                  className={`${
                    values.objectiveType === 1
                      ? "border-red-500 "
                      : "border-gray-700 hover:bg-gray-700"
                  } disabled:bg-gray-50 transition-all group relative text-left w-full shadow-sm flex-1 px-3 py-2 rounded-md border focus:border-red-500 focus:ring-red-500`}
                  disabled={
                    loadingEditAd ||
                    !(targetAd.status === 0 || targetAd.status === 5)
                  }
                >
                  <div className="w-full flex items-center justify-between">
                    <p className="text-gray-400 text-sm sm:text-base">Medium</p>
                    <svg
                      className={`${
                        values.objectiveType === 1
                          ? "text-red-500 opacity-100"
                          : "text-gray-200 opacity-0 "
                      } ${
                        targetAd.status === 0 || targetAd.status === 5
                          ? "group-hover:opacity-100"
                          : ""
                      } transition-all h-4 fill-current `}
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="6.5" cy="6.5" r="6.5" />
                      <path d="M3 6.72727L5.5 9L10 4" stroke="white" />
                    </svg>
                  </div>
                  <p className="text-xl text-gray-800 font-bold">US$6.47/day</p>
                </button>
              )}

              {(targetAd.status === 0 ||
                targetAd.status === 5 ||
                targetAd.objectiveType === 2) && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({ ...values, objectiveType: 2 });
                    editAd({
                      variables: {
                        ...values,
                        fieldsToEdit: ["objectiveType"],
                        objectiveType: 2,
                      },
                    });
                  }}
                  className={`${
                    values.objectiveType === 2
                      ? "border-green-500 "
                      : "border-gray-700 hover:bg-gray-700"
                  } disabled:bg-gray-50 transition-all group relative text-left w-full shadow-sm flex-1 px-3 py-2 rounded-md border focus:border-green-500 focus:ring-green-500`}
                  disabled={
                    loadingEditAd ||
                    !(targetAd.status === 0 || targetAd.status === 5)
                  }
                >
                  <div className="w-full flex items-center justify-between">
                    <p className="text-gray-400 text-sm sm:text-base">Large</p>
                    <svg
                      className={`${
                        values.objectiveType === 2
                          ? "text-green-500 opacity-100"
                          : "text-gray-200 opacity-0 "
                      } ${
                        targetAd.status === 0 || targetAd.status === 5
                          ? "group-hover:opacity-100"
                          : ""
                      } transition-all h-4 fill-current `}
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="6.5" cy="6.5" r="6.5" />
                      <path d="M3 6.72727L5.5 9L10 4" stroke="white" />
                    </svg>
                  </div>
                  <p className="text-xl text-gray-800 font-bold">US$9.05/day</p>
                </button>
              )}
            </div>

            {(targetAd.status === 0 || targetAd.status === 5) && (
              <div className="flex sm:text-base text-sm flex-col items-start w-full justify-start px-2 mt-2 text-gray-600">
                {/* <li>You will only be charged for month 1 today</li> */}
                <p>
                  &#8227; Your ad will be shown to at least{" "}
                  <span className="font-semibold">
                    {values.objectiveType === 0
                      ? "100"
                      : values.objectiveType === 1
                      ? "200"
                      : "500"}{" "}
                    unique members
                  </span>{" "}
                  of your selected target audience every day
                </p>
                {/* {(values.objectiveType === 1 || values.objectiveType === 2) && (
                  <> */}
                <p>
                  &#8227; People will be able to click your ad to go to your
                  link
                </p>
                <p>&#8227; Track the daily performance of your ad</p>

                <p>&#8227; Billed daily - stop any time</p>
              </div>
            )}
          </div>

          <TextInputField
            title="Link (optional)"
            name="link"
            type={"url"}
            error={errors.link}
            disabled={
              loadingEditAd ||
              (targetAd.status !== 0 && targetAd.status !== 5 && !isAdmin)
            }
            onChange={onChange}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={
              targetAd.status === 0 ||
              (isAdmin && targetAd.status === 3) ||
              targetAd.status === 5
                ? values.fieldsToEdit
                : null
            }
            value={values.link}
            editFunc={editAd}
            prefix="https://"
          />

          {values.format === parseInt(0) && (
            <AdMediaField
              name="narrowHorAdFile"
              urlFieldName="narrowHorAdUrl"
              title="Narrow image/video (< 4 MB, 58/7)"
              values={values}
              previewVideoURL={previewVideo0URL}
              setPreviewPhotoURL={(photoTempUrl) => {
                setPreviewPhoto0Url(photoTempUrl);
              }}
              previewPhotoURL={previewPhoto0Url}
              setPreviewVideoURL={(videoTempUrl) => {
                setPreviewVideo0URL(videoTempUrl);
              }}
              editAd={editAd}
              errors={errors}
              loadingEditAd={loadingEditAd}
              setFieldsToEdit={setFieldsToEdit}
              setErrors={setErrors}
              targetAd={targetAd}
              onChangeMedia={onChangeMedia}
              setValues={setValues}
              maxSize={4000000}
              aspect={"aspect-[58/7]"}
              isAdmin={isAdmin}
            />
          )}
          {values.format === parseInt(6) && (
            <AdMediaField
              name="regularHorAdFile"
              urlFieldName="regularHorAdUrl"
              title="Regular image/video (< 10 MB)"
              values={values}
              previewVideoURL={previewVideo0URL}
              setPreviewPhotoURL={(photoTempUrl) => {
                setPreviewPhoto6Url(photoTempUrl);
              }}
              previewPhotoURL={previewPhoto6Url}
              setPreviewVideoURL={(videoTempUrl) => {
                setPreviewVideo6URL(videoTempUrl);
              }}
              editAd={editAd}
              errors={errors}
              loadingEditAd={loadingEditAd}
              setFieldsToEdit={setFieldsToEdit}
              setErrors={setErrors}
              targetAd={targetAd}
              onChangeMedia={onChangeMedia}
              setValues={setValues}
              maxSize={10000000}
              aspect={"aspect-[16/9]"}
              isAdmin={isAdmin}
            />
          )}
          {values.format === parseInt(8) && (
            <AdMediaField
              name="squareAdFile"
              urlFieldName="squareAdUrl"
              title="Square image/video (< 10 MB)"
              values={values}
              previewVideoURL={previewVideo8URL}
              setPreviewPhotoURL={(photoTempUrl) => {
                setPreviewPhoto8Url(photoTempUrl);
              }}
              previewPhotoURL={previewPhoto8Url}
              setPreviewVideoURL={(videoTempUrl) => {
                setPreviewVideo8URL(videoTempUrl);
              }}
              editAd={editAd}
              errors={errors}
              loadingEditAd={loadingEditAd}
              setFieldsToEdit={setFieldsToEdit}
              setErrors={setErrors}
              targetAd={targetAd}
              onChangeMedia={onChangeMedia}
              setValues={setValues}
              maxSize={10000000}
              aspect={"aspect-[1/1]"}
              isAdmin={isAdmin}
            />
          )}
          {values.format === parseInt(9) && (
            <AdMediaField
              name="frontPageBannerFile"
              urlFieldName="frontPageBannerUrl"
              title="Front page image/video (< 10 MB)"
              values={values}
              previewVideoURL={previewVideo9URL}
              setPreviewPhotoURL={(photoTempUrl) => {
                setPreviewPhoto9Url(photoTempUrl);
              }}
              previewPhotoURL={previewPhoto9Url}
              setPreviewVideoURL={(videoTempUrl) => {
                setPreviewVideo9URL(videoTempUrl);
              }}
              editAd={editAd}
              errors={errors}
              loadingEditAd={
                loadingEditAd &&
                values.fieldsToEdit &&
                values.fieldsToEdit.includes("frontPageBannerFile")
              }
              setFieldsToEdit={setFieldsToEdit}
              setErrors={setErrors}
              targetAd={targetAd}
              onChangeMedia={onChangeMedia}
              setValues={setValues}
              maxSize={10000000}
              aspect={"aspect-[32/9]"}
              isAdmin={isAdmin}
            />
          )}

          {values.format === parseInt(7) && (
            <AdMediaField
              name="regularVerAdFile"
              urlFieldName="regularVerAdUrl"
              title="Vertical image/video (< 10 MB)"
              values={values}
              previewVideoURL={previewVideo7URL}
              setPreviewPhotoURL={(photoTempUrl) => {
                setPreviewPhoto7Url(photoTempUrl);
              }}
              previewPhotoURL={previewPhoto7Url}
              setPreviewVideoURL={(videoTempUrl) => {
                setPreviewVideo7URL(videoTempUrl);
              }}
              editAd={editAd}
              errors={errors}
              loadingEditAd={
                loadingEditAd &&
                values.fieldsToEdit &&
                values.fieldsToEdit.includes("regularVerAdFile")
              }
              setFieldsToEdit={setFieldsToEdit}
              setErrors={setErrors}
              targetAd={targetAd}
              onChangeMedia={onChangeMedia}
              setValues={setValues}
              maxSize={10000000}
              aspect={"aspect-[9/14]"}
              isAdmin={isAdmin}
            />
          )}
          <MultiSelectTextWSearchInputField
            error={errors && errors.categories}
            values={values}
            name="categories"
            value={values.categories}
            fieldsToEdit={values.fieldsToEdit}
            title="Product category"
            targetAd={targetAd}
            canEdit={
              targetAd.status === 0 ||
              (isAdmin && targetAd.status === 3) ||
              targetAd.status === 5
            }
            setValues={setValues}
            maxTags={3}
            setFieldsToEdit={setFieldsToEdit}
            setTextInputValue={setCategoryInputValue}
            edit={editAd}
            loading={loadingEditAd}
            textInputValue={categoryInputValue}
            options={productCategories}
          />
          {/* <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor="targetPlaces"
                className="font-semibold text-gray-400 "
              >
                Target cities and/or countries{" "}
                {errors.targetPlaces &&
                  (!values.fieldsToEdit ||
                    (values.fieldsToEdit &&
                      values.fieldsToEdit.includes("targetPlaces"))) && (
                    <span className="text-red-500">{errors.targetPlaces}</span>
                  )}
              </label>
              {(targetAd.status === 0 || targetAd.status === 5) &&
                values.fieldsToEdit.length === 0 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setValues({
                        ...values,
                        fieldsToEdit: ["targetPlaces"],
                      });
                    }}
                    className="text-blue-300 transition-all font-semibold hover:text-blue-400"
                  >
                    Edit
                  </button>
                )}
              {values.fieldsToEdit.includes("targetPlaces") && (
                <div className="flex items-center justify-end space-x-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldsToEdit("targetPlaces");
                      setInputValue("");
                    }}
                    className="text-red-500 transition-all font-semibold hover:text-red-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      editAd();
                      setInputValue("");
                    }}
                    className="text-green-500 transition-all font-semibold hover:text-green-700"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>

            {values.targetPlaces && values.targetPlaces.length > 0 && (
              <div
                className={`gap-2 flex-wrap flex items-center justify-start mt-2`}
              >
                {values.targetPlaces.map((targetPlace, index) => (
                  <div
                    key={index}
                    className="rounded-full flex items-center justify-start bg-gray-800 text-white pl-4 overflow-hidden py-1"
                  >
                    <p>{targetPlace}</p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();

                        const targetPlacesCopy = values.targetPlaces
                          ? [...values.targetPlaces]
                          : [];
                        if (targetPlacesCopy[index]) {
                          targetPlacesCopy.splice(index, 1);
                        }
                        setValues({
                          ...values,
                          targetPlaces: targetPlacesCopy,
                        });
                      }}
                      disabled={
                        loadingEditAd ||
                        (values.fieldsToEdit &&
                          !values.fieldsToEdit.includes("targetPlaces"))
                      }
                      className="text-gray-400 disabled:opacity-50 hover:text-gray-500 transition-all pl-2 pr-3"
                    >
                      <svg
                        className="w-4 fill-current"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            )}
            {values.fieldsToEdit.includes("targetPlaces") && (
              <input
                className={`sm:text-lg mt-3 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-200 border focus:border-indigo-500 focus:ring-indigo-500`}
                type={"text"}
                value={inputValue}
                onChange={(e) => {
                  const value = e.target.value;
                  setInputValue(value);
                  const targetPlaces = value
                    .split(",")
                    .map((targetPlace) => targetPlace.trim());
                  if (targetPlaces.length > 1) {
                    if (values.targetPlaces) {
                      const newTags = [
                        ...values.targetPlaces,
                        ...targetPlaces,
                      ].filter((item, pos, self) => self.indexOf(item) === pos);
                      setValues({
                        ...values,
                        targetPlaces: newTags.slice(0, 4),
                      });
                    } else {
                      setValues({
                        ...values,
                        targetPlaces: targetPlaces.slice(0, 4),
                      });
                    }
                    setInputValue("");
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    event.key === "Enter" ||
                    event.key === "," ||
                    event.key === "."
                  ) {
                    event.preventDefault();
                    const targetPlace = inputValue.trim();
                    if (targetPlace) {
                      if (values.targetPlaces) {
                        if (values.targetPlaces.includes(targetPlace)) {
                          setInputValue("");
                        } else {
                          setValues({
                            ...values,
                            targetPlaces: [...values.targetPlaces, targetPlace],
                          });
                        }
                      } else {
                        setValues({ ...values, targetPlaces: [targetPlace] });
                      }
                      setInputValue("");
                    }
                  } else if (event.key === "Backspace" && !inputValue) {
                    event.preventDefault();
                    const targetPlacesCopy = values.targetPlaces
                      ? [...values.targetPlaces]
                      : [];
                    targetPlacesCopy.pop();

                    setValues({ ...values, targetPlaces: targetPlacesCopy });
                  }
                }}
                placeholder={""}
                disabled={
                  loadingEditAd ||
                  (values.fieldsToEdit &&
                    !values.fieldsToEdit.includes("targetPlaces"))
                }
                maxLength={200}
              />
            )}
          </div> */}
          <MultiPlaceSelectWSearchInputField
            error={errors && errors.targetPlaces}
            value={values.targetPlaces}
            fieldsToEdit={values.fieldsToEdit}
            name="targetPlaces"
            values={values}
            title="Target cities and/or countries"
            targetAd={targetAd}
            canEdit={
              targetAd.status === 0 ||
              (isAdmin && targetAd.status === 3) ||
              targetAd.status === 5
            }
            setValues={setValues}
            setFieldsToEdit={setFieldsToEdit}
            setTextInputValue={setPlaceInputValue}
            edit={editAd}
            maxTags={5}
            loading={loadingEditAd}
            textInputValue={placeInputValue}
            options={countries}
          />
          <MultiSelectIntWSearchInputField
            error={errors && errors.targetAgeRanges}
            value={values.targetAgeRanges}
            fieldsToEdit={values.fieldsToEdit}
            name="targetAgeRanges"
            values={values}
            title="Audience age"
            targetAd={targetAd}
            canEdit={
              targetAd.status === 0 ||
              (isAdmin && targetAd.status === 3) ||
              targetAd.status === 5
            }
            setValues={setValues}
            setFieldsToEdit={setFieldsToEdit}
            setTextInputValue={setAgeRangeInputValue}
            edit={editAd}
            loading={loadingEditAd}
            textInputValue={ageRangeInputValue}
            options={ageRanges}
          />
          {/* <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor="targetGender"
                className="font-semibold text-gray-400 "
              >
                Gender{" "}
                {errors.targetGender &&
                  (!values.fieldsToEdit ||
                    (values.fieldsToEdit &&
                      values.fieldsToEdit.includes("targetGender"))) && (
                    <span className="text-red-500">{errors.targetGender}</span>
                  )}
              </label>
              {(targetAd.status === 0 || targetAd.status === 5) &&
                values.fieldsToEdit.length === 0 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setValues({
                        ...values,
                        fieldsToEdit: ["targetGender"],
                      });
                    }}
                    className="text-blue-300 transition-all font-semibold hover:text-blue-400"
                  >
                    Edit
                  </button>
                )}
              {values.fieldsToEdit.includes("targetGender") && (
                <div className="flex items-center justify-end space-x-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldsToEdit("targetGender");
                    }}
                    className="text-red-500 transition-all font-semibold hover:text-red-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      editAd();
                    }}
                    className="text-green-500 transition-all font-semibold hover:text-green-700"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <select
              id="targetGender"
              name="targetGender"
              // autoComplete=""
              value={values.targetGender}
              onChange={onChange}
              disabled={
                loadingEditAd ||
                (values.fieldsToEdit &&
                  !values.fieldsToEdit.includes("targetGender"))
              }
              className="sm:text-lg bg-transparent text-white mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-700 border focus:border-indigo-500 focus:ring-indigo-500"
            >
              {/* {["New", "Used"].map((targetGender, index) => ( 
          <option value={0}>Male</option>
          <option value={1}>Female</option>
          <option value={2}>Everyone</option>
          {/* ))} 
            </select>
          </div> */}

          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor={"targetGender"}
                className="font-semibold text-gray-400 "
              >
                Gender{" "}
                {errors.targetGender &&
                  (!values.fieldsToEdit ||
                    (values.fieldsToEdit &&
                      values.fieldsToEdit.includes("targetGender"))) && (
                    <span className="text-red-500">{errors.targetGender}</span>
                  )}
              </label>
              {(targetAd.status === 0 ||
                (isAdmin && targetAd.status === 3) ||
                targetAd.status === 5) &&
                values.fieldsToEdit &&
                values.fieldsToEdit.length === 0 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setValues({
                        ...values,
                        fieldsToEdit: ["targetGender"],
                      });
                    }}
                    className="text-blue-300 transition-all font-semibold hover:text-blue-400"
                  >
                    Edit
                  </button>
                )}
              {values.fieldsToEdit &&
                values.fieldsToEdit.includes("targetGender") && (
                  <div className="flex items-center justify-end space-x-2">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldsToEdit("targetGender");
                      }}
                      className="text-red-500 transition-all font-semibold hover:text-red-700"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        editAd();
                      }}
                      className="text-green-500 transition-all font-semibold hover:text-green-700"
                    >
                      Save
                    </button>
                  </div>
                )}
            </div>

            <div
              className={`gap-2 flex-wrap flex items-center justify-start mt-2`}
            >
              {[1, 0, 2].map((option, index) => (
                <button
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    if (values.targetGender === parseInt(option)) {
                      setValues({
                        ...values,
                        targetGender: parseInt(option),
                      });
                    } else if (
                      values.fieldsToEdit &&
                      values.fieldsToEdit.includes("targetGender")
                    ) {
                      setValues({
                        ...values,
                        targetGender: parseInt(option),
                      });
                    }
                  }}
                  className={`px-4 ${
                    values.targetGender !== parseInt(option)
                      ? targetAd.status === 0 || targetAd.status === 5
                        ? "flex opacity-30"
                        : "hidden"
                      : ""
                  } ${
                    values.fieldsToEdit &&
                    values.fieldsToEdit.includes("targetGender")
                      ? " hover:bg-blue-100"
                      : " cursor-default"
                  }  rounded-full text-white items-center space-x-2 transition-all justify-start border border-gray-700 bg-gray-800 overflow-hidden py-1`}
                  disabled={
                    !(
                      values.fieldsToEdit &&
                      values.fieldsToEdit.includes("targetGender")
                    )
                  }
                >
                  <p>{genders[option]}</p>
                </button>
              ))}
            </div>
          </div>
          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor="targetInterests"
                className="font-semibold text-gray-400 "
              >
                Tags (optional){" "}
                {errors.targetInterests &&
                  (!values.fieldsToEdit ||
                    (values.fieldsToEdit &&
                      values.fieldsToEdit.includes("targetInterests"))) && (
                    <span className="text-red-500">
                      {errors.targetInterests}
                    </span>
                  )}
              </label>
              {(targetAd.status === 0 ||
                (isAdmin && targetAd.status === 3) ||
                targetAd.status === 5) &&
                values.fieldsToEdit.length === 0 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setValues({
                        ...values,
                        fieldsToEdit: ["targetInterests"],
                      });
                    }}
                    className="text-blue-300 transition-all font-semibold hover:text-blue-400"
                  >
                    Edit
                  </button>
                )}
              {values.fieldsToEdit.includes("targetInterests") && (
                <div className="flex items-center justify-end space-x-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldsToEdit("targetInterests");
                      setInputValue("");
                    }}
                    className="text-red-500 transition-all font-semibold hover:text-red-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      editAd();
                      setInputValue("");
                    }}
                    className="text-green-500 transition-all font-semibold hover:text-green-700"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>

            {values.targetInterests && values.targetInterests.length > 0 && (
              <div
                className={`gap-2 mb-2 flex-wrap flex items-center justify-start mt-2`}
              >
                {values.targetInterests.map((interest, index) => (
                  <div
                    key={index}
                    className="rounded-full lowercase flex items-center justify-start bg-gray-800 border border-gray-700 text-white pl-4 overflow-hidden py-1"
                  >
                    <p>{interest}</p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();

                        const interestsCopy = values.interests
                          ? [...values.interests]
                          : [];
                        if (interestsCopy[index]) {
                          interestsCopy.splice(index, 1);
                        }
                        setValues({ ...values, interests: interestsCopy });
                      }}
                      disabled={
                        loadingEditAd ||
                        (values.fieldsToEdit &&
                          !values.fieldsToEdit.includes("interests"))
                      }
                      className="text-gray-400 disabled:opacity-50 hover:text-gray-500 transition-all pl-2 pr-3"
                    >
                      {values.fieldsToEdit.includes("targetInterests") && (
                        <svg
                          className="w-4 fill-current"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                        </svg>
                      )}
                    </button>
                  </div>
                ))}
              </div>
            )}
            {values.fieldsToEdit.includes("targetInterests") && (
              <input
                className={`sm:text-lg lowercase mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-200 border focus:border-indigo-500 focus:ring-indigo-500`}
                type={"text"}
                value={inputValue}
                onChange={(e) => {
                  const value = e.target.value;
                  setInputValue(value);
                  const targetInterests = value
                    .split(",")
                    .map((targetInterest) => targetInterest.trim());
                  if (targetInterests.length > 1) {
                    if (values.targetInterests) {
                      const newTags = [
                        ...values.targetInterests,
                        ...targetInterests,
                      ].filter((item, pos, self) => self.indexOf(item) === pos);
                      setValues({
                        ...values,
                        targetInterests: newTags.slice(0, 4),
                      });
                    } else {
                      setValues({
                        ...values,
                        targetInterests: targetInterests.slice(0, 4),
                      });
                    }
                    setInputValue("");
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    event.key === "Enter" ||
                    event.key === "," ||
                    event.key === "."
                  ) {
                    event.preventDefault();
                    const targetInterest = inputValue.trim();
                    if (targetInterest) {
                      if (values.targetInterests) {
                        if (values.targetInterests.includes(targetInterest)) {
                          setInputValue("");
                        } else {
                          setValues({
                            ...values,
                            targetInterests: [
                              ...values.targetInterests,
                              targetInterest,
                            ],
                          });
                        }
                      } else {
                        setValues({
                          ...values,
                          targetInterests: [targetInterest],
                        });
                      }
                      setInputValue("");
                    }
                  } else if (event.key === "Backspace" && !inputValue) {
                    event.preventDefault();
                    const targetInterestsCopy = values.targetInterests
                      ? [...values.targetInterests]
                      : [];
                    targetInterestsCopy.pop();

                    setValues({
                      ...values,
                      targetInterests: targetInterestsCopy,
                    });
                  }
                }}
                placeholder={""}
                disabled={
                  loadingEditAd ||
                  (values.fieldsToEdit &&
                    !values.fieldsToEdit.includes("targetInterests"))
                }
                maxLength={200}
              />
            )}
          </div>

          {/* {targetAd.status === 0 ? (
            <>
              <div className="py-2 mt-4 px-4 text-blue-700 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm w-full rounded-lg border-blue-600 border">
                <p>Fees</p>
                <p className="text-lg sm:text-xl">
                  US${(targetAd.dailyBudget / 100).toFixed(2)}{" "}
                  {/* <span className="text-blue-400 text-base">
                    + 4.16% per sale
                  </span> 
                </p>
              </div>
            </>
          ) : (
            <></>
          )} */}
          {targetAd.status === 0 && !isAdmin ? (
            values.useNewCard ? (
              <div className="w-full">
                <label
                  htmlFor="paymentInfo"
                  className="sm:text-base text-sm font-semibold text-gray-400"
                >
                  Payment info{" "}
                  {errors && errors.paymentError && (
                    <span className="text-red-500">{errors.paymentError}</span>
                  )}
                </label>

                <CardInputField
                  cardElemIsFocused={cardElemIsFocused}
                  setCardElemIsFocused={setCardElemIsFocused}
                  error={errors && errors.paymentError}
                  setIsCardComplete={setIsCardComplete}
                  disabled={false}
                  title="Payment Info"
                />
              </div>
            ) : (
              <div className="sm:text-lg mt-1 overflow-y-auto w-full shadow-sm flex-1 px-3 py-2 rounded-md border-blue-600 border focus:border-indigo-500 focus:ring-indigo-500">
                <div className="w-full flex items-center justify-between">
                  <p className="text-gray-400">Using card on file</p>
                  <svg
                    className={`text-blue-500 transition-all h-4 fill-current `}
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="6.5" cy="6.5" r="6.5" />
                    <path d="M3 6.72727L5.5 9L10 4" stroke="white" />
                  </svg>
                </div>
              </div>
            )
          ) : (
            <></>
          )}
          {(targetAd.status === 0 || targetAd.status === 5) && (
            // <button
            //   onClick={(e) => {
            //     e.preventDefault();
            //     sendAdForApproval();
            //   }}
            //   className="border rounded-md uppercase font-semibold border-gray-700 py-2 w-full text-white text-center hover:bg-gray-800 transition-all"
            // >
            //   Send product for approval
            // </button>
            <SubmitButton
              onClickFunc={sendAdForApprovalCallback}
              disabled={
                loadingSendAdForApproval || values.fieldsToEdit.length > 0
              }
              isNotLoading={!loadingSendAdForApproval}
              title={targetAd.status === 5 ? "Resubmit" : "Confirm and go live"}
              errors={values.fieldsToEdit.length === 0 && errors}
            />
          )}
        </div>
      </form>
    </div>
  );
}

export default EditSellerAd;
